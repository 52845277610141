import React from "react";

export default function Button(props) {

  return (
    <button
      onClick={props.onClick}
      className={props.buttonClassName}
      disabled={props.disabled}
    >
      {
        props.icon === "typemix_residential_newconstruction" ?
          <span><span role="img" aria-label="new-construction">🏗</span> New Construction</span>
        : props.icon === "typemix_residential_renovation" ?
          <span><span role="img" aria-label="requires-renovation">🏢</span> Existing buildings requiring renovation</span>
        : props.icon === "typemix_residential_no_reno" ?
          <span><span role="img" aria-label="no-renovation">🏢</span> Existing buildings that do not require renovation</span>
        : props.buttonText
      }
    </button>
  );
}
