import React from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  progressBar: {
    height: 20,
    borderRadius: 10,
    backgroundColor: "#E1E5E6",
  },
});

export default function ProjectNameElement(props) {

  const classes = useStyles();

  return (
    <div className={props.hasPadding ? "padding-left-xsmall padding-right-xsmall" : ""}>
      <div className={classes.root}>
        <LinearProgress
          variant="determinate"
          value={props.percentComplete}
          color="primary"
          className={classes.progressBar}
        />
      </div>
      {props.hasLabel && <div className="white light margin-top-xxsmall">
        <strong>{props.percentComplete}%</strong> Complete
      </div>}
    </div>

  )
}
