import React, {useState, useContext} from 'react'
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserContext } from '../UserContext'
import Button from "./buttons/Button.js"
import HomePageRight from "./HomePageRight.js"
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const {token, setToken} = useContext(UserContext)
  let history = useHistory();

  // state values for user profile
  const [ firstName, setFirstName ] = useState("")
  const [ lastName, setLastName ] = useState("")
  const [ email, setEmail ] = useState("")
  const [ password1, setPassword1 ] = useState("")
  const [ showPassword1, setShowPassword1 ] = useState(false)
  const [ showPassword2, setShowPassword2 ] = useState(false)
  const [ password2, setPassword2 ] = useState("")
  const [ organization, setOrganization ] = useState("")
  const [ title, setTitle ] = useState("")
  const [ errorManager, setErrorManager ] = useState({})

  // state values for managing loading and post
  const [ isSigningUp, setIsSigningUp ] = useState(false)

  const handleBlur = (event) => {
    const {name, value, type, checked} = event.target
    if (name === "firstName" || name === "lastName" || name === "title" || name === "organization" || name === "password1") {
      value === "" ? setErrorManager({...errorManager, [name]: "This field is required."}) : setErrorManager({...errorManager, [name]: null})
    } else if (name === "email") {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        setErrorManager({...errorManager, [name]: "Please enter a valid email."})
      } else {
        setErrorManager({...errorManager, [name]: null})
      }
    } else if (name === "password2") {
      if (password1 !== "" && !errorManager['password1'] && password2 !== password1) {
        setErrorManager({...errorManager, [name]: "Your passwords must match."})
      } else {
        setErrorManager({...errorManager, [name]: null})
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setIsSigningUp(true)
    let url = process.env.REACT_APP_API_URL + "users/"
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    let data = JSON.stringify({
      "first_name":firstName,
      "last_name":lastName,
      "email": email,
      "username": email,
      "password": password1,
      "profile": {
        "organization": organization,
        "title":title
      }
    })
    fetch(url, {
      method: "POST",
      headers: headers,
      body: data
    })
    .then(res => res.json())
    .then(
      (data) => {
        if (data.error) {
          // handling of error messages from backend should go here, especially password invalid messages
        } else {
          let formdata = new FormData();
          formdata.append("grant_type", "password");
          formdata.append("username", email);
          formdata.append("password", password1);
          formdata.append("client_id", process.env.REACT_APP_CLIENT_ID);
          let url = process.env.REACT_APP_API_URL+ 'o/token/';
          fetch(url, {
           method: "POST",
           body: formdata,
          })
          .then(res => res.json())
          .then(
            (data) => {
              if (data.error) {
              } else {
                setToken(data.access_token)
                history.push("/dashboard");
              }
            }
          )
        }
        setIsSigningUp(false)
      }
    )
  }

  return (
    <div className="flexrow">
      <div className="col-xs-5 white-vertical-container">
        <div className="flexrow margin-top-xsmall">
          <div className="col-xs-12">
            <h2>Sign Up</h2>
          </div>
        </div>
        <form className={classes.root} noValidate autoComplete="off">
          <div className="flexrow middle-xs">
            <div className="col-xs-4">First Name</div>
            <div className="col-xs-8">
              <TextField
                id="first_name"
                type="text"
                label="First Name"
                name="firstName"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                onBlur={(event) => handleBlur(event)}
                variant="outlined"
                disabled={isSigningUp}
                error={errorManager["firstName"]}
                helperText={errorManager["firstName"] ? errorManager["firstName"] : ""}
              />
            </div>
          </div>
          <div className="flexrow middle-xs">
            <div className="col-xs-4">Last Name</div>
            <div className="col-xs-8">
              <TextField
                id="last_name"
                type="text"
                label="Last Name"
                name="lastName"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                onBlur={(event) => handleBlur(event)}
                variant="outlined"
                disabled={isSigningUp}
                error={errorManager["lastName"]}
                helperText={errorManager["lastName"] ? errorManager["firstName"] : ""}
              />
            </div>
          </div>
          <div className="flexrow middle-xs">
            <div className="col-xs-4">Email</div>
            <div className="col-xs-8">
              <TextField
                id="email"
                type="email"
                label="Email"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onBlur={(event) => handleBlur(event)}
                variant="outlined"
                disabled={isSigningUp}
                error={errorManager["email"]}
                helperText={errorManager["email"] ? errorManager["email"] : ""}
              />
            </div>
          </div>
          <div className="flexrow middle-xs">
            <div className="col-xs-4">Password</div>
            <div className="col-xs-8">
              <TextField
                id="password1"
                type="password"
                label="Password"
                name="password1"
                value={password1}
                onChange={(event) => setPassword1(event.target.value)}
                onBlur={(event) => handleBlur(event)}
                variant="outlined"
                disabled={isSigningUp}
                error={errorManager["password1"]}
                helperText={errorManager["password1"] ? errorManager["password1"] : ""}
              />
            </div>
          </div>
          <div className="flexrow middle-xs">
            <div className="col-xs-4">Confirm Password</div>
            <div className="col-xs-8">
              <TextField
                id="password2"
                type="password"
                label="Confirm Password"
                name="password2"
                value={password2}
                onChange={(event) => setPassword2(event.target.value)}
                onBlur={(event) => handleBlur(event)}
                variant="outlined"
                disabled={isSigningUp}
                error={errorManager["password2"]}
                helperText={errorManager["password2"] ? errorManager["password2"] : ""}
              />
            </div>
          </div>
          <div className="flexrow middle-xs">
            <div className="col-xs-4">Organization</div>
            <div className="col-xs-8">
              <TextField
                id="organization"
                type="text"
                label="Organization"
                name="organization"
                value={organization}
                onChange={(event) => setOrganization(event.target.value)}
                onBlur={(event) => handleBlur(event)}
                variant="outlined"
                disabled={isSigningUp}
                error={errorManager["organization"]}
                helperText={errorManager["organization"] ? errorManager["organization"] : ""}
              />
            </div>
          </div>
          <div className="flexrow middle-xs">
            <div className="col-xs-4">Title</div>
            <div className="col-xs-8">
              <TextField
                id="title"
                type="text"
                label="Title"
                name="title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                onBlur={(event) => handleBlur(event)}
                variant="outlined"
                disabled={isSigningUp}
                error={errorManager["title"]}
                helperText={errorManager["title"] ? errorManager["title"] : ""}
              />
            </div>
          </div>
          <div className="flexrow middle-xs margin-top-xsmall">
            <div className="col-xs-12 center-xs">
              <Button
                buttonText={isSigningUp ? <ClipLoader size={15} color={"#ffffff"} loading={isSigningUp}/> : "Get Started"}
                buttonClassName={"button-75 light-blue padding-xsmall margin-right-xsmall"}
                onClick={handleSubmit}
                disabled={isSigningUp}
              />
            </div>
          </div>
          <div className="flexrow middle-xs center-xs margin-top-xsmall">
            <p className="question-subtext">By signing up, you agree to GroundUp's <a href="/terms">Terms of Service</a>.</p>
          </div>
        </form>
      </div>
      <div className="col-xs-7 gray-vertical-container">
        <HomePageRight />
      </div>
    </div>
      )


}
