import React from "react";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip_InlineGray from "../tooltips/Tooltip_InlineGray.js"
import Tooltip_Gary from "../tooltips/Tooltip_Gary.js"
import Tooltip_Large_Banner from "../tooltips/Tooltip_Large_Banner.js"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));


export default function InputGroup_Number_Multiply_Across(props) {

  // File Description:
  // -- input type with multiplication settings
  // -- builds the header row by iterating through the column options in the settings, and outputting a div for each
  // -- for each input, includes:
  //      -- the question_copy
  //      -- the input element itself
  //      -- the columns showing the values to be multipled
  //      -- the final output column

  const classes = useStyles();

  let dynamicColumnWidth = props.multiplicationSettings[0].field_settings.length <= 1 ? "col-xs-2" : "col-xs-1"
  return (
    <div className="flexcolumn margin-top-xsmall">
      <div className="flexrow col-xs-12">
        <div className="col-xs-2"></div>
        <div className="col-xs-2 table-header-bottom">{props.multiplicationSettings[0].input_column_label}</div>
        {
          props.multiplicationSettings[0].field_settings.map((column,index) => {
            // this outputs a column for each column in the field_settings array
            // these are dynamically inserted in the middle of the table
            return (
                <div key={index+column.column_field} className={dynamicColumnWidth + " center-xs table-header-bottom"}>{column.column_label}</div>
              )
          })
        }
        <div className="col-xs-2 center-xs table-header-bottom">{props.multiplicationSettings[0].output_column_label}</div>
      </div>
      {
        props.inputFields.sort(function(a, b) {
          return a.id - b.id;
        }).map((input,index) => {
          // initiate an array that will store the values to be multipled together at the end
          let valuesArray = [props.proformaData[input.name]]
          // for each column in the field_settings array
          let calculatedColumns = props.multiplicationSettings[0].field_settings.map((column, index) => {
            // push the value into the list to be multipled together
            let cellDataValue = typeof column.column_field === "string" ? parseInt(props.proformaData[column.column_field]) : column.column_field.reduce((a,b) => props.proformaData[a] * props.proformaData[b])

            valuesArray.push(cellDataValue)

            // return a column that has the multiplcation sign, the value of the lookup field, and any pre/post labels
            // these are dynamically inserted in the middle of the table
            return (
              <div className={dynamicColumnWidth + " flexrow between-xs"}>
                <span>x</span>
                <span>{column.column_pre_unit}{props.formatOutputWithCommas(cellDataValue)}{column.column_post_unit}</span>
              </div>
            )
          })
          // calculate the final value by multiplying all of those in the array
          let finalValue = props.multiplicationSettings[0].is_negative ? Math.round(valuesArray.reduce((a,b) => a*b)) * -1 : Math.round(valuesArray.reduce((a,b) => a*b))
          // add that to the last column of the table
          calculatedColumns.push(
              <div className="col-xs-2 flexrow between-xs">
                <span>=</span>
                <span>{props.multiplicationSettings[0].output_column_pre_unit}{props.formatOutputWithCommas(finalValue)}{props.multiplicationSettings[0].output_column_post_unit}</span>
              </div>
          )

          // determine disabled status, at the input level
          let isDisabled = false
          if (input.dependency_field_name !== "") {
            isDisabled = !props.proformaData[input.dependency_field_name]

          }
          if (isDisabled && props.proformaData[input.name] !== 0) {
            props.setProformaData({
              ...props.proformaData,
              [input.name]: 0,
            });
          }

          return (
            <div className="flexrow margin-top-xxsmall middle-xs">
              <div className="col-xs-2">{input.question_copy}</div>
              <div className="col-xs-2">
                <form className={classes.root} noValidate autoComplete="off">
                  <TextField
                    id={input.name}
                    type={input.type}
                    label={input.placeholder_text}
                    name={input.name}
                    value={props.formatInput(input.input_type, (props.proformaData[input.name] ? props.proformaData[input.name] : ""), input.post_unit)}
                    onChange={(event) => props.handleChange(event, input.input_type, input.post_unit)}
                    onBlur={(event) => props.handleChange(event, input.input_type, input.post_unit)}
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">{input.post_unit}</InputAdornment>,
                    }}
                    disabled={isDisabled}
                    error={props.errorManager[input.name] ? true : false}
                    helperText={props.errorManager[input.name] ? props.errorManager[input.name] : null}
                  />
                </form>
              </div>
              {calculatedColumns}
              <div className="col-xs-2">
                {input.tooltip_type === "small" ?
                  <Tooltip_InlineGray
                    tooltipText={input.tooltip}
                  />
                : input.tooltip_type === "gary_tooltip" ?
                  <div className="col-xs-6">
                    <Tooltip_Gary
                      tooltipTitle={input.tooltip_title}
                      tooltipText={input.tooltip}
                    />
                  </div>
                :
                  null
                }
              </div>
            </div>
          )
        })
      }
      {(props.tooltipType === "large_banner") ?
        <Tooltip_Large_Banner
          tooltipTitle={props.tooltipTitle}
          tooltipText={props.tooltipText}
        />
        :
        null
      }
    </div>
  )
}
