import React, {useState, useEffect, useContext} from "react";
import { UserContext } from '../../UserContext'
import { useHistory } from "react-router-dom";
import Number_Horizontal_EndAdornment from './Number_Horizontal_EndAdornment.js'
import ChartElement from './ChartElement.js'
import Tooltip_Gary from "../tooltips/Tooltip_Gary.js"

export default function InputGroup_Chart(props) {

  // File Description:
  //  TODO

  const {token, setToken} = useContext(UserContext)
  let history = useHistory();
  const [ chartEndpointData, setChartEndpointData ] = useState()
  let chartEndpointURL = props.chartSettings[0].dependent_field_endpoint ? props.chartSettings[0].dependent_field_endpoint : null

  useEffect(() => {
    // fetch and set state in response
    if (chartEndpointURL) {
      let url = process.env.REACT_APP_API_URL + chartEndpointURL + props.proformaData.id
      let headers = new Headers();
      headers.set('Content-type', 'application/json')
      headers.set('Authorization', 'Bearer ' + token);
      fetch(url, {
        method: "GET",
        headers: headers,
      })
      .then((response) => {
        if (!response.ok) { throw response }
        return response.json()  //we only get here if there is no error
      })
      .then(data => setChartEndpointData(data))
      .catch( err => {
        if (err.status === 401) {
          history.push("/login")
        } else if (err.status === 404 || err.status === 500 || err.status === 403) {
          history.push("/error404")
        }
      })
    } else {
      setChartEndpointData(null)
    }
  },[chartEndpointURL,props.proformaData])

  let chartElement = []
  chartElement.push(
    <ChartElement
      chartSettings={props.chartSettings}
      proformaData={props.proformaData}
      formatOutputWithCommas={props.formatOutputWithCommas}
    />
  )

  let outputFields = []
  props.chartSettings[0].dependent_field_lookups.map((field,index) => {
    if (props.chartSettings[0].show_percent_and_value) {
      let numeratorValue = Number(props.proformaData[field.lookup_name])
      let denominatorValue = Number(props.proformaData[props.chartSettings[0].total_lookup_name])
      let percentValue = (numeratorValue / denominatorValue).toFixed(2)
      let textPercentage = parseInt(percentValue*100).toFixed(2).toString() + "%"
      outputFields.push(
        <div className="flexrow margin-top-xsmall">
          <div className="col-xs-4 padding-left-none">{field.text_name}</div>
          <div className="col-xs-2 end-xs">{textPercentage}</div>
          <div className="col-xs-2 end-xs">{props.proformaData[field.lookup_name] ? props.formatOutputWithCommas(props.proformaData[field.lookup_name]) : ""}</div>
        </div>
      )
    } else {
      outputFields.push(
        <div className="flexrow margin-top-xsmall">
          <div className="col-xs-4">{field.text_name}</div>
          <div className="col-xs-2 end-xs">{props.proformaData[field.lookup_name]}</div>
        </div>
      )
    }
  })

  if (chartEndpointData) {
    outputFields.push(
        <div className="col-xs-12">
          <h4>{props.chartSettings[0].dependent_field_name}</h4>
        </div>
    )
    Object.values(chartEndpointData).map((ratio,index) => {
      if (index !== 0 && (index !== Object.values(chartEndpointData).length - 1)) {
        outputFields.push((
          <div className="flexrow margin-top-xsmall">
            <div className="col-xs-8">Year {index}</div>
            <div className="col-xs-4 end-xs">{props.formatOutputWithCommas(ratio)}</div>
          </div>
        ))
      } // don't display year zero or 11
      return ratio
    })
  }



  let inputFieldElement = []
  props.inputFields.map((input,index) => {
    inputFieldElement.push(
      <Number_Horizontal_EndAdornment
        key={"input_"+index+input.name}
        type={input.input_type}
        questionText={input.question_copy}
        placeholderText={input.placeholder_text}
        name={input.name}
        value={props.proformaData[input.name] === null ? "" : props.proformaData[input.name]}
        handleChange={props.handleChange}
        endAdornment={input.post_unit}
        tooltipType={input.tooltip_type}
        tooltipText={input.tooltip}
        tooltipTitle={input.tooltip_title}
        formatInput={props.formatInput}
        errorManager={props.errorManager}
      />
    )
  })



  return (
    <div>
      {props.chartSettings[0].location === "start" &&
        <div>
          <div className="col-xs-10">
            {chartElement}
          </div>
          <div className="flexrow">
            <div className="col-xs-6">
                {outputFields}
            </div>
            {(props.tooltipType === "gary_tooltip") ?
              <Tooltip_Gary
                tooltipTitle={props.tooltipTitle}
                tooltipText={props.tooltipText}
              />
              :
              null
            }
          </div>
        </div>
      }
      {inputFieldElement}
      {props.chartSettings[0].location === "end" &&
        <div className="margin-top-small">
          <div className="col-xs-10">
            {chartElement}
          </div>
          <div className="flexrow">
            <div className="col-xs-6">
                {outputFields}
            </div>
            {(props.tooltipType === "gary_tooltip") ?
              <Tooltip_Gary
                tooltipTitle={props.tooltipTitle}
                tooltipText={props.tooltipText}
              />
              :
              null
            }
          </div>
        </div>
      }
    </div>
  )
}
