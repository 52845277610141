import React from "react";
import SelectMultiple_Buttons_Vertical from './SelectMultiple_Buttons_Vertical.js'

export default function InputGroup_Boolean_Dependent_Select_Multiple(props) {

  // File Description:
  //  --  Receives a boolean field as a dependentFieldName
  //  --  Maps each input
  //  --  If the boolean is false, the input is disabled
  //  --  Value is automatically set to blank array if the boolean dependecy field is false
  let mustCheckOne = props.inputFields[0].name === "taxes_taxcredits_types" ? false : true
  return (
    props.inputFields.sort(function(a, b) {
      return a.id - b.id;
    }).map((input,index) => {

      let isDisabled = !props.proformaData[props.dependentFieldName]
      if (isDisabled && props.proformaData[input.name].length !== 0) {
        props.setProformaData({
          ...props.proformaData,
          [input.name]: [],
        });
      }

      return (
        <SelectMultiple_Buttons_Vertical
          key={"input_"+index+input.name}
          type={input.input_type}
          questionText={input.question_copy}
          placeholderText={input.placeholder_text}
          name={input.name}
          value={props.proformaData[input.name]}
          choices={input.choices}
          proformaData={props.proformaData}
          setProformaData={props.setProformaData}
          proformaId={props.proformaId}
          patchProformaBulkBody={props.patchProformaBulkBody}
          isDisabled={isDisabled}
          mustCheckOne={mustCheckOne}
          errorManager={props.errorManager}
        />
      )
    })
  )
}
