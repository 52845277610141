import React from "react";
import Button from '../buttons/Button.js'
import Tooltip_Large_Banner from "../tooltips/Tooltip_Large_Banner.js"
import Tooltip_Gary from "../tooltips/Tooltip_Gary.js"

export default function SelectMultiple_Buttons_Vertical_Icons(props) {

  const onClickSelectMultiple = (fieldName, clickedChoice) => {
    let arrayValues = props.proformaData[fieldName]
    let requestBody = {}
    if (arrayValues.includes(clickedChoice)) {
      let valueIndex = arrayValues.indexOf(clickedChoice)
      arrayValues.splice(valueIndex,1)
      // begin building the request body that will also set the value of the dependent fields to zero
      requestBody[clickedChoice] = 0
    } else {
      arrayValues.push(clickedChoice)
    }
    // add the new value for the array field to request body
    requestBody[fieldName] = arrayValues
    props.patchProformaBulkBody(props.proformaId,requestBody,fieldName)
  }

  let customErrorText = (props.errorManager[props.name] || props.proformaData[props.name].length === 0) ? <div class="error-text">
      <p>Your project must include one of these types of construction. Please select an option from the list below.</p>
    </div>
    : null

  return (
    <div className="flexcolumn start-xs">
      <div className="col-xs-6">
        <p className="margin-bottom-none">{props.questionText}</p>
        <p className="question-subtext">{props.placeholderText}</p>
      </div>
      {customErrorText && <div className="col-xs-6">
        <p>{customErrorText}</p>
      </div>}
      <div className="flexrow">
        <div className="col-xs-6 flexcolumn">
          {
            props.choices.map((choice,index) => {
              let buttonSelectedClass = props.proformaData[props.name].includes(choice.dependency_field_name) ? "royal-blue" : "gray-gray-border"
              return (
                <Button
                  key={index}
                  buttonText={choice.text_name}
                  buttonClassName={"button-fill button-icon padding-xsmall margin-bottom-xxsmall margin-right-xsmall " + buttonSelectedClass}
                  onClick={() => onClickSelectMultiple(props.name,choice.dependency_field_name)}
                  disabled={false}
                  icon={choice.dependency_field_name}
                />
              )
            })
          }
        </div>
        {props.tooltipType === "large_banner" ?
          <Tooltip_Large_Banner
            tooltipTitle={props.tooltipTitle}
            tooltipText={props.tooltipText}
          />
        : props.tooltipType === "gary_tooltip" ?
          <Tooltip_Gary
            tooltipTitle={props.tooltipTitle}
            tooltipText={props.tooltipText}
          />
        :
          null
        }

      </div>
    </div>
  );
}
