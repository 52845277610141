import React from "react";
import { useHistory } from "react-router-dom";
import Button from '../buttons/Button.js'

export default function BottomBar(props) {

  let history = useHistory();

  // determine included uses
  let includesResidential = props.activeProforma.project_type.includes("project_type_residential")
  let includesCommercial = props.activeProforma.project_type.includes("project_type_commercial")
  let includesOffice =props.activeProforma.project_type.includes("project_type_office")

  // retrieve the eligible section IDs based on included uses
  var decoupledStructure = JSON.parse(JSON.stringify(props.sections));
  let eligibleSections = decoupledStructure
  if (!includesResidential) { eligibleSections = eligibleSections.filter((section) => {return section.id !== 2})}
  if (!includesCommercial) { eligibleSections = eligibleSections.filter((section) => {return section.id !== 3})}
  if (!includesOffice) { eligibleSections = eligibleSections.filter((section) => {return section.id !== 4})}

  let backButtonOnClick;
  let nextButtonClassName;
  let nextButtonText;
  let nextButtonOnClick;

  let maxSectionOrder = Math.max.apply(Math, props.sections.map(function(o) { return o.id; }))
  let maxSubsectionOrder = Math.max.apply(Math, props.subsections.map(function(o) { return o.order; }))

  // if in first section and first subsection, back goes to dashboard
  if (props.activeSection === 1 && props.activeSubsection === 0) {
    backButtonOnClick = () => history.push("/dashboard")
    nextButtonOnClick = () => props.setActiveSubsection(props.activeSubsection + 1)
    nextButtonText = "Get Started"
    nextButtonClassName = "button-normal light-blue padding-xsmall margin-right-xsmall"
  // if in last section and last subsection, next goes to "View Results page"
    } else if (props.activeSection === maxSectionOrder && props.activeSubsection === 99) {
    backButtonOnClick = () => props.setActiveSubsection(props.activeSubsection - 1)
    nextButtonOnClick = () => history.push("/proforma/" + props.activeProforma.id + "/summary")
    nextButtonText = "View Results"
    nextButtonClassName = "button-normal green-no-border padding-xsmall margin-right-xsmall"
  // if in first subsection, back takes you to beginning of previous section
  } else if (props.activeSubsection === 0) {

    // identify id of previous section based on current proforma uses
    let currentSection = eligibleSections.filter((section) => {return section.id === props.activeSection})[0]
    let currentSectionIndex = eligibleSections.indexOf(currentSection)
    let previousSectionID = eligibleSections[currentSectionIndex - 1].id

    backButtonOnClick = () => {props.setActiveSection(previousSectionID)}
    nextButtonOnClick = () => props.setActiveSubsection(props.activeSubsection + 1)
    nextButtonText = "Next"
    nextButtonClassName = "button-normal light-blue padding-xsmall margin-right-xsmall"
  // if in last subsection, go to summary page
  } else if (props.activeSubsection === maxSubsectionOrder) {
    backButtonOnClick = () => props.setActiveSubsection(props.activeSubsection - 1)
    nextButtonOnClick = () => {props.setActiveSubsection(99)}
    nextButtonText = "Next"
    nextButtonClassName = "button-normal light-blue padding-xsmall margin-right-xsmall"
  // if on summary page, go to next section
  } else if (props.activeSubsection === 99) {
    backButtonOnClick = () => props.setActiveSubsection(maxSubsectionOrder)
    nextButtonOnClick = () => {

      // identify id of next section based on current proforma uses
      let currentSection = eligibleSections.filter((section) => {return section.id === props.activeSection})[0]
      let currentSectionIndex = eligibleSections.indexOf(currentSection)
      let nextSectionId = eligibleSections[currentSectionIndex + 1].id

      props.setActiveSection(nextSectionId);
      props.setActiveSubsection(0)
    }
    nextButtonText = "Complete Section"
    nextButtonClassName = "button-normal green-no-border padding-xsmall margin-right-xsmall"
  } else {
    backButtonOnClick = () => props.setActiveSubsection(props.activeSubsection - 1)
    nextButtonOnClick = () => props.setActiveSubsection(props.activeSubsection + 1)
    nextButtonText = "Next"
    nextButtonClassName = "button-normal light-blue padding-xsmall margin-right-xsmall"
  }

  return (
    <div className="flexrow margin-top-medium">
      <div className="col-xs-12 padding-left-xsmall flexrow between-xs">
        <div className="col-xs-2 padding-left-none padding-right-none">
          <Button
            buttonText={"< Back"}
            buttonClassName={"button-small white-no-border padding-xsmall margin-right-xsmall"}
            onClick={backButtonOnClick}
            disabled={false}
          />
        </div>
        <div className="col-xs-2 padding-left-none padding-right-none">
          <Button
            buttonText={nextButtonText}
            buttonClassName={nextButtonClassName}
            onClick={nextButtonOnClick}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
}
