import React from "react";
import GaryTheGroundhog from '../../static_assets/svg/GaryTheGroundhog.png';

export default function Error404(props) {

  return (
    <div className="flexrow center-xs middle-xs padding-top-medium">
      <div className="col-xs-2 flexcolumn start-xs padding-left-medium">
        <img src={GaryTheGroundhog} alt="welcome" className="gary-image" />
        <span className="question-subtext">Gary the Groundhog</span>
        <span className="size-80 light">GroundUp Expert</span>
      </div>
      <div className="col-xs-6 start-xs">
        <h2>Uh oh! You've Dug Too Deep!</h2>
        <p className="margin-bottom-xxsmall">Looks like you may have tried to access a page that does not exist or performed an action that you do not have permission to complete.</p>
        <p className="margin-top-xxsmall">Head back to your <a href="dashboard">dashboard</a> to get back on track.</p>
      </div>
    </div>
  )
}
