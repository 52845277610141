import React from "react";
import {ReactComponent as ProjectCommercialOfficeResidential} from '../../static_assets/svg/ProjectCommercialOfficeResidential.svg';
import {ReactComponent as ProjectCommercialResidential} from '../../static_assets/svg/ProjectCommercialResidential.svg';
import {ReactComponent as ProjectCommercialOffice} from '../../static_assets/svg/ProjectCommercialOffice.svg';
import {ReactComponent as ProjectCommercial} from '../../static_assets/svg/ProjectCommercial.svg';
import {ReactComponent as ProjectOfficeResidential} from '../../static_assets/svg/ProjectOfficeResidential.svg';
import {ReactComponent as ProjectOffice} from '../../static_assets/svg/ProjectOffice.svg';
import {ReactComponent as ProjectResidential} from '../../static_assets/svg/ProjectResidential.svg';

export default function SectionOverview(props) {

  let askedAbout = props.section.asked_about
  let askedAboutElements = askedAbout.map((element,index) => <li key={index}>{element.replace(/['"]+/g, '')}</li>)

  let includesCommercial = props.projectType.includes("project_type_commercial")
  let includesResidential = props.projectType.includes("project_type_residential")
  let includesOffice =props.projectType.includes("project_type_office")

  let projectImage;
  if (includesCommercial && includesOffice && includesResidential) { // all three
    projectImage = <ProjectCommercialOfficeResidential className="dashboard-project-svg"/>
  } else if (includesCommercial && includesOffice && !includesResidential) { // commercial office
    projectImage = <ProjectCommercialOffice className="dashboard-project-svg"/>
  } else if (includesCommercial && !includesOffice && includesResidential) { // commercial residential
    projectImage = <ProjectCommercialResidential className="dashboard-project-svg"/>
  } else if (includesCommercial && !includesOffice && !includesResidential) { // commercial only
    projectImage = <ProjectCommercial className="dashboard-project-svg"/>
  } else if (!includesCommercial && includesOffice && includesResidential) { // office residential
    projectImage = <ProjectOfficeResidential className="dashboard-project-svg"/>
  } else if (!includesCommercial && includesOffice && !includesResidential) { // office only
    projectImage = <ProjectOffice className="dashboard-project-svg"/>
  } else if (!includesCommercial && !includesOffice && includesResidential) { // residential only
    projectImage = <ProjectResidential className="dashboard-project-svg"/>
  }

  return (
    <div className="padding-top-small padding-left-xsmall">
      <div className="flexrow center-xs">
        <div className="col-xs-4">
          {projectImage}
        </div>
      </div>
      <div className="flexrow center-xs">
        <div className="col-xs-4">
          <h1>{props.section.header}</h1>
        </div>
      </div>
      <div className="flexrow center-xs">
        <div className="col-xs-6 start-xs">
          <h3>Overview</h3>
          <p>{props.section.overview}</p>
        </div>
      </div>
      <div className="flexrow center-xs">
        <div className="col-xs-3 start-xs">
          <span>Sections</span>
          <ol>
            {props.section.subsections.map((subsection,index) => <li key={index}>{subsection.shorthand}</li>)}
          </ol>
        </div>
        <div className="col-xs-5 start-xs">
          <span>What we'll ask about:</span>
          <ol>
            {askedAboutElements}
          </ol>
        </div>
      </div>
    </div>
  )
}
