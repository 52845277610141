import React, {useState} from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


export default function ResourcesFAQCard(props) {

  const [ isVisible, setIsVisible ] = useState(false)
  var ReactDOMServer = require('react-dom/server');
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  var questionAnswer = htmlToReactParser.parse(props.question.question_answer);

  return (
    <div className="card padding-small margin-top-xsmall">
      <div className="flexrow middle-xs">
        <span className="margin-right-xxsmall">{isVisible ?
            <ExpandLessIcon
              className="svg-light-blue"
              onClick={() => setIsVisible(!isVisible)}
            />
            :
            <ExpandMoreIcon
              className="svg-light-blue"
              onClick={() => setIsVisible(!isVisible)}
            />
          }
        </span>
        <div className="card-title" onClick={() => setIsVisible(!isVisible)}>{props.question.question_title}</div>
      </div>

      <div className={!isVisible ? "card-body hidden" : "card-body"}>{questionAnswer}</div>
    </div>
  )
}
