import React, {useState, useEffect, useContext} from "react";
import { UserContext } from '../../UserContext'
import Button from "../buttons/Button.js"
import ProgressBar from "../proformaPage/ProgressBarElement.js";
import { useHistory } from "react-router-dom";
import calculateProformaProgress from '../../static_assets/js/proformaCompleteness.js';
// import project type SVG
import {ReactComponent as ProjectCommercialOfficeResidential} from '../../static_assets/svg/ProjectCommercialOfficeResidential.svg';
import {ReactComponent as ProjectCommercialResidential} from '../../static_assets/svg/ProjectCommercialResidential.svg';
import {ReactComponent as ProjectCommercialOffice} from '../../static_assets/svg/ProjectCommercialOffice.svg';
import {ReactComponent as ProjectCommercial} from '../../static_assets/svg/ProjectCommercial.svg';
import {ReactComponent as ProjectOfficeResidential} from '../../static_assets/svg/ProjectOfficeResidential.svg';
import {ReactComponent as ProjectOffice} from '../../static_assets/svg/ProjectOffice.svg';
import {ReactComponent as ProjectResidential} from '../../static_assets/svg/ProjectResidential.svg';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/HighlightOff';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ProjectShareModal from "./ProjectShareModal.js"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: '2px solid #fff',
    padding: theme.spacing(2, 4, 3),
  },
}));


export default function ProformaObject(props) {
  const classes = useStyles();
  let history = useHistory();
  const {token, setToken} = useContext(UserContext)

  // used to calculate the overall status of a project
  const [ overallStatus, setOverallStatus] = useState({"percentComplete": 0})
  // used to define whether the delete modal is open
  const [open, setOpen] = React.useState(false);
  // used to define whether the share modal is open
  const [shareOpen, setShareOpen] = React.useState(false);

  // delete modal -- handle open
  const handleOpen = () => {
    setOpen(true);
  };
  // delete modal -- handle close
  const handleClose = () => {
    setOpen(false);
  };
  // share modal -- handle open
  const handleShareOpen = () => {
    setShareOpen(true);
  };
  // share modal -- handle close
  const handleShareClose = () => {
    setShareOpen(false);
  };
  // function to allow collaborator to remove a shared proforma from their dashboard
  const unaffiliateProforma = (proforma) => {
    let permissionObject = proforma.permissions.filter((permission) => {return permission.proforma === proforma.id && permission.collaborator.id === props.activeUser[0].id})[0]
    let url = process.env.REACT_APP_API_URL + "collaborations/" + permissionObject.id + "/"
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "DELETE",
      headers: headers
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()
    })
    .then(data => {})
    .catch( err => {
      if (err.status === 401) {
        history.push("/login")
      } else if (err.status === 404 || err.status === 403 || err.status === 500) {
        history.push("/error404")
      }
    })
    // remove the unaffiliated proforma from the users proforma data
    props.setProformaList(props.proformaList.filter((pf) => {return pf.id !== proforma.id}));
  }

  // make a copy of section structure so each proforma object has it's own and does not update the shared parent
  var decoupledStructure = JSON.parse(JSON.stringify(props.proformaStructure));
  // calculate percent complete
  calculateProformaProgress(props.proforma, decoupledStructure, overallStatus)

  // Function Definition:
  //  - Formats numbers to have comma separated values
  //  - https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  function formatOutputWithCommas(x) {
    if (Number(x) < 1000) {
      x = Number(x).toFixed(2)
    }
    else {
      x = Number(x).toFixed(0)
    }
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  // determine included uses
  let includesResidential = props.proforma.project_type.includes("project_type_residential")
  let includesCommercial = props.proforma.project_type.includes("project_type_commercial")
  let includesOffice =props.proforma.project_type.includes("project_type_office")

  // retrieve the eligible section IDs based on included uses
  let eligibleSections = decoupledStructure
  if (!includesResidential) { eligibleSections = eligibleSections.filter((section) => {return section.id !== 2})}
  if (!includesCommercial) { eligibleSections = eligibleSections.filter((section) => {return section.id !== 3})}
  if (!includesOffice) { eligibleSections = eligibleSections.filter((section) => {return section.id !== 4})}

  // determine the next section and subsection to complete
  let nextSection;
  let nextSubsection;
  // only do this if the proforma is not complete
  if (Number(overallStatus.percentComplete) !== 1) {
    // next section is the first section where the status is not complete
    nextSection = eligibleSections.sort(function(a, b) {
      return a.id - b.id;
    }).filter((section) => {return section.status !== "complete"})[0]
    // next subsection is first incomplete subsection within the next section
    nextSubsection = nextSection.subsections.sort(function(a,b) {
      return a.id - b.id;
    }).filter((subsection) => {return subsection.status !== "complete"})[0]
  }

  // determine project image and shorthand
  let projectImage;
  let projectTypeShorthand;
  if (includesCommercial && includesOffice && includesResidential) { // all three
    projectImage = <ProjectCommercialOfficeResidential className="dashboard-project-svg"/>
    projectTypeShorthand = "Commercial, Office & Residential"
  } else if (includesCommercial && includesOffice && !includesResidential) { // commercial office
    projectImage = <ProjectCommercialOffice className="dashboard-project-svg"/>
    projectTypeShorthand = "Commercial, Office"
  } else if (includesCommercial && !includesOffice && includesResidential) { // commercial residential
    projectImage = <ProjectCommercialResidential className="dashboard-project-svg"/>
    projectTypeShorthand = "Commercial, Residential"
  } else if (includesCommercial && !includesOffice && !includesResidential) { // commercial only
    projectImage = <ProjectCommercial className="dashboard-project-svg"/>
    projectTypeShorthand = "Commercial"
  } else if (!includesCommercial && includesOffice && includesResidential) { // office residential
    projectImage = <ProjectOfficeResidential className="dashboard-project-svg"/>
    projectTypeShorthand = "Office, Residential"
  } else if (!includesCommercial && includesOffice && !includesResidential) { // office only
    projectImage = <ProjectOffice className="dashboard-project-svg"/>
    projectTypeShorthand = "Office"
  } else if (!includesCommercial && !includesOffice && includesResidential) { // residential only
    projectImage = <ProjectResidential className="dashboard-project-svg"/>
    projectTypeShorthand = "Residential"
  } else {
    projectImage = <ProjectCommercialOfficeResidential className="dashboard-project-svg"/>
    projectTypeShorthand = "None Selected"
  }

  let statsOrProgressElement = []
  // if percent complete function running, show loader;
  if (!overallStatus.percentComplete) {
    statsOrProgressElement.push(
      <div className="col-xs-3 border-right-gray">
        <div className="loading-div"></div>
      </div>
    )
  } else if (Number(overallStatus.percentComplete) === 1) {
    statsOrProgressElement.push(
      <div className="col-xs-3 border-right-gray">
        <h3 className="margin-bottom-xxsmall">Stats</h3>
        <div className="flexcolumn">
          <div className="flexrow middle-xs margin-top-xxsmall">
            <div className="col-xs-6 padding-left-none">
              <h5 className="margin-bottom-none margin-top-none">IRR</h5>
            </div>
            <div className="col-xs-6 end-xs">
              <p className="question-subtext">{props.proforma.calc_irr_projectlevel === "NaN" ? "N/A" : (Number(props.proforma.calc_irr_projectlevel).toFixed(2) + "%")}</p>
            </div>
          </div>
          <div className="flexrow middle-xs margin-top-xxsmall">
            <div className="col-xs-6 padding-left-none">
              <h5 className="margin-bottom-none margin-top-none">Project Cost</h5>
            </div>
            <div className="col-xs-6 end-xs">
              <p className="question-subtext">${formatOutputWithCommas(props.proforma.calc_uses_total)}</p>
            </div>
          </div>
        </div>
      </div>
    )
  // else if not complete, show progress bar
  } else {
    statsOrProgressElement.push(
      <div className="col-xs-3 border-right-gray">
        <div className="flexrow between-xs middle-xs">
          <h3 className="margin-bottom-xxsmall margin-top-xxsmall">Progress</h3>
          <p className="question-subtext">{Number(overallStatus.percentComplete).toFixed(2) * 100}%</p>
        </div>
        <ProgressBar
          percentComplete={Number(overallStatus.percentComplete) * 100}
          hasPadding={false}
          hasLabel={false}
        />
      <div className="flexrow middle-xs"><h5 className="margin-right-xxsmall">Next up: </h5><p className="question-subtext">{nextSection.title} | {nextSubsection.shorthand}</p></div>
      </div>
    )
  }

  let actionsElement = []

  // based on project completeness, determine what action and text should appear
  let actionButton = []
  if (props.permissionLevel !== "owner" && props.permissionLevel !== "view_edit" && Number(overallStatus.percentComplete) !== 1) {
    actionButton.push(
      <p className="question-subtext">This proforma has been shared with you but is not yet complete; check back soon for an update.</p>
    )
  } else if (props.permissionLevel !== "owner" && props.permissionLevel !== "view_edit" && Number(overallStatus.percentComplete) === 1) {
    actionButton.push(
      <Button
        buttonClassName={"button-fill green-no-border padding-xsmall margin-top-xsmall margin-bottom-xxsmall"}
        buttonText={"View Summary"}
        onClick={() => history.push("/proforma/" + props.proforma.id + "/summary")}
      />
    )
  } else {
    let primaryActionText = Number(overallStatus.percentComplete) === 1 ? "View Summary" : "Resume"
    let primaryButtonClass = Number(overallStatus.percentComplete) === 1 ? "button-fill green-no-border padding-xsmall margin-top-xsmall" : "button-fill dark-blue padding-xsmall margin-top-xsmall"
    let historyURLroute = Number(overallStatus.percentComplete) === 1 ? ("/proforma/" + props.proforma.id + "/summary") : ("/proforma/" + props.proforma.id + "/review")
    actionButton.push(
      <Button
        buttonClassName={primaryButtonClass}
        buttonText={primaryActionText}
        onClick={() => history.push(historyURLroute)}
      />
    )
  }


  actionsElement.push(
    <div className="col-xs-3 start-xs middle-xs">
      {actionButton}
    {props.permissionLevel === "owner" && <div className="flexrow margin-top-xsmall">
        <div className="col-xs-4 padding-left-none padding-right-xxsmall">
          <Button
            buttonClassName="button-fill white-gray-border padding-xxsmall margin-right-xxsmall"
            buttonText={"Duplicate"}
            onClick={() => props.duplicateProforma(props.proforma.id)}
          />
        </div>
        <div className="col-xs-4 padding-left-none padding-right-xxsmall">
          <Button
            buttonClassName="button-fill white-gray-border padding-xxsmall margin-right-xxsmall"
            buttonText={"Share"}
            onClick={handleShareOpen}
          />
          <ProjectShareModal
            shareOpen={shareOpen}
            handleShareClose={handleShareClose}
            proforma={props.proforma.id}
            activeUser={props.activeUser}
          />
        </div>
        <div className="col-xs-4 padding-left-xxsmall padding-right-none">
          <Button
            buttonClassName="button-fill white-gray-border padding-xxsmall margin-right-xxsmall"
            buttonText={"Delete"}
            onClick={handleOpen}
          />
          <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper + " light-blue-border"}>
              <h2 id="transition-modal-title">Are you sure?</h2>
              <p id="transition-modal-description">Deleting this proforma will permanently erase all data associated with it.</p>
                <Button
                  buttonClassName="button-small red-no-border padding-xxsmall margin-right-xxsmall"
                  buttonText={"Delete"}
                  onClick={(event) => {
                    props.deleteProforma(props.proforma.id)
                    handleClose()
                  }}
                />
            </div>
          </Fade>
        </Modal>
        </div>
      </div>}
      {props.permissionLevel !== "owner" && <div className="flexrow margin-top-xsmall">
        <div className="col-xs-12 padding-left-none padding-right-xxsmall">
          <Button
            buttonClassName="button-fill white-gray-border padding-xxsmall margin-right-xxsmall"
            buttonText={"Unaffiliate"}
            onClick={() => unaffiliateProforma(props.proforma)}
          />
        </div>
      </div>}
    </div>
  )


  return (
    <div
      key={props.index}
      className="col-xs-12 flexrow proforma-list-container start-xs"
    >
      <div className="col-xs-2 center-xs">{projectImage}</div>
      <div className="col-xs-4 border-right-gray">
        <h2 className="margin-bottom-xxsmall">{props.proforma.project_name}</h2>
        <p className="question-subtext">{projectTypeShorthand}</p>
      </div>
      {statsOrProgressElement}
      {actionsElement}
    </div>
  )


}
