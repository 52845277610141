import React from "react";
import Number_Horizontal_EndAdornment from './Number_Horizontal_EndAdornment.js'

export default function InputGroup_Dependent_NumberFields_BooleanDependency(props) {

  // File Description:
  //  --  Receives a boolean field as a dependentFieldName
  //  --  Maps each input
  //  --  If the boolean is false, the input is disabled
  //  --  Value is automatically set to zero if the boolean dependecy field is false

  return (
    props.inputFields.sort(function(a, b) {
      return a.id - b.id;
    }).map((input,index) => {

      let isDisabled = !props.proformaData[props.dependentFieldName]
      if (isDisabled && props.proformaData[input.name] != 0) {
        props.setProformaData({
          ...props.proformaData,
          [input.name]: 0,
        });
        props.patchProforma(props.proformaId,input.name,0)
      }

      return (
        <Number_Horizontal_EndAdornment
          key={index}
          type={input.input_type}
          questionText={input.question_copy}
          placeholderText={input.placeholder_text}
          name={input.name}
          value={props.proformaData[input.name] === null ? "" : props.proformaData[input.name]}
          handleChange={props.handleChange}
          endAdornment={input.post_unit}
          disabled={!props.proformaData[props.dependentFieldName]}
          tooltipType={input.tooltip_type}
          tooltipText={input.tooltip}
          tooltipTitle={input.tooltip_title}
          formatInput={props.formatInput}
          errorManager={props.errorManager}
        />
      )
    })
  )
}
