import React, {useState, useEffect} from "react";
import {ReactComponent as ProjectCommercial} from '../../static_assets/svg/ProjectCommercial.svg';
import {ReactComponent as ProjectOffice} from '../../static_assets/svg/ProjectOffice.svg';
import {ReactComponent as ProjectResidential} from '../../static_assets/svg/ProjectResidential.svg';
import Button from "../buttons/Button.js"
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: '2px solid #fff',
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SelectMultiple_Images_Horizontal_Icon(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // prevents modal from closing while patch is underway
    if (!props.patchLoader) {
      setOpen(false)
    };
  };

  useEffect(() => {
    // closes the modal when patch loader goes from true to false and the modal state is open
    if (!props.patchLoader && open) {
      setOpen(false)
    }
  },[props.patchLoader])

  let selectedClass = props.proformaData[props.name].includes(props.choice.dependency_field_name) ? "selected" : ""

  let modalBody = []
  if (props.patchLoader) {
    modalBody.push(
      <div className={classes.paper + " light-blue-border col-xs-4"}>
        <h2 id="transition-modal-title">Please be patient.</h2>
        <h4>This can take a minute.</h4>
        <p id="transition-modal-description">We're doing a lot of number crunching. Please hold on while we update your proforma data.</p>
          <Button
            buttonClassName="button-small red-no-border padding-xxsmall margin-right-xxsmall"
            buttonText={<ClipLoader size={15} color={"#ffffff"} loading={props.patchLoader}/>}
            disabled={props.patchLoader}
          />
      </div>
    )
  } else {
    modalBody.push(
      <div className={classes.paper + " light-blue-border col-xs-4"}>
        <h2 id="transition-modal-title">Are you sure?</h2>
        <p id="transition-modal-description">Removing the {props.choice.text_name} use from this proforma will delete all data associated with this use type.<br /><br />You will need to re-enter any data you have entered for this use if you'd like to add it back to your financial model.</p>
          <Button
            buttonClassName="button-small red-no-border padding-xxsmall margin-right-xxsmall"
            buttonText={"Remove"}
            onClick={(event) => {
              props.onClickSelectMultiple(props.name,props.choice.dependency_field_name)
            }}
          />
      </div>
    )
  }

  return (
    <div className={"col-xs-3 center-xs flexcolumn middle-xs "}>
      <div
        className={"select-image-button flexcolumn middle-xs " + selectedClass}
        onClick={() => {
          // if the use is included in the project, open the confirmation modal
          if (props.proformaData[props.name].includes(props.choice.dependency_field_name)) {
            handleOpen()
          } else {
            // otherwise, add the use to the proforma
            props.onClickSelectMultiple(props.name,props.choice.dependency_field_name)
          }
        }}
      >
        {
          props.choice.text_name === "Residential" ?
            <ProjectResidential />
          : props.choice.text_name === "Commercial" ?
            <ProjectCommercial />
          : props.choice.text_name === "Office" ?
            <ProjectOffice />
          : null
        }
        <p>{props.choice.text_name}</p>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {modalBody[0]}
        </Fade>
      </Modal>
    </div>

  );
}
