import React, {useState, useEffect} from "react";
import Button from '../buttons/Button.js'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip_InlineGray from "../tooltips/Tooltip_InlineGray.js"
import Tooltip_Gary from "../tooltips/Tooltip_Gary.js"
import Tooltip_Large_Banner from "../tooltips/Tooltip_Large_Banner.js"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
}));

export default function SelectSingle_Buttons_Vertical_WithCustom(props) {

  const classes = useStyles();
  const [ showCustom, setShowCustom ] = useState(true)

  useEffect(() => {

    props.choices.map((choice,index) => {
      if (choice.value === parseFloat(props.proformaData[props.name])) {setShowCustom(false)}
    })
  },[])

  const onClickSelectSingle = (name, value, choiceName) => {
    if (choiceName === "Custom") {
      setShowCustom(true)
    } else {
      props.setProformaData({
        ...props.proformaData,
        [props.name]: value
      });
      props.patchProforma(props.proformaId,props.name, value)
      setShowCustom(false)
    }
  }

  return (
    <div className="flexrow wrap middle-xs">
      <div className="col-xs-8">
        <p className="margin-bottom-none">{props.questionText}</p>
        <p className="question-subtext">{props.placeholderText}</p>
      </div>
      <div className="col-xs-12 flexrow padding-left-none padding-right-none margin-top-xxsmall">
        <div className="flexcolumn col-xs-6 padding-left-none padding-right-none">
          {
            props.choices.sort(function(a, b) {
              return a.order - b.order;
            }).map((choice,index) => {
              let buttonSelectedClass;
              if (!showCustom && parseFloat(props.proformaData[props.name]) === choice.value) {
                buttonSelectedClass = "royal-blue"
              } else if (showCustom && choice.text_name === "Custom") {
                buttonSelectedClass = "royal-blue"
              } else {
                buttonSelectedClass = "gray-gray-border"
              }
              return (
                <Button
                  key={index}
                  buttonText={choice.text_name}
                  buttonClassName={"button-fill padding-xsmall margin-bottom-xxsmall margin-right-xsmall " + buttonSelectedClass}
                  onClick={() => onClickSelectSingle(props.name,choice.value,choice.text_name)}
                  disabled={props.isDisabled}
                />
              )
            })
          }
          {showCustom && <div className="col-xs-9 padding-left-none padding-right-none margin-top-xxsmall">
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id={props.name}
                type={"number"}
                label={"Custom Sitework Cost"}
                name={props.name}
                value={props.value ? props.formatInput("number", props.value, props.endAdornment) : ""}
                onChange={(event) => props.handleChange(event, "number", props.endAdornment)}
                onBlur={(event) => props.handleChange(event, "number", props.endAdornment)}
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="start">{props.endAdornment}</InputAdornment>,
                  startAdornment: <InputAdornment position="start">{props.startAdornment}</InputAdornment>,
                }}
                disabled={props.disabled}
                error={props.errorManager[props.name] ? true : false}
                helperText={props.errorManager[props.name] ? props.errorManager[props.name] : null}
              />
            </form>
          </div>
          }
        </div>
        {props.tooltipType === "small" ?
          <Tooltip_InlineGray
            tooltipText={props.tooltipText}
          />
        : props.tooltipType === "gary_tooltip" ?
          <Tooltip_Gary
            tooltipTitle={props.tooltipTitle}
            tooltipText={props.tooltipText}
          />
        :
          null
        }
      </div>
    </div>
  );
}
