import React, {useState} from 'react'
import { useHistory } from "react-router-dom";
import Button from "../buttons/Button.js"
import HomePageRight from "../HomePageRight.js"
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export default function ResetPassword(props) {
  const classes = useStyles();
  let history = useHistory();
  const queryString = require('query-string')
  let resetPasswordToken = queryString.parse(props.location.search).token
  const [ password1, setPassword1 ] = useState("")
  const [ password2, setPassword2 ] = useState("")
  const [ passwordError, setPasswordError ] = useState("")
  const [ isLoading, setIsLoading ] = useState(false)
  const [ showConfirmation, setShowConfirmation ] = useState(false)

  function handleSubmit(e){
    e.preventDefault()

    // check if passwords do not match; if not, throw password error
    if (password1 !== password2) {
      setPasswordError("Your passwords do not match. To continue, please update your passwords to match.")
    } else {
      setIsLoading(true)
      // prepare request, add headers, etc.
      let headers = new Headers();
      headers.set('Content-type', 'application/json')
      let url = process.env.REACT_APP_API_URL + "groundup/password_reset/confirm/"
      let data = {
        "token": resetPasswordToken,
        "password": password1
      }
      // make request
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
      })
      .then((response) => {
        if (!response.ok) { throw response }
        if (response.ok) {
          setIsLoading(false)
          setShowConfirmation(true)
        }
      })
      .catch( err => {
        setIsLoading(false)
        if (err.status === 401) {
          history.push("/login")
        } else if (err.status === 400) {
          err.text().then( errorMessage => {
            let errorObject = JSON.parse(errorMessage)
            let errorMessageText = errorObject["password"].map((error,index) => {
              return (<p>{error}</p>)
            })
            setPasswordError(errorMessageText)
          })
        } else if (err.status === 403 || err.status === 500 || err.status === 404) {
          history.push("/error404")
        }
      })
    }
  }

  return (
    <div className="flexrow center-xs">
      {!showConfirmation && <div className="col-xs-5 white-vertical-container start-xs margin-top-large">
        <div className="col-xs-12 center-xs">
          <h2>Please Enter a New Password</h2>
        </div>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="password1"
            type="password"
            label="Password"
            name="password1"
            value={password1}
            onChange={(event) => {
              setPassword1(event.target.value)
              setPasswordError()
            }}
            variant="outlined"
            error={passwordError ? true : false}
            helperText={passwordError ? passwordError : ""}
          />
          <TextField
            id="password2"
            type="password"
            label="Confirm Password"
            name="password2"
            value={password2}
            onChange={(event) => {
              setPassword2(event.target.value)
              setPasswordError()
            }}
            variant="outlined"
          />
        </form>
        <div className="center-xs margin-top-small">
          <Button
            buttonText={isLoading ? <ClipLoader size={15} color={"#ffffff"} loading={isLoading}/> : "Submit"}
            buttonClassName={"button-normal light-blue padding-xsmall margin-right-xsmall"}
            onClick={handleSubmit}
            disabled={isLoading}
          />
        </div>
      </div>}
      {showConfirmation && <div className="col-xs-5 white-vertical-container start-xs margin-top-large">
        <h2>Success!</h2>
        <a href="/login">Click here to login with your new password.</a>
      </div>}
    </div>
      )


}
