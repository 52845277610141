import React, {useState, useContext} from 'react'
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserContext } from '../UserContext'
import Button from "./buttons/Button.js"
import HomePageRight from "./HomePageRight.js"
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const [ username, setUsername ] = useState("")
  const [ usernameError, setUsernameError ] = useState()
  const [ password, setPassword ] = useState("")
  const [ passwordError, setPasswordError ] = useState()
  const [ credentialsError, setCredentialsError ] = useState()
  const [ isLoggingIn, setIsLoggingIn ] = useState(false)
  const {token, setToken} = useContext(UserContext)
  let history = useHistory();

  function handleSubmit(e){
    e.preventDefault()
    setIsLoggingIn(true)
    let formdata = new FormData();
    formdata.append("grant_type", "password");
    formdata.append("username", username);
    formdata.append("password", password);
    formdata.append("client_id", process.env.REACT_APP_CLIENT_ID);
    let url = process.env.REACT_APP_API_URL+ 'o/token/';
    fetch(url, {
     method: "POST",
     body: formdata,
    })
    .then(res => res.json())
    .then(
      (data) => {
        if (data.error) {
          data.error_description === "Request is missing username parameter." ? setUsernameError("Please enter an email") : setUsernameError()
          data.error_description === "Request is missing password parameter." ? setPasswordError("Please enter a password") : setPasswordError()
          data.error_description === "Invalid credentials given." ? setCredentialsError(true) : setCredentialsError()
        } else {
          setToken(data.access_token)
          history.push("/dashboard");
        }
        setIsLoggingIn(false)
      }
    )
  }
  return (
    <div className="flexrow">
      <div className="col-xs-5 white-vertical-container">
        <div className="flexrow margin-top-large">
          <div className="col-xs-12">
            <h2>Sign In</h2>
          </div>
        </div>
        <form className={classes.root} noValidate autoComplete="off">
          <div className="flexrow middle-xs">
            <div className="col-xs-4">Email</div>
            <div className="col-xs-8">
              <TextField
                error={usernameError}
                helperText={usernameError}
                id="username"
                type="text"
                label="Email"
                name="username"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                variant="outlined"
              />
            </div>
          </div>
          <div className="flexrow middle-xs">
            <div className="col-xs-4">Password</div>
            <div className="col-xs-8">
              <TextField
                error={passwordError}
                helperText={passwordError}
                id="password"
                type="password"
                label="Password"
                name="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                variant="outlined"
              />
            </div>
          </div>
          {credentialsError && <div className="flexrow">
            <div className="col-xs-12 flexcolumn">
              <span className="error-text">You have entered an invalid email or password.</span>
              <span className="error-text">Please try again, and remember that emails and passwords are case-sensitive.</span>
            </div>
          </div>}
          <div className="flexrow middle-xs margin-top-small">
            <div className="col-xs-12 center-xs">
              <Button
                buttonText={isLoggingIn ? <ClipLoader size={15} color={"#ffffff"} loading={isLoggingIn}/> : "Get Started"}
                buttonClassName={"button-75 light-blue padding-xsmall margin-right-xsmall"}
                onClick={handleSubmit}
                disabled={isLoggingIn}
              />
            </div>
          </div>
        </form>
        <div className="flexrow center-xs middle-xs margin-top-small">
          <a className="navbar-link" href="/reset-password">Forgot password?</a>
        </div>
        <div className="flexrow middle-xs center-xs margin-top-small">
          <p className="question-subtext">By logging in, you agree to GroundUp's <a href="/terms">Terms of Service</a>.</p>
        </div>
      </div>
      <div className="col-xs-7 gray-vertical-container">
        <HomePageRight />
      </div>
    </div>
      )


}
