import React from "react";
import ChartElement from "../inputs/ChartElement.js"

export default function ProjectFinances(props) {

  let chartSettings = [
        {
          "data": [
            {
              "order": 1,
              "lookup_name":"calc_sources_permanentfinancingamount",
              "text_name":"Debt"
            },
            {
              "order": 2,
              "lookup_name":"calc_sources_equity_oppfundamount",
              "text_name":"Market Rate Equity"
            },
            {
              "order": 3,
              "lookup_name":"calc_sources_equity_developeramount",
              "text_name":"Developer Equity"
            },
            {
              "order": 4,
              "lookup_name":"sources_equity_philanthropyamount",
              "text_name":"Philanthropy & Grants"
            },
            {
              "order": 5,
              "lookup_name":"sources_equity_cappedreturncapitalamount",
              "text_name":"Capped Return"
            },
            {
              "order": 6,
              "lookup_name":"sources_equity_returnofcapitalamount",
              "text_name":"Return of Capital"
            },
            {
              "order": 7,
              "lookup_name":"calc_sources_equity_cityamount",
              "text_name":"Tax Incentives"
            }
          ],
          "location":"start",
          "total_lookup_name":"calc_uses_total",
          "chart_title":"Project Financing",
          "show_percent_and_value":true,
          "dependent_field_lookups": [
            {
              "order": 1,
              "lookup_name": "calc_sources_equity_oppfundamount",
              "text_name": "Market Rate Equity"
            }
          ]
        }
      ]

  return (
    <div className="padding-left-none col-xs-10">
      <div className="flexrow col-xs-12 padding-left-none">
        <div className="col-xs-3 center-xs padding-left-none">
          <div className="summary-stat-container column">
            <span className="table-header-bottom">Total Project Cost</span>
            <span>${props.formatOutputWithCommas(Number(props.activeProforma.calc_uses_total).toFixed(0))}</span>
          </div>
        </div>
        <div className="col-xs-6 center-xs">
          <div className="summary-stat-container">
            <div className="col-xs-12">
              <span className="table-header-bottom">Total Financing</span>
            </div>
            <div className="flexrow">
              <div className="col-xs-4 flexcolumn div-bottom">
                <span className="table-center"><strong>Debt</strong></span>
                <span className="table-center">${props.formatOutputWithCommas(Number(props.activeProforma.calc_sources_permanentfinancingamount).toFixed(0))}</span>
              </div>
              <div className="col-xs-4 flexcolumn div-bottom">
                <span className="table-center"><strong>Equity</strong></span>
                <span className="table-center">${props.formatOutputWithCommas(Number(props.activeProforma.calc_sources_equity_amount).toFixed(0))}</span>
              </div>
              <div className="col-xs-4 flexcolumn div-bottom">
                <span className="table-center"><strong>Philanthropy & Grants</strong></span>
                <span className="table-center">${props.formatOutputWithCommas(Number(props.activeProforma.sources_equity_philanthropyamount).toFixed(0))}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChartElement
        chartSettings={chartSettings}
        proformaData={props.activeProforma}
        formatOutputWithCommas={props.formatOutputWithCommas}
      />
    </div>

  )
}
