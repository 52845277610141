import React, {useEffect} from "react";
import AppFooter from "./navbar/AppFooter.js";

export default function Support(props) {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);



  return (
    <div className="flexcolumn between-xs white-vertical-container">
      <div className="flexrow wrap">
        <div className="col-xs-12 margin-top-small">
          <h1>Support</h1>
          <h3 className="gray">What can we help you with?</h3>
        </div>
        <div className="col-xs-8">
          <iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://cdfafoundation.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&searchArea=no" scrolling="no" height="500px" width="100%" frameborder="0" ></iframe>

        </div>
      </div>
      <AppFooter />
    </div>
  );
}
