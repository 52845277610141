import React, {useState} from "react";
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandLessIcon from '@material-ui/icons/KeyboardArrowDown';
import Division_Table from '../outputs/Division_Table.js'
import CustomFiveColumnTable from '../outputs/Custom_Five_Column_Table.js'
import SectionSummary_Other from '../proformaPage/SectionSummary_Other.js'
import SectionSummary_ProjectFinances from '../proformaPage/SectionSummary_ProjectFinances.js'
import GrayBox from '../outputs/GrayBox.js'

export default function KeySummaryOutputs(props) {

  function insert(arr, ...items) {
  	arr.push(...items);
    return arr
  }

  const [ showResidential, setShowResidential ] = useState(false)
  const [ showCommercial, setShowCommercial ] = useState(false)
  const [ showOffice, setShowOffice ] = useState(false)
  const [ showSourcesUses, setShowSourcesUses ] = useState(false)

  if (props.activeProforma && props.activeProformaStructure) {

    // build the residential section outputs
    let residentialOutputs = []
    // only add output data if the project includes residential
    if (props.activeProforma.project_type.includes("project_type_residential")) {

      // first, get the output data from the proforma structure
      let residentialSubsections = props.activeProformaStructure.filter(section => {return section.id === 2})[0].subsections
      let output_residentialUnitMix = residentialSubsections.filter(subsection => {return subsection.id === 5})[0].outputs[0]
      let output_residentialRentBreakdown = residentialSubsections.filter(subsection => {return subsection.id === 6})[0].outputs[0]
      let output_residentialRentRevenue = residentialSubsections.filter(subsection => {return subsection.id === 6})[0].outputs[1]

      // create a collapsible header, and then add the output components
      residentialOutputs.push(
        <div>
          <div className="flexrow middle-xs">
            <span className="margin-right-xxsmall">{showResidential ?
                <ExpandLessIcon
                  className="svg-light-blue"
                  onClick={() => setShowResidential(!showResidential)}
                />
                :
                <ExpandMoreIcon
                  className="svg-light-blue"
                  onClick={() => setShowResidential(!showResidential)}
                />
              }
            </span>
            <h3
              className="card-title"
              onClick={() => setShowResidential(!showResidential)}
            >
              Residential Outputs
            </h3>
          </div>
          <div className={showResidential ? "" : "hidden"}>
            <div className="col-xs-12 border-bottom-gray padding-bottom-xsmall">
              <h3 class="padding-left-xsmall">Unit Mix Overview</h3>
              <Division_Table
                activeProforma={props.activeProforma}
                output={output_residentialUnitMix}
                formatOutputWithCommas={props.formatOutputWithCommas}
              />
            </div>
            <div className="col-xs-12 border-bottom-gray margin-top-small padding-bottom-xsmall">
              <h3 class="padding-left-xsmall">Residential Rent Breakdown</h3>
              <CustomFiveColumnTable
                activeProforma={props.activeProforma}
                output={output_residentialRentBreakdown}
                formatOutputWithCommas={props.formatOutputWithCommas}
              />
              <div className="margin-top-xsmall">
                <GrayBox
                  activeProforma={props.activeProforma}
                  output={output_residentialRentRevenue}
                  formatOutputWithCommas={props.formatOutputWithCommas}
                  formatOutputPercent={props.formatOutputPercent}
                />
              </div>
            </div>
          </div>
        </div>
      )
    }

    // build commercial section outputs
    let commercialOutputs = []
    if (props.activeProforma.project_type.includes("project_type_commercial")) {
      // first, get the output data from the commercial summary page
      let commercialSection = props.activeProformaStructure.filter(section => {return section.id === 3})[0]

      commercialOutputs.push(
        <div>
          <div className="flexrow middle-xs">
            <span className="margin-right-xxsmall">{showCommercial ?
                <ExpandLessIcon
                  className="svg-light-blue"
                  onClick={() => setShowCommercial(!showCommercial)}
                />
                :
                <ExpandMoreIcon
                  className="svg-light-blue"
                  onClick={() => setShowCommercial(!showCommercial)}
                />
              }
            </span>
            <h3
              className="card-title"
              onClick={() => setShowCommercial(!showCommercial)}
            >
              Commercial Outputs
            </h3>
          </div>
          <div className={showCommercial ? "" : "hidden"}>
            <SectionSummary_Other
              section={commercialSection}
              activeProforma={props.activeProforma}
              formatOutputPercent={props.formatOutputPercent}
              formatOutputWithCommas={props.formatOutputWithCommas}
              alignLeft={true}
              hideIntroText={true}
            />
          </div>
        </div>
      )
    }

    // build office section outputs
    let officeOutputs = []
    if (props.activeProforma.project_type.includes("project_type_office")) {
      // first, get the output data from the office summary page
      let officeSection = props.activeProformaStructure.filter(section => {return section.id === 4})[0]

      officeOutputs.push(
        <div>
          <div className="flexrow middle-xs">
            <span className="margin-right-xxsmall">{showOffice ?
                <ExpandLessIcon
                  className="svg-light-blue"
                  onClick={() => setShowOffice(!showOffice)}
                />
                :
                <ExpandMoreIcon
                  className="svg-light-blue"
                  onClick={() => setShowOffice(!showOffice)}
                />
              }
            </span>
            <h3
              className="card-title"
              onClick={() => setShowOffice(!showOffice)}
            >
              Office Outputs
            </h3>
          </div>
          <div className={showOffice ? "" : "hidden"}>
            <SectionSummary_Other
              section={officeSection}
              activeProforma={props.activeProforma}
              formatOutputPercent={props.formatOutputPercent}
              formatOutputWithCommas={props.formatOutputWithCommas}
              alignLeft={true}
              hideIntroText={true}
            />
          </div>
        </div>
      )
    }

    // build the project finances output
    let projectFinanceOutputs = []
    // first, get the output data from the project finance summary page
    let projectFinanceSection = props.activeProformaStructure.filter(section => {return section.id === 6})[0]
    projectFinanceOutputs.push(
      <div>
        <div className="flexrow middle-xs">
          <span className="margin-right-xxsmall">{showSourcesUses ?
              <ExpandLessIcon
                className="svg-light-blue"
                onClick={() => setShowSourcesUses(!showSourcesUses)}
              />
              :
              <ExpandMoreIcon
                className="svg-light-blue"
                onClick={() => setShowSourcesUses(!showSourcesUses)}
              />
            }
          </span>
          <h3
            className="card-title"
            onClick={() => setShowSourcesUses(!showSourcesUses)}
          >
            Sources and Uses
          </h3>
        </div>
        <div className={showSourcesUses ? "" : "hidden"}>
          <SectionSummary_ProjectFinances
            section={projectFinanceSection}
            activeProforma={props.activeProforma}
            formatOutputPercent={props.formatOutputPercent}
            formatOutputWithCommas={props.formatOutputWithCommas}
            alignLeft={true}
            hideIntroText={true}
          />
        </div>
      </div>
    )

    return (
      <div>
        {residentialOutputs}
        {commercialOutputs}
        {officeOutputs}
        {projectFinanceOutputs}
      </div>

    )
  } else {
    return (
      <div className="padding-left-none col-xs-10">
        Loading
      </div>
    )
  }
}
