import React, {useState} from "react";
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip_InlineGray from "../tooltips/Tooltip_InlineGray.js"
import Tooltip_Gary from "../tooltips/Tooltip_Gary.js"

const useStyles = makeStyles({
  root: {
    width: "100%",
    paddingLeft: 6,
    paddingRight: 6,
  },
  input: {
    width: "100%",
  },
});

export default function InputGroup_Dependent_SliderFields_Constrained(props) {

  // File Description:
  //  --  Three outputs:
  //        --  Slider elements
  //              -- Maps inputs received
  //              -- Creates a slider with an adjacent text input for each
  //              -- These are governed by handleChange and handleBlur functions that set limits on min (not less than zero)
  //                  and max (no more than 100 total across all sliders) values
  //        --  Total percent complete: shows total across all inputs
  //        --  Error text: renders if total input was greater than 100, tells user why it was auto corrected

  const classes = useStyles();

  // TODO: variable to hold error text; perhaps this lives in a useEffect in the future, and also shows errors if not totaling 100%
  const [ overConstraintError, setOverConstraintError ] = useState("")
  // default error text that appears when inputs total over 100%
  const errorText = <div className="flexrow col-xs-6 error-text margin-top-xsmall">The value you set for this unit mix caused the total to surpass 100%. The value has been set to the max allowed value. To increase it further, first decrease other percentages.</div>

  // manages slider change
  const handleSliderChange = (event, newValue, name, maxAllowedValue) => {
    // if value is allowed, set it and clear error
    if (newValue <= maxAllowedValue) {
      props.setProformaData({
        ...props.proformaData,
        [name]: newValue,
      });
      setOverConstraintError("")
      props.patchProforma(props.proformaId,name,newValue)
    // otherwise, override and show error text
    } else {
      props.setProformaData({
        ...props.proformaData,
        [name]: maxAllowedValue,
      });
      setOverConstraintError(errorText)
      props.patchProforma(props.proformaId,name,maxAllowedValue)
    }

  };

  // manages changes from input field
  const handleBlur = (event, maxAllowedValue) => {
    let valueOnBlur = Number(event.target.value) / 100
    if (valueOnBlur < 0) {
      props.setProformaData({
        ...props.proformaData,
        [event.target.name]: 0,
      });
      setOverConstraintError("")
      props.patchProforma(props.proformaId,event.target.name,0)
    } else if (valueOnBlur > Number(maxAllowedValue)) {
      props.setProformaData({
        ...props.proformaData,
        [event.target.name]: maxAllowedValue,
      });
      setOverConstraintError(errorText)
      props.patchProforma(props.proformaId,event.target.name,maxAllowedValue)
    } else {
      props.setProformaData({
        ...props.proformaData,
        [event.target.name]: valueOnBlur,
      });
      setOverConstraintError("")
      props.patchProforma(props.proformaId,event.target.name,valueOnBlur)
    }
  };

  // sets marks on the slider
  const marks = [
    {
      value: 0.0,
      label: '0%',
    },
    {
      value: 0.33,
      label: '33%',
    },
    {
      value: 0.66,
      label: '66%',
    },
    {
      value: 1.00,
      label: '100%',
    },
  ];

  // calculate the total percentage allocated across all sliders to show in the bottom total
  let allocatedPercentage = 0
  props.inputFields.map((input,index) => {
    allocatedPercentage = allocatedPercentage + Number(props.proformaData[input.name])
    return input
  })

  // create the slider elements from the provided inputs
  let sliderElements = []
  props.inputFields.map((input,index) => {

    // disable if not included in the dependency array
    let dependentFieldValues = props.proformaData[props.dependentFieldName]
    let currentInput = input.dependency_field_name
    let isDisabled = !dependentFieldValues.includes(currentInput)
    if (isDisabled && props.proformaData[input.name] !== 0) {
      props.setProformaData({
        ...props.proformaData,
        [input.name]: 0,
      });
    }

    // set the max allowed for each field
    let otherAllocatedValues = 0
    props.inputFields.map((inputForMax,index) => {
      if (input !== inputForMax) {
        otherAllocatedValues = otherAllocatedValues + Number(props.proformaData[inputForMax.name])
      }
      return inputForMax
    })
    let maxAllowedValue = Number(1 - otherAllocatedValues).toFixed(5)

    // build the sliders
    sliderElements.push(
      <div key={index+input.name}>
        <div className="flexrow middle-xs margin-top-xsmall">
          <div className="col-xs-2">
            <p>{input.question_copy}</p>
          </div>
          <div className="col-xs-4 between-xs flexrow">
            <div className="col-xs-7">
              <Slider
                className={classes.root}
                name={input.name}
                value={props.proformaData[input.name]}
                disabled={isDisabled}
                min={0}
                max={1}
                step={0.01}
                marks={marks}
              />
            </div>
            <div className="col-xs-5">
              <TextField
                key={index}
                id={input.name}
                type={input.type}
                label={input.placeholder_text}
                name={input.name}
                value={props.formatInput(input.input_type, (props.proformaData[input.name] ? props.proformaData[input.name] : ""), input.post_unit)}
                onChange={(event) => props.handleChange(event, input.input_type, input.post_unit)}
                onBlur={(event) => handleBlur(event,maxAllowedValue)}
                InputProps={{
                  endAdornment: <InputAdornment position="start">{input.post_unit}</InputAdornment>,
                }}
                disabled={isDisabled}
                error={props.errorManager[input.name] ? true : false}
                helperText={props.errorManager[input.name] ? props.errorManager[input.name] : null}
              />
            </div>
            <div className="col-xs-2">
              {input.tooltip_type === "small" ?
                <Tooltip_InlineGray
                  tooltipText={input.tooltip}
                />
              : input.tooltip_type === "gary_tooltip" ?
                <div className="col-xs-6">
                  <Tooltip_Gary
                    tooltipTitle={input.tooltip_title}
                    tooltipText={input.tooltip}
                  />
                </div>
              :
                null
              }
            </div>
          </div>
        </div>
      </div>
    )
    return input
  })

  return (
    <div>
      {sliderElements}
      <div className="flexrow margin-top-xsmall end-xs col-xs-6">
        Total: {(allocatedPercentage.toFixed(2))*100} / 100%
      </div>
      {overConstraintError}
    </div>

  )
}
