import React, {useState, useEffect} from "react";
import TextField from '@material-ui/core/TextField';
import Button from '../buttons/Button.js'
import DeleteIcon from '@material-ui/icons/HighlightOff';
import Tooltip_InlineGray from "../tooltips/Tooltip_InlineGray.js"
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Custom_Equity_Table(props) {

  const classes = useStyles();
  // state var to manage whether or not the save button is active
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(true)
  // state var to hold the values for the line to be added
  const [ newEquitySource, setNewEquitySource ] = useState({
    "source_name": "",
    "source_type": "",
    "source_amount": 0,
  })
  // state var to hold the total value for nontraditional equity
  const [ totalNonTradEquity, setTotalNonTradEquity ] = useState(0)

  // Function Definition:
  //  -- updates the total non traditional equity value on any change of proformaData
  useEffect(() => {
    let totalValue = 0;
    props.proformaData[props.name] && props.proformaData[props.name].map((source,index) => (totalValue = totalValue + Number(source.source_amount)))
    setTotalNonTradEquity(totalValue)
  },[props.proformaData])

  // Function Definition:
  //  -- updates the active status of the button on any chance of newEquitySource; all fields need to be complete for the save button to be active
  useEffect(() => {
    if (newEquitySource.source_name !== "" && newEquitySource.source_type !== "" && newEquitySource.source_amount) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  },[newEquitySource])

  // Function Definition:
  //  -- adds or removes a new equity source
  //  -- updates parent state
  const addRemoveNewEquitySource = (action, id) => {
    let copyProformaData = {...props.proformaData}
    if (action === "add") {
      // make an ID for this new source that can be referenced in the delete function
      newEquitySource.random_id = makeid(8)
      copyProformaData[props.name].push(newEquitySource)
      // based on the values in the new array, update the total Philanthropy, capped return and return of capital equity totals
      updateTotalNonTradEquityInputs(copyProformaData)
      setNewEquitySource({
        "source_name": "",
        "source_type": "",
        "source_amount": 0,
      })
    } else if (action === "remove") {
      // remove the object based on ID
      copyProformaData[props.name] = copyProformaData[props.name].filter((obj) => {return obj.random_id !== id})
      // based on the values in the new array, update the total Philanthropy, capped return and return of capital equity totals
      updateTotalNonTradEquityInputs(copyProformaData)
    }
    // update master proforma data
    let requestBody = {}
    requestBody[props.name] = copyProformaData[props.name]
    requestBody["sources_equity_philanthropyamount"] = copyProformaData["sources_equity_philanthropyamount"]
    requestBody["sources_equity_cappedreturncapitalamount"] = copyProformaData["sources_equity_cappedreturncapitalamount"]
    requestBody["sources_equity_returnofcapitalamount"] = copyProformaData["sources_equity_returnofcapitalamount"]
    requestBody["sources_equity_cappedreturncapitalcappedreturn"] = copyProformaData["sources_equity_cappedreturncapitalamount"] === 0 ? 0.0 : copyProformaData["sources_equity_cappedreturncapitalcappedreturn"]
    props.patchProformaBulkBody(props.proformaId,requestBody,props.name)
  }

  // Function Definition:
  //  -- Loops through the array of non traditional equity values
  //  -- updates the totals for Philanthropy, Capped Return Capital and Return of Capital
  //  -- returns the proformaData object
  const updateTotalNonTradEquityInputs = (array) => {

    let totalPhilanthropic = 0;
    let totalCappedReturn = 0;
    let totalReturnofCapital = 0;

    let newNonTradEquityArray = array[props.name]
    newNonTradEquityArray.map((source,index) => {
      totalPhilanthropic = source.source_type === "Philanthropy" ? totalPhilanthropic + Number(source.source_amount) : totalPhilanthropic
      totalCappedReturn = source.source_type === "Capped Return Capital" ? totalCappedReturn + Number(source.source_amount) : totalCappedReturn
      totalReturnofCapital = source.source_type === "Return of Capital" ? totalReturnofCapital + Number(source.source_amount) : totalReturnofCapital
    })

    array["sources_equity_philanthropyamount"] = totalPhilanthropic
    array["sources_equity_cappedreturncapitalamount"] = totalCappedReturn
    array["sources_equity_returnofcapitalamount"] = totalReturnofCapital

    return array

  }

  // Function Definition:
  //  -- makes a random ID for the new equity object
  const makeid = (length) => {
     var result           = '';
     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
     var charactersLength = characters.length;
     for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result;
  }

  // Function Definition:
  //  -- handle change for all inputs on the new equity line
  //  -- validates the data in the amount box to a number format, so it can be properly rendered in any sums
  const handleChange = (event) => {
    let value;
    if (event.target.name === "source_amount" && event.target.value !== "") {
      value = Number(event.target.value)
    } else {
      value = event.target.value
    }

    setNewEquitySource({
      ...newEquitySource,
      [event.target.name]: value,
    });
  }

  return (
    <div className="flexcolumn margin-top-small">
      <div className="flexrow middle-xs">
        <div className="col-xs-6">{props.questionText}</div>
        <Tooltip_InlineGray
          tooltipText={props.tooltipText}
        />
      </div>
      <div className="flexrow col-xs-12 between-xs black-underline margin-top-small padding-bottom-xxsmall">
        <div className="flexrow col-xs-10 table-header-bottom">
          <div className="col-xs-3">Source Name</div>
          <div className="col-xs-3">Source Type</div>
          <div className="col-xs-3">Amount</div>
        </div>
      </div>
      {props.proformaData[props.name] && props.proformaData[props.name].map((equitySource, index) => {

        return(
          <div className="flexrow col-xs-12 middle-xs">
            <div className="flexrow col-xs-10 padding-top-xsmall padding-bottom-xsmall">
              <div className="col-xs-3 div-middle">{equitySource.source_name}</div>
              <div className="col-xs-3 div-middle">{equitySource.source_type}</div>
              <div className="col-xs-3 div-middle">{equitySource.source_amount ? props.formatOutputWithCommas(equitySource.source_amount) : ""}</div>
              <div className="col-xs-1 div-middle">
                <DeleteIcon
                  onClick={() => addRemoveNewEquitySource("remove",equitySource.random_id)}
                  className="svg-delete"
                />
              </div>
            </div>
          </div>
        )
      })}
      <div className="flexrow col-xs-12 middle-xs">
        <div className="flexrow col-xs-10 padding-top-xsmall padding-bottom-xsmall">
          <div className="col-xs-3 div-middle">
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                type="text"
                label="Equity Source Name"
                name="source_name"
                value={newEquitySource.source_name}
                onChange={handleChange}
                variant="outlined"
                disabled={props.disabled}
              />
            </form>
          </div>
          <div className="col-xs-3 div-middle">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Type</InputLabel>
              <Select
                name="source_type"
                value={newEquitySource.source_type}
                onChange={handleChange}
                label="Type"
                disabled={props.disabled}
              >
                <MenuItem value={"Philanthropy"}>Philanthropy & Grants</MenuItem>
                <MenuItem value={"Capped Return Capital"}>Capped Return Capital</MenuItem>
                <MenuItem value={"Return of Capital"}>Return of Capital</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-xs-3 div-middle">
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                type="number"
                label="Amount"
                name="source_amount"
                value={newEquitySource.source_amount}
                onChange={handleChange}
                variant="outlined"
                disabled={props.disabled}
              />
            </form>
          </div>
          <div className="col-xs-1 div-middle">
            <Button
              buttonText={"Save"}
              buttonClassName={"button-small light-blue padding-xxsmall margin-right-xxsmall"}
              onClick={() => addRemoveNewEquitySource("add")}
              disabled={isButtonDisabled || props.disabled}
            />
          </div>
        </div>
      </div>
      <div className="flexrow col-xs-12 between-xs black-underline-top padding-top-xxsmall">
        <div className="flexrow col-xs-10 table-header-bottom">
          <div className="col-xs-3">Total</div>
          <div className="col-xs-3"></div>
          <div className="col-xs-3">{props.formatOutputWithCommas(totalNonTradEquity)}</div>
        </div>
      </div>
    </div>
  );
}
