import React from "react";

export default function Custom_SingleColumnTable(props) {

  // File Description:
  //  --  TODO
  let denominatorField;
  let fieldsToMap = []
  props.output.calculation.map((calcField,index) => {
    if (calcField.name === "calc_sources_equity_amount") {denominatorField = calcField} else {fieldsToMap.push(calcField)}
  })

  return (
    <div>
      <div className="black-underline col-xs-6">
        {fieldsToMap.sort(function(a, b) {
            return a.id - b.id;
          }).map((field,index) => {
            let valueToFormat = props.activeProforma[field.name] ? props.activeProforma[field.name] : ""
            return (
              <div className="flexrow padding-top-xsmall padding-bottom-xsmall">
                <div className="col-xs-6">{field.display_name}</div>
                <div className="col-xs-3">${props.formatOutputWithCommas(valueToFormat)}</div>
                <div className="col-xs-3">{props.formatOutputPercent((valueToFormat / props.activeProforma[denominatorField.name]))}%</div>
              </div>
            )

        })}
      </div>
      <div className="total-box margin-top-xsmall margin-bottom-xsmall col-xs-6 flexrow padding-top-xsmall padding-bottom-xsmall">
        <div className="col-xs-6">{denominatorField.display_name}</div>
        <div className="col-xs-3">${props.formatOutputWithCommas(props.activeProforma[denominatorField.name])}</div>
      </div>
    </div>
  )
}
