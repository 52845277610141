import React, {useState, useEffect} from "react";

export default function Division_Table(props) {

  // File Description:
  //  --  TODO

  let cumulativeColumnValue = 0

  let rentableResidentialSquareFootage = props.output.calculation.filter((calcField) => {return calcField.name === "calc_rentable_residential_square_footage"})[0]
  let typemixFields = props.output.input.filter((inputField) => {return inputField.name.includes("typemix_residential_")})
  let unitSizeFieldsSorted = props.output.input.filter((inputField) => {return inputField.name.includes("res_squareftperunit")}).sort(function(a, b) {
    return a.id - b.id;
  })
  let calcFieldsUnitsPerTypeSorted = props.output.calculation.filter((calcField) => {return calcField.name.includes("calc_total_residential_units_")}).sort(function(a, b) {
    return a.id - b.id;
  })
  let calcTotalResidentialUnits = props.output.calculation.filter((calcField) => {return calcField.name === "calc_total_residential_units"})[0]

  return (
    <div>
      <div>
        <div className="flexrow col-xs-12">
          <div className="col-xs-4 padding-left-none chart-title-row">{rentableResidentialSquareFootage.display_name}</div>
          <div className="col-xs-2 padding-right-none">{props.formatOutputWithCommas(Math.round(props.activeProforma[rentableResidentialSquareFootage.name]))} sqft</div>
        </div>
        <div className="flexrow col-xs-12 question-subtext">
          From previous section
        </div>
      </div>
      <div className="flexcolumn">
        <div className="flexrow margin-top-xsmall margin-bottom-xsmall chart-title-row">
          <div className="col-xs-3"></div>
          <div className="col-xs-2">Total Unit Sqft</div>
          <div className="col-xs-1"></div>
          <div className="col-xs-2">Unit Size</div>
          <div className="col-xs-1"></div>
          <div className="col-xs-2">Number of Units</div>
        </div>
        {
          typemixFields.sort(function(a, b) {
            return a.id - b.id;
          }).map((field,index) => {
            let firstColumnValue = props.formatOutputWithCommas(Math.round(props.activeProforma[field.name] * props.activeProforma[rentableResidentialSquareFootage.name]))
            let secondColumnValue = props.formatOutputWithCommas(Math.round(props.activeProforma[unitSizeFieldsSorted[index].name]))
            let finalColumnValue = props.formatOutputWithCommas(Math.round(props.activeProforma[calcFieldsUnitsPerTypeSorted[index].name]))

            return (
              <div>
                <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
                  <div className="col-xs-3">{field.question_copy}</div>
                  <div className="col-xs-2 flexrow between-xs"><span>{firstColumnValue}</span> sqft</div>
                  <div className="col-xs-1 question-disabled">/</div>
                  <div className="col-xs-2 flexrow between-xs"><span>{secondColumnValue}</span> sqft</div>
                  <div className="col-xs-1 question-disabled">=</div>
                  <div className="col-xs-2 flexrow between-xs"><span>{finalColumnValue}</span><span>units</span></div>
                </div>
              </div>
            )
          })
        }
        <div className="total-box">
          <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
            <div className="col-xs-3">{calcTotalResidentialUnits.display_name}</div>
            <div className="col-xs-2"></div>
            <div className="col-xs-1"></div>
            <div className="col-xs-2"></div>
            <div className="col-xs-1"></div>
            <div className="col-xs-2 flexrow between-xs"><span>{props.formatOutputWithCommas(Math.round(props.activeProforma[calcTotalResidentialUnits.name]))}</span><span>units</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}
