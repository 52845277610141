import React from "react";
import {ReactComponent as ProjectCommercialOfficeResidential} from '../../static_assets/svg/ProjectCommercialOfficeResidential.svg';

export default function SectionSummary_Other(props) {

  return (
    <div className={props.alignLeft ? "flexrow start-xs" : "flexrow center-xs"}>
      <div className="col-xs-6 start-xs">
        {!props.hideIntroText && <div className="col-xs-12">
          <h3>Summary</h3>
        </div>}
        {props.section.summary_page_section[0].input.length !== 0 && props.section.summary_page_section[0].input.sort(function(a, b) {
            return a.id - b.id;
          }).map((field,index) => {

            let formattedNumber;
            if (field.input_type === "number" && field.post_unit !== "%") {
              console.log("Here")
              formattedNumber = props.activeProforma[field.name] ? props.formatOutputWithCommas(props.activeProforma[field.name]) : ""
            } else if (field.input_type === "number" && field.post_unit === "%") {
              formattedNumber = props.activeProforma[field.name]
            } else {
              formattedNumber = props.activeProforma[field.name]
            }

            return (
              <div key={index} className="flexrow margin-top-xsmall">
                <div className="col-xs-6"><strong>{field.question_shorthand}</strong></div>
                <div className="col-xs-6">{field.pre_unit}{formattedNumber} {field.post_unit}</div>
              </div>
            )
          })
        }
        {props.section.summary_page_section[0].calculation.length !== 0 && props.section.summary_page_section[0].calculation.sort(function(a, b) {
            return a.id - b.id;
          }).map((field,index) => {
            return (
              <div key={index} className="flexrow margin-top-xsmall">
                <div className="col-xs-6"><strong>{field.display_name}</strong></div>
                <div className="col-xs-6">{field.pre_unit}{props.formatOutputWithCommas(Math.round(props.activeProforma[field.name]))} {field.post_unit}</div>
              </div>
            )
          })
        }
        {props.section.summary_page_section[0].calculation_output_fields.length !== 0 && <div className="black-underline-top margin-top-xsmall padding-top-xsmall">
          <div className="total-box">
            {props.section.summary_page_section[0].calculation_output_fields.sort(function(a, b) {
              return a.id - b.id;
            }).map((field,index) => {
              return (
                <div key={index} className="flexrow margin-top-xsmall margin-bottom-xsmall">
                  <div className="col-xs-6"><strong>{field.display_name}</strong></div>
                  <div className="col-xs-6">{field.pre_unit}{props.formatOutputWithCommas(Math.round(props.activeProforma[field.name]))} {field.post_unit}</div>
                </div>
              )
            })}
          </div>
        </div>}
      </div>
    </div>
  )
}
