import React, {useState, useContext} from 'react'
import {ReactComponent as ProjectCommercialOfficeResidentialWhite} from '../static_assets/svg/ProjectCommercialOfficeResidentialWhite.svg';

export default function HomePageRight(props) {

  return (
    <div className="flexcolumn margin-top-large padding-left-medium">
      {<ProjectCommercialOfficeResidentialWhite className="svg-home"/>}
      <div className="col-xs-9 flexrow padding-left-none">
        <h1 className="homepage-logo">Welcome to GroundUp</h1>
      </div>
      <div className="col-xs-8 flexrow padding-left-none">
        <h3>A Pro Forma modeling application built to make community-driven development easier.</h3>
      </div>
    </div>
  )
}
