import React from "react";
import Number_Horizontal_StartEndAdornment from './Number_Horizontal_StartEndAdornment.js'

export default function InputGroup_Dependent_NumberFields_Unconstrained(props) {

  // File Description:
  //  --  Receives a list of inputs in an inputGroup
  //  --  Maps each input
  //  --  Toggles active or disabled state based on whether or not the input name
  //        is included in the given dependency array
  //  --  Values that are disabled (i.e. not included in the dependecy array) are automatically set to zero

  return (
    props.inputFields.sort(function(a, b) {
      return a.id - b.id;
    }).map((input,index) => {

      let dependentFieldValues = props.proformaData[props.dependentFieldName]
      let currentInput = input.dependency_field_name
      let isDisabled = !dependentFieldValues.includes(currentInput)
      if (isDisabled && (props.proformaData[input.name] != 0)) {
        props.setProformaData({
          ...props.proformaData,
          [input.name]: 0,
        });
      }

      return (
        <Number_Horizontal_StartEndAdornment
          key={index}
          type={input.input_type}
          questionText={input.question_copy}
          placeholderText={input.placeholder_text}
          name={input.name}
          value={props.proformaData[input.name] !== null ? props.proformaData[input.name] : ""}
          handleChange={props.handleChange}
          startAdornment={input.pre_unit}
          endAdornment={input.post_unit}
          disabled={isDisabled}
          tooltipType={input.tooltip_type}
          tooltipText={input.tooltip}
          tooltipTitle={input.tooltip_title}
          formatInput={props.formatInput}
          errorManager={props.errorManager}
        />
      )
    })
  )
}
