import React from "react";

export default function calculateProformaProgress(proformaData, proformaStructure, overallStatus) {

  function increment(object) {
    let newCount = object.count + 1
    object.count = newCount
    return object
  }

  function isCompleteTest(fieldValue,fieldType,allowZero) {
    if (
      (fieldValue !== null) && (
        (typeof fieldValue === "object" && Object.keys(fieldValue).length !== 0) ||
        (typeof fieldValue === "number" && fieldValue) ||
        (typeof fieldValue === "number" && fieldValue === 0 && allowZero) ||
        (typeof fieldValue === "string" && fieldType !== "number" && fieldValue !== "") ||
        (typeof fieldValue === "string" && fieldType === "number" && (fieldValue !== "" && Number(fieldValue) !== 0)) ||
        (typeof fieldValue === "string" && fieldType === "number" && Number(fieldValue) === 0 && allowZero)
      )
    ) {
      return true
    } else {
      return false
    }
  }

  let includesCommercial = proformaData["project_type"].includes("project_type_commercial")
  let includesResidential = proformaData["project_type"].includes("project_type_residential")
  let includesOffice =proformaData["project_type"].includes("project_type_office")

  let totalInputs = {count: 0}
  let completeInputs = {count: 0};
  proformaStructure.map((section,index) => {

    if (
      (section.id === 2 && !includesResidential) ||
      (section.id === 3 && !includesCommercial) ||
      (section.id === 4 && !includesOffice)
    ) { return null }
    else {

      let totalSectionInputs = {count: 0};
      let completeSectionInputs = {count: 0};

      section.subsections.map((subsection,index) => {

        let totalSubSectionInputs = {count: 0};
        let completeSubSectionInputs = {count: 0};

        subsection.input_groups.map((inputGroup,index) => {

          let inputGroupDependencyField = inputGroup.dependency_field_name ? inputGroup.dependency_field_name : null
          let inputGroupDependencyType = inputGroup.dependency_field_name ? inputGroup.type : null

          inputGroup.inputs.sort(function(a, b) {
            return a.id - b.id;
          }).map((input, index) => {

            // define counter arrays
            let totalArray = [totalInputs,totalSectionInputs,totalSubSectionInputs]
            let completeArray = [completeInputs,completeSectionInputs,completeSubSectionInputs]
            let fieldValue = proformaData[input.name]
            let fieldType = input.input_type
            let allowZero = input.allow_zero

            // Evaluate whether the field should count towards the total number of fields to complete
            if (inputGroupDependencyField && inputGroupDependencyType === "dependent_number_fields") {

              // check if the field's name is included in the dependent field's value array
              if (proformaData[inputGroupDependencyField].includes(input.name)) {
                // if so, increment totals and check for completeness
                totalArray.forEach(increment)
                completeArray = isCompleteTest(fieldValue,fieldType,allowZero) ? completeArray.forEach(increment) : completeArray
              }

            } else if (inputGroupDependencyField && inputGroupDependencyType === "dependent_sliders_constrained") {

              // check if the field's dependency name is included in the dependent field's value array
              if (proformaData[inputGroupDependencyField].includes(input.dependency_field_name)) {
                // if so, increment totals and check for completeness
                totalArray.forEach(increment)
                completeArray = isCompleteTest(fieldValue,fieldType,allowZero) ? completeArray.forEach(increment) : completeArray
              }

            } else if (inputGroupDependencyField && inputGroupDependencyType === "boolean_dependent_number_fields" || inputGroupDependencyType === "boolean_dependent_select_multiple") {

              // check if the dependent field's value is true or false; only evaluate if true
              if (proformaData[inputGroupDependencyField]) {
                // if so, increment totals and check for completeness
                totalArray.forEach(increment)
                completeArray = isCompleteTest(fieldValue,fieldType,allowZero) ? completeArray.forEach(increment) : completeArray
              }

            } else if (inputGroup.type === "number_multiply_across" && input.dependency_field_name) {

              if (proformaData[input.dependency_field_name]) {
                // if so, increment totals and check for completeness
                totalArray.forEach(increment)
                completeArray = isCompleteTest(fieldValue,fieldType,allowZero) ? completeArray.forEach(increment) : completeArray
              }

            } else if (inputGroup.type === "growth_table") {

              // all fields are valid; the isCompleteTest checks to make sure they are not null
              totalArray.forEach(increment)
              completeArray = fieldValue !== null ? completeArray.forEach(increment) : completeArray

            } else if (inputGroup.type === "custom_equity_table" && input.dependency_field_name) {

              if (proformaData[input.dependency_field_name]) {

                // if so, increment totals and check for completeness
                totalArray.forEach(increment)
                completeArray = isCompleteTest(fieldValue,fieldType,allowZero) ? completeArray.forEach(increment) : completeArray
              }
            // put custom dependencies here for one-off independent fields
            } else if (inputGroup.type === "independent_fields" && input.dependency_field_name) {
              if (proformaData[input.dependency_field_name] && proformaData[input.dependency_field_name] !== 0 && input.name === "sources_equity_cappedreturncapitalcappedreturn") {
                // if so, increment totals and check for completeness
                totalArray.forEach(increment)
                completeArray = isCompleteTest(fieldValue,fieldType,allowZero) ? completeArray.forEach(increment) : completeArray
              }
            } else if (typeof fieldValue === "boolean") {
              // exclude booleans from the total
              // pass
            // if no other conditions, evaluate the field's completeness and increment totals
            } else {

              totalArray.forEach(increment)
              completeArray = isCompleteTest(fieldValue,fieldType,allowZero) ? completeArray.forEach(increment) : completeArray

            }

            // console.log("field value: " + fieldValue + " || typeof: " + typeof fieldValue + " || field_type: " + fieldType)
            // console.log("Name: " + input.name + "     || value: " + proformaData[input.name] + "      || dependency: " + inputGroupDependencyField)
            // console.log(allowZero)
            // console.log(isCompleteTest(fieldValue,fieldType,allowZero) ? "This field was evaluated complete" : "This field was evaluated incomplete")
            // console.log("Overall -- Complete Inputs:  " + completeInputs.count)
            // console.log("Total   -- Total Inputs:     " + totalInputs.count)
            // console.log("Section -- Complete Inputs:  " + completeSectionInputs.count)
            // console.log("Section -- Total Inputs:     " + totalSectionInputs.count)

            return input
          })
          return inputGroup
        })

        // set section status at end of subsection map
        let subSectionPercentComplete = (completeSubSectionInputs.count / totalSubSectionInputs.count).toFixed(2);
        if (completeSubSectionInputs.count === 0 && totalSubSectionInputs.count === 0){
          subSectionPercentComplete = 1
        }

        // console.log("Subsection percent complete: " + subSectionPercentComplete)
        let subSectionStatus = subSectionPercentComplete == 1 ? "complete" :
          subSectionPercentComplete != 0 ? "inProgress" :
          "notStarted"
        // console.log("Subsection status: " + subSectionStatus)
        subsection.percentComplete = subSectionPercentComplete
        subsection.status = subSectionStatus
        return subsection
      })

      // set section status at end of section map
      let sectionPercentComplete = (completeSectionInputs.count / totalSectionInputs.count).toFixed(2);
      // console.log("Section percent complete: " + sectionPercentComplete)
      let sectionStatus = sectionPercentComplete == 1 ? "complete" :
        sectionPercentComplete != 0 ? "inProgress" :
        "notStarted"
      // console.log("Section status: " + sectionStatus)
      section.percentComplete = sectionPercentComplete
      section.status = sectionStatus
    }
    return section
  })

  // calculate total percent complete
  let totalPercentComplete = (completeInputs.count / totalInputs.count).toFixed(6);
  // console.log("Overall percent complete: " + totalPercentComplete)
  overallStatus.percentComplete = totalPercentComplete

  return proformaStructure
}
