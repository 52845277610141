import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";

// import components
import GroundUpLogo from "../../static_assets/logo/GroundUpLogo.js"
import Button from "../buttons/Button.js"
import UserNameDropdown from "./UserNameDropdown.js"

export default function Navbar(props) {

  useEffect(() => {
    if (props.activeUser && props.activeUser.length === 0) {
      history.push("/login")
    }
  },[])

  let history = useHistory();
  return (
    <div className="navbar">
      <div className="flexrow col-xs-12 between-xs">
        <GroundUpLogo />
        <div className="flexrow middle-xs">
          <div className="flexrow padding-right-small border-right-gray">
            <a href="/resources" className="navbar-link padding-xxsmall">Resources</a>
            <a href="/blog" className="navbar-link padding-xxsmall">Blog</a>
            {props.activeUser && props.activeUser.length !== 0 && <a href="/feedback" className="navbar-link padding-xxsmall">Feedback</a>}
            {props.activeUser && props.activeUser.length !== 0 && <a href="/support" className="navbar-link padding-xxsmall">Support</a>}
          </div>
          {props.activeUser && props.activeUser.length !== 0 ?
            <UserNameDropdown
              activeUser={props.activeUser[0]}
              setActiveUser={props.setActiveUser}
              logout={props.logout}
            />
          :
            <Button
              buttonText={"Log In"}
              buttonClassName={"button-normal dark-blue padding-xsmall margin-right-xsmall margin-left-small"}
              onClick={() => history.push("/login")}
              disabled={false}
            />
          }
        </div>
      </div>
    </div>
  );
}
