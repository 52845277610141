import React, {useEffect} from "react";

export default function Blog(props) {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.retainable.io/assets/retainable/rss-embed/retainable-rss-embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className="flexcolumn between-xs white-vertical-container">
      <div className="flexrow wrap padding-small">
        <div className="col-xs-12 margin-top-small">
          <h1>GroundUp Blog</h1>
          <p>Catch up on the latest from The Governance Project's GroundUp work.</p>
        </div>
        <div className="col-xs-12">
          <div id="retainable-rss-embed"
           data-rss="https://medium.com/feed/%40tgp_groundup"
           data-maxcols="4"
           data-layout="grid"
           data-poststyle="modal"
           data-readmore="Read the rest"
           data-buttonclass="btn btn-primary"
           data-offset="-200">&#160;
         </div>
        </div>
      </div>
    </div>
  );
}
