import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import Tooltip_InlineGray from "../tooltips/Tooltip_InlineGray.js"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export default function Date_Horizontal(props) {

  const classes = useStyles();

  return (
    <div className="flexrow middle-xs">
      <div className="col-xs-2">
        <p>{props.questionText}</p>
      </div>
      <div className="col-xs-4">
        <form className={classes.root} noValidate autoComplete="off">
          <span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                autoOk={true}
                value={props.value}
                onChange={(date) => props.handleChange(props.name,date)}
                KeyboardButtonProps={{
                  "aria-label": "change-date",
                }}
              />
            </MuiPickersUtilsProvider>
          </span>
        </form>
      </div>
      {props.tooltipType === "small" ?
        <Tooltip_InlineGray
          tooltipText={props.tooltipText}
        />
        :
        null
      }
    </div>
  );
}
