import React from "react";

export default function GrayBox(props) {

  // File Description:
  //  --  Receives an outputs object
  //  --  Maps through the calculation objects
  //  --  Displays each on the page

  let boxElements = []
  props.output.input.map((inputField,index) => {

    let formattedNumber;
    if (inputField.post_unit === "%") {
      formattedNumber = props.activeProforma[inputField.name] ? props.formatOutputPercent(props.activeProforma[inputField.name]) : ""
    } else {
      formattedNumber = props.activeProforma[inputField.name] ? props.formatOutputWithCommas(props.activeProforma[inputField.name]) : ""
    }

    boxElements.push(
      <div className="flexrow padding-top-xsmall padding-bottom-xsmall" key={inputField.name}>
        <div className="col-xs-7 padding-left-none">{inputField.question_shorthand}</div>
        <div className="col-xs-5">{inputField.pre_unit}{formattedNumber} {inputField.post_unit}</div>
      </div>
    )
    return inputField
  })
  props.output.calculation.map((calcField, index) => {

    let formattedNumber;
    if (calcField.post_unit === "%") {
      formattedNumber = props.activeProforma[calcField.name] ? props.formatOutputPercent(props.activeProforma[calcField.name]) : ""
    } else {
      formattedNumber = props.activeProforma[calcField.name] ? props.formatOutputWithCommas(props.activeProforma[calcField.name]) : ""
    }

    boxElements.push(
      <div className="flexrow padding-top-xsmall padding-bottom-xsmall" key={calcField.name}>
        <div className="col-xs-7 padding-left-none">{calcField.display_name}</div>
        <div className="col-xs-5">{calcField.pre_unit}{formattedNumber} {calcField.post_unit}</div>
      </div>
    )
    return calcField
  })

  return (
    <div className="total-box col-xs-6">
      {boxElements}
    </div>
  )
}
