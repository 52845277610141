import React, {useState, useEffect, useContext} from "react";
import { UserContext } from '../UserContext'
import { useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";

// import components
import BackArrowWithText from './proformaPage/BackArrowWithText.js'
import ProgressBarElement from './proformaPage/ProgressBarElement.js'
import ProformaSections from './proformaPage/ProformaSections.js'
import SubsectionList from './proformaPage/SubsectionList.js'
import BottomBar from './proformaPage/BottomBar.js'
import InputGroups from './proformaPage/InputGroups.js'
import SectionOverview from './proformaPage/SectionOverview.js'
import SectionSummary from './proformaPage/SectionSummary.js'
import ProjectReview from './proformaPage/ProjectReview.js'
import ProjectSummary from './proformaPage/ProjectSummary.js'
import {ReactComponent as TGPLogo} from '../static_assets/svg/TGPLogo.svg';
import TOELogo from '../static_assets/svg/TOE_White.png';
import calculateProformaProgress from '../static_assets/js/proformaCompleteness.js'

export default function ProformaPageStructure(props) {

  const {token, setToken} = useContext(UserContext)
  let history = useHistory();
  const [ activeProforma, setActiveProforma ] = useState()
  const overallStatus = {
    "percentComplete" : 0
  }
  const [ errorManager, setErrorManager ] = useState({})
  const [ activeProformaStructure, setActiveProformaStructure ] = useState([])
  const [ activeSection, setActiveSection ] = useState()
  const [ activeSubsection, setActiveSubsection ] = useState()
  const proformaId = props.match.params.proformaId
  let reviewUrl = props.match.url.includes("review")
  let summaryURL = props.match.url.includes("summary")
  let isOwnerOrEditor = null;
  // determine whether the viewer has edit priviledge for the active proforma
  if (activeProforma) {
    isOwnerOrEditor = activeProforma.created_by === props.activeUser[0].id ? true :
                          activeProforma.permissions.filter((permission) => {return (permission.collaborator.username = props.activeUser[0].username) && permission.permissions === "view_edit"}).length !== 0 ? true :
                          false
  }
  if (!isOwnerOrEditor && isOwnerOrEditor !== null && !summaryURL && activeProforma) {
    history.push("/proforma/" + activeProforma.id + "/summary")
  }

  // Function Definition:
  //  - retrieves proforma section data from proforma endpoint
  //  - no dependencies; runs once on page load to populate the app
  useEffect(() => {
    // retrieve section structure
    let url = process.env.REACT_APP_API_URL + "sections/";
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "GET",
      headers: headers
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()  //we only get here if there is no error
    })
    .then((data) => {
      setActiveProformaStructure(data)
    })
    .catch( err => {
      err.text().then( errorMessage => {
        let errorObject = JSON.parse(errorMessage)
        if (errorObject.detail) {
          if (errorObject.detail === "Authentication credentials were not provided.") {
            history.push("/login")
          } else if (errorObject.detail === "Not found.") {
            history.push("/error404")
          }
        }
      })
    })

    // retrieve proforma data
    let proformaURL = process.env.REACT_APP_API_URL + "proformas/" + proformaId;
    let proformaHeaders = new Headers();
    proformaHeaders.set('Content-type', 'application/json')
    proformaHeaders.set('Authorization', 'Bearer ' + token);
    fetch(proformaURL, {
      method: "GET",
      headers: proformaHeaders
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()  //we only get here if there is no error
    })
    .then((proformaData) => {
      setActiveProforma(proformaData)
    })
    .catch( err => {
      err.text().then( errorMessage => {
        let errorObject = JSON.parse(errorMessage)
        if (errorObject.detail) {
          if (errorObject.detail === "Authentication credentials were not provided.") {
            history.push("/login")
          } else if (errorObject.detail === "Not found.") {
            history.push("/error404")
          }
        }
      })
    })

    // if page loads either the activeSection or activeSubsection is null, direct to the review page
    if ((!activeSection || !activeSubsection) && !summaryURL) {
      history.push("/proforma/" + proformaId + "/review")
    }
  },[])

  useEffect(() => {
    try{
      document.getElementsByClassName("proforma-main-content")[0].scrollTo(0,0)
    }
    catch{
    }
  }, [activeSubsection])
  // Function Definition:
  //  -- calculate proforma completeness and section structure for naviation
  if (activeProformaStructure.length !== 0 && activeProforma) {
    calculateProformaProgress(activeProforma, activeProformaStructure, overallStatus)
  }

  // Function Definition:
  //  - Formats numbers to have comma separated values
  //  - https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  function formatOutputWithCommas(x) {
    if (Math.abs(Number(x)) < 1000) {
      x = Number(x).toFixed(2)
    }
    else {
      x = Number(x).toFixed(0)
    }
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  function formatOutputPercent(x) {
    let times100 = (x * 100).toFixed(2)
    let formattedNumber = times100.substring(times100.length-2,times100.length) === "00" ? Number(times100).toFixed(0) : Number(times100)
    return formattedNumber
  }

  if (activeProformaStructure.length !== 0 && activeProforma) {
    return (
      <div className="flexrow">
        <div className="col-xs-2 proforma-left-bar flexcolumn margin-left-none padding-left-none margin-right-none padding-right-none between-xs">
          <div>
            <BackArrowWithText
              text="All Projects"
              onClickURL={"/dashboard"}
            />
            <h2 className="white margin-bottom-xxsmall padding-left-xsmall padding-right-xsmall">
              {activeProforma.project_name}
            </h2>
            <ProgressBarElement
              percentComplete={(overallStatus.percentComplete * 100).toFixed(0)}
              hasPadding={true}
              hasLabel={true}
            />
            {isOwnerOrEditor && <ProformaSections
              sections={activeProformaStructure}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              setActiveSubsection={setActiveSubsection}
              projectType={activeProforma["project_type"]}
              proformaId={proformaId}
              summaryURL={summaryURL}
            />}
          </div>
          <div className="padding-left-xsmall padding-right-xsmall">
            <TGPLogo />
            <img src={TOELogo} alt="toe-logo" className="toe-image-logo" />
          </div>
        </div>
        {activeSection !== undefined && activeSubsection !== undefined && !reviewUrl && !summaryURL && <div className="col-xs-10 proforma-main-content flexcolumn between-xs">
          <div>
            <h1 className="padding-left-xsmall margin-bottom-xsmall">
              {activeProformaStructure.filter((section) => {return section.id === activeSection})[0].title}
            </h1>
            <SubsectionList
              subsections={activeProformaStructure.filter((section) => {return section.id === activeSection})[0].subsections}
              activeSubsection={activeSubsection}
              setActiveSubsection={setActiveSubsection}
            />
            {
              activeSubsection === 0 ?
                <SectionOverview
                  section={activeProformaStructure.filter((section) => {return section.id === activeSection})[0]}
                  projectType={activeProforma["project_type"]}
                />
              : activeSubsection === 99 ?
                <SectionSummary
                  section={activeProformaStructure.filter((section) => {return section.id === activeSection})[0]}
                  activeProforma={activeProforma}
                  formatOutputWithCommas={formatOutputWithCommas}
                  formatOutputPercent={formatOutputPercent}
                />
              :
              <InputGroups
                subsection={activeProformaStructure.filter((section) => {return section.id === activeSection})[0].subsections.filter((subsection) => {return subsection.order === activeSubsection})[0]}
                activeProforma={activeProforma}
                setActiveProforma={setActiveProforma}
                formatOutputWithCommas={formatOutputWithCommas}
                formatOutputPercent={formatOutputPercent}
                errorManager={errorManager}
                setErrorManager={setErrorManager}
                proformaId={proformaId}
              />
            }
          </div>
          <div className="margin-bottom-medium">
            <BottomBar
              sections={activeProformaStructure}
              subsections={activeProformaStructure.filter((section) => {return section.id === activeSection})[0].subsections}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              activeSubsection={activeSubsection}
              setActiveSubsection={setActiveSubsection}
              activeProforma={activeProforma}
            />
          </div>
        </div>}
        {summaryURL && <div className="col-xs-10 proforma-main-content flexcolumn between-xs">
          <ProjectSummary
            activeProforma={activeProforma}
            activeProformaStructure={activeProformaStructure}
            setActiveSection={setActiveSection}
            setActiveSubsection={setActiveSubsection}
            overallStatus={overallStatus}
            formatOutputWithCommas={formatOutputWithCommas}
            formatOutputPercent={formatOutputPercent}
          />
        </div>}
        {reviewUrl && <div className="col-xs-10 proforma-main-content flexcolumn between-xs">
          <ProjectReview
            activeProforma={activeProforma}
            activeProformaStructure={activeProformaStructure}
            setActiveSection={setActiveSection}
            setActiveSubsection={setActiveSubsection}
            overallStatus={overallStatus}
            formatOutputWithCommas={formatOutputWithCommas}
          />
        </div>
        }
      </div>
    );
  } else {
    return (
      <div className="flexrow center-xs padding-top-medium">
        <PropagateLoader
          size={25} color={"#FED766"} loading={true}
        />
      </div>
    )
  }

}
