import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';


// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    fontFamily: "Helvetica"
  },
  yellowHeader: {
    backgroundColor: '#FED766',
    height: "1.05in",
    width: "8.67in"
  },
  blackLine: {
    height: ".12in",
    width: "8.67in",
    backgroundColor: '#000000',
    marginBottom: "1%"
  },
  projectName: {
    marginTop: -30,
    marginLeft: 20
  },
  placeholder: {
    marginTop: "5%"
  },
  tgpLogo: {
    width: "1.01in",
    height: "1.54in",
    marginLeft: 470,
    marginTop: -8
  },
  toeLogo: {
    width: ".61in",
    height: ".59in",
    marginLeft: 550,
    marginTop: -100,
  },
  projectAddress: {
    fontSize: 10,
    marginLeft: 20,
    marginTop: 10
  },
  projectDescriptionContainer: {
    marginLeft: 230,
    marginTop: -50,
    marginRight: 10,
    lineHeight: 1.5,
    height: 72
  },
  projectDescription: {
    fontSize: 10,
  },
  usesTable: {
    width: "3.40in",
    height: "4.14in",
    marginLeft: 20,
    marginTop: 30
  },
  sourcesTable: {
    width: "3.3in",
    height: "4.14in",
    marginLeft: 300,
    marginTop: -298,
  },
  assumptionsTable: {
    width: "3.3in",
    height: "4.14in",
    marginLeft: 300,
    marginTop: -120
  },
  headerRow: {
    backgroundColor: '#FED766',
    textAlign: "center",
    fontSize: 13,
    height: 22
  },
  tableRow: {
    backgroundColor: '#fbe2b6',
    fontSize: 11,
    height: 20,
    marginTop: 1,
  },
  endTableRow: {
    backgroundColor: '#fbe2b6',
    fontSize: 11,
    height: 20,
    marginTop: 1,
    fontWeight: "bold"
  },
  disclaimerText: {
    fontSize: 6,
    marginLeft: 10,
    marginRight: 300
  },
  rentalContainer: {
    marginTop: 20,
    marginLeft: 20,
    height: 110
  },
  capitalContainer: {
    marginTop: 20,
    marginLeft: 20
  },
  projectTypeImage: {
    width: "2.76in",
    height: "1.39in",
    marginLeft: 20
  },
  disclaimerContainer: {
    marginTop: -144
  },
  footer: {
    width: "100%",
  },
  nameCell: {
    textAlign:"left",
    width: 190,
    paddingLeft: 2
  },
  numberCell: {
    textAlign:"left",
    width: 90,
    height: "100%",
    marginLeft: 150,
    marginTop: -16,
    borderLeftStyle: "solid",
    borderLeftColor: "#ffffff",
    borderLeftWidth: 1,
    paddingRight: 2
  },
  assumptionsTableNumberCell: {
    textAlign:"left",
    width: 55,
    height: "100%",
    marginLeft: 190,
    marginTop: -16,
    borderLeftStyle: "solid",
    borderLeftColor: "#ffffff",
    borderLeftWidth: 1,
    paddingRight: 2
  },
  divider: {
    backgroundColor: "#ffffff",
    width: 1,
    height: 16,
    marginLeft: 30
  },
  bullet: {
    fontSize:11,
    paddingTop: 5,
    paddingLeft: 10,
  },
  subBullet: {
    paddingLeft: 25,
    fontSize:11,
    paddingTop: 5
  },
  cellText: {
    marginLeft: 6,
    marginTop:4,
    width:"100%"
  },
  cellTextBold: {
    marginLeft: 6,
    marginTop:4,
    width:"100%",
    fontFamily:'Helvetica-Bold'

  }

});

function numberWithCommas(x) {
  if (x === "0"){
    return "-"
  }
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function PdfDocument(props) {

  let uses;
  let includesResidential = props.data["project_type"].includes("project_type_residential")
  let includesCommercial = props.data["project_type"].includes("project_type_commercial")
  let includesOffice = props.data["project_type"].includes("project_type_office")
  let residentialUnits = Number(props.data["calc_total_residential_units"]).toFixed(0)
  let hasAffordable = props.data["res_affordable"]
  let percentAffordable = (Number(props.data["res_affordableunits_percenttotal"])*100).toFixed(0)
  let affordableIncomePercent = (Number(props.data["res_affordableincome_percentami"])*100).toFixed(0)
  let ami = numberWithCommas(Number(props.data["res_ami"]).toFixed(0))
  let rentableResidentialSpace;
  let rentableCommercialSpace;
  let rentableOfficeSpace;
  let propertySf = 0
  if (props.data["calc_rentable_residential_square_footage"]){
    propertySf += Number(props.data["typemix_residential_newconstruction"])
    propertySf += Number(props.data["typemix_residential_renovation"])
    propertySf += Number(props.data["typemix_residential_no_reno"])
    rentableResidentialSpace = numberWithCommas(Number(props.data["calc_rentable_residential_square_footage"]).toFixed(0))
  }
  if (props.data["calc_rentable_commercial_square_footage"]) {
    propertySf += Number(props.data["typemix_commercial_newconstruction"])
    propertySf += Number(props.data["typemix_commercial_renovation"])
    propertySf += Number(props.data["typemix_commercial_existingsf"])
    rentableCommercialSpace = numberWithCommas(Number(props.data["calc_rentable_commercial_square_footage"]).toFixed(0))
  }
  if (props.data["calc_rentable_office_square_footage"]) {
    propertySf += Number(props.data["typemix_office_newconstruction"])
    propertySf += Number(props.data["typemix_office_renovation"])
    propertySf += Number(props.data["typemix_office_existingsf"])
    rentableOfficeSpace = numberWithCommas(Number(props.data["calc_rentable_office_square_footage"]).toFixed(0))
  }
  let imageUrl;
  if (includesResidential && includesCommercial && includesOffice){
    uses = "residential, commercial, and office"
    imageUrl = "https://groundup-prod.s3.us-east-2.amazonaws.com/ProjectCommercialOfficeResidential.png"
  }
  else if (includesResidential && !includesCommercial && includesOffice){
    uses = "residential and office"
    imageUrl = "https://groundup-prod.s3.us-east-2.amazonaws.com/ProjectOfficeResidential.png"
  }
  else if (includesResidential && includesCommercial && !includesOffice){
    uses = "residential and commercial"
    imageUrl = "https://groundup-prod.s3.us-east-2.amazonaws.com/ProjectCommercialResidential.png"
  }
  else if (includesResidential && !includesCommercial && !includesOffice){
    uses = "residential"
    imageUrl = "https://groundup-prod.s3.us-east-2.amazonaws.com/ProjectResidential.png"
  }
  else if (!includesResidential && includesCommercial && !includesOffice){
    uses = "commercial"
    imageUrl = "https://groundup-prod.s3.us-east-2.amazonaws.com/ProjectCommercial.png"
  }
  else if (!includesResidential && includesCommercial && includesOffice){
    uses = "commercial and office"
    imageUrl = "https://groundup-prod.s3.us-east-2.amazonaws.com/ProjectCommercialOffice.png"
  }
  else if (!includesResidential && !includesCommercial && includesOffice){
    uses = "office"
    imageUrl = "https://groundup-prod.s3.us-east-2.amazonaws.com/ProjectOffice.png"
  }
  let descriptionText = `The proposed project is a \
${numberWithCommas(propertySf.toFixed(0))} SF property to be used for ${uses} needs. The \
lot is ${numberWithCommas(Number(props.data["project_landsf"]).toFixed(0))} SF and will be/was purchased for $${numberWithCommas(Number(props.data["project_acquisition_price"]).toFixed(0))}.`

  return(
    <Document>
      <Page size="A4" debug={false}style={styles.page}>
        <View debug={false} style={styles.yellowHeader}>
          <View debug={false} style={styles.blackLine}></View>
          <View debug={false} style={styles.blackLine}></View>
          <Image style={styles.tgpLogo}
          src="https://groundup-prod.s3.us-east-2.amazonaws.com/Screen+Shot+2020-08-04+at+3.43.05+PM.png"/>
          <Image style={styles.toeLogo} debug={false}
          src="https://groundup-prod.s3.us-east-2.amazonaws.com/LogoCropped+(1).png"/>
          <Text style={styles.projectName}>{props.data["project_name"]}</Text>
        </View>
        <View>
          <Text debug={false} style={styles.projectAddress}>{props.data["project_address"]}</Text>
          <Image style={styles.projectTypeImage} debug={false}
          src={imageUrl}/>
        </View>
        <View debug={false} style={styles.projectDescriptionContainer}>
          <Text debug={false}>The Investment</Text>
          <Text debug={false} style={styles.projectDescription}>{descriptionText}</Text>
        </View>
        <View debug={false} style={styles.rentalContainer}>
          <Text debug={false}>Rental Highlights</Text>
          {includesResidential && rentableResidentialSpace && <Text debug={false} style={styles.bullet}>• {residentialUnits} Residential Units</Text>}
          {includesResidential && rentableResidentialSpace && hasAffordable && <Text debug={false} style={styles.subBullet}>o	{percentAffordable}% of units will be affordable at {affordableIncomePercent}% of AMI</Text>}
          {includesResidential && rentableResidentialSpace && <Text debug={false} style={styles.subBullet}>o	Local AMI is ${ami}</Text>}
          {includesResidential && rentableResidentialSpace && <Text debug={false} style={styles.bullet}>• {rentableResidentialSpace} SF of rentable residential space</Text>}
          {includesCommercial && rentableCommercialSpace && <Text debug={false} style={styles.bullet}>• {rentableCommercialSpace} SF of rentable commercial space</Text>}
          {includesOffice && rentableOfficeSpace && <Text debug={false} style={styles.bullet}>• {rentableOfficeSpace} SF of rentable office space</Text>}
        </View>
        <View style={styles.capitalContainer}>
          <Text debug={false}>Capital Highlights</Text>
          <View debug={false}style={styles.usesTable}>
            <View style={styles.headerRow}>
              <Text debug={false} styles={{textAlign:"center"}}>Uses</Text>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Property Acquisition Cost</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["project_acquisition_price"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Closing Costs</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_uses_closingcosts_total"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Site Work</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_uses_siteworkcosts_total"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Hard Construction Cost</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_uses_constructioncosts_total"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Parking Construction Cost</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_uses_parkingcosts_total"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Architectural & Engineering</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_uses_architecturalengineering_total"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Permitting & Zoning</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_uses_permitzoningconsulting_total"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Construction Contingency</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_uses_constructioncontingency_total"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Capitalized Interest</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_uses_capitalized_interest_total"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Financing Fees</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_uses_financingfees_total"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Developer Fees</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_uses_developerfee_total"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.endTableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={{fontFamily:'Helvetica-Bold', marginTop:4}}>Total Uses</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellTextBold}>$ {numberWithCommas(Number(props.data["calc_uses_total"]).toFixed(0))}</Text>
              </View>
            </View>
          </View>
          <View style={styles.sourcesTable}>
            <View style={styles.headerRow}>
              <Text debug={false} style={{textAlign:"center"}}>Sources</Text>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Debt</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_sources_permanentfinancingamount"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>City Incentives</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_sources_equity_cityamount"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Philanthropy</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["sources_equity_philanthropyamount"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Capped Return Capital</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["sources_equity_cappedreturncapitalamount"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Return of Capital</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["sources_equity_returnofcapitalamount"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Market Rate Equity</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellText}>$ {numberWithCommas(Number(props.data["calc_sources_equity_oppfundamount"]).toFixed(0))}</Text>
              </View>
            </View>
            <View style={styles.endTableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={{fontFamily:'Helvetica-Bold', marginTop:4}}>Total Sources</Text>
              </View>
              <View debug={false} style={styles.numberCell}>
                <Text debug={false} style={styles.cellTextBold}>$ {numberWithCommas(Number(props.data["calc_sources_total_amount"]).toFixed(0))}</Text>
              </View>
            </View>
          </View>

          <View style={styles.assumptionsTable}>
            <View style={styles.headerRow}>
              <Text debug={false} style={{textAlign:"center"}}>Project Assumptions</Text>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Loan to Cost Ratio</Text>
              </View>
              <View debug={false} style={styles.assumptionsTableNumberCell}>
                <Text debug={false} style={styles.cellText}>{(Number(props.data["loan_loantocost"])*100).toFixed(2)}%</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Exit Cap Rate</Text>
              </View>
              <View debug={false} style={styles.assumptionsTableNumberCell}>
                <Text debug={false} style={styles.cellText}>{(Number(props.data["exit_caprate"])*100).toFixed(2)}%</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Loan Interest Rate</Text>
              </View>
              <View debug={false} style={styles.assumptionsTableNumberCell}>
                <Text debug={false} style={styles.cellText}>{(Number(props.data["loan_loanrate_permanentfinancing"])*100).toFixed(2)}%</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View debug={false} style={styles.nameCell}>
                <Text debug={false} style={styles.cellText}>Debt Service Coverate Ratio: Year 5</Text>
              </View>
              <View debug={false} style={styles.assumptionsTableNumberCell}>
                <Text debug={false} style={styles.cellText}>{Number(props.data["calc_sources_debtservicecoverage_year5"]).toFixed(2)}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.disclaimerContainer} debug={false}>
          <Text debug={false} style={styles.disclaimerText}>Produced using GroundUp. The material provided is for informational purposes only and does not constitute an offer to buy or sell securities. For more detail, please review the GroundUp Terms of Service.</Text>
          <Image style={styles.footer} debug={false}
          src="https://groundup-prod.s3.us-east-2.amazonaws.com/footer.png"/>
        </View>

      </Page>
    </Document>
  );
}
