import React from "react";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip_InlineGray from "../tooltips/Tooltip_InlineGray.js"
import Tooltip_Gary from "../tooltips/Tooltip_Gary.js"
import Tooltip_Large_Banner from "../tooltips/Tooltip_Large_Banner.js"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export default function Number_Horizontal_EndAdornment(props) {

  const classes = useStyles();

  let errorHelperText = props.errorManager[props.name] === "Ensure that there are no more than 0 digits before the decimal point." ?
    "Please enter a valid decimal input."
    : props.errorManager[props.name]

  return (
    <div>
      <div className={props.tooltipType === "small" ? "flexrow middle-xs": "flexrow"}>
        <div className="col-xs-2">
          <p className={props.disabled ? "question-disabled":null}>{props.questionText}</p>
        </div>
        <div className="col-xs-4">
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id={props.name}
              type={"text"}
              label={props.placeholderText}
              name={props.name}
              value={props.value !== null ? props.formatInput(props.type, props.value, props.endAdornment) : ""}
              onChange={(event) => props.handleChange(event, props.type, props.endAdornment)}
              onBlur={(event) => props.handleChange(event, props.type, props.endAdornment)}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="start">{props.endAdornment}</InputAdornment>,
              }}
              disabled={props.disabled}
              error={props.errorManager[props.name] ? true : false}
              helperText={props.errorManager[props.name] ? errorHelperText : null}
            />
          </form>
        </div>
        {props.tooltipType === "small" ?
          <Tooltip_InlineGray
            tooltipText={props.tooltipText}
          />
        : props.tooltipType === "gary_tooltip" ?
          <Tooltip_Gary
            tooltipTitle={props.tooltipTitle}
            tooltipText={props.tooltipText}
          />
        :
          null
        }
      </div>
      {props.tooltipType === "large_banner" ?
        <Tooltip_Large_Banner
          tooltipTitle={props.tooltipTitle}
          tooltipText={props.tooltipText}
        />
      :
        null
      }
    </div>

  );
}
