import React from "react";
import Custom_Equity_Boolean_Horizontal from './Custom_Equity_Boolean_Horizontal.js'
import Custom_Equity_Table from './Custom_Equity_Table.js'
import Tooltip_Large_Banner from "../tooltips/Tooltip_Large_Banner.js"

export default function InputGroup_EquityTable(props) {

  // File Description:
  //  TODO

  let inputElements = []
  props.inputFields.sort(function(a, b) {
    return a.id - b.id;
  }).map((input,index) => {
    if (input.input_type === "boolean") {
      inputElements.push(
        <Custom_Equity_Boolean_Horizontal
          key={"input_"+index+input.name}
          type={input.input_type}
          questionText={input.question_copy}
          placeholderText={input.placeholder_text}
          name={input.name}
          activeProforma={props.proformaData}
          setActiveProforma={props.setProformaData}
          value={props.proformaData[input.name]}
          tooltipType={input.tooltip_type}
          tooltipText={input.tooltip}
          tooltipTitle={input.tooltip_title}
          patchProforma={props.patchProforma}
          patchProformaBulkBody={props.patchProformaBulkBody}
          proformaId={props.proformaId}
        />
      )
    } else if (input.input_type === "equity_table") {
      inputElements.push(
        <Custom_Equity_Table
          key={"input_"+index+input.name}
          questionText={input.question_copy}
          name={input.name}
          proformaData={props.proformaData}
          setProformaData={props.setProformaData}
          value={props.proformaData[input.name]}
          tooltipType={input.tooltip_type}
          tooltipText={input.tooltip}
          tooltipTitle={input.tooltip_title}
          formatOutputWithCommas={props.formatOutputWithCommas}
          patchProforma={props.patchProforma}
          patchProformaBulkBody={props.patchProformaBulkBody}
          proformaId={props.proformaId}
          disabled={!props.proformaData[input.dependency_field_name]}
        />
      )
    }
  })

  inputElements.push(
    <Tooltip_Large_Banner
      tooltipTitle={props.tooltipTitle}
      tooltipText={props.tooltipText}
    />
  )

  return (
    inputElements
  )

}
