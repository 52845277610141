import React from "react";
import ImagePDF from '../../static_assets/svg/ImagePDF.png';
import Button from '../buttons/Button.js';
import DownloadIcon from '@material-ui/icons/GetApp';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "../../summaryPdf.js";

export default function ProjectFinances(props) {
  return (
    <div className="padding-left-none col-xs-10">
      <div className="flexrow">
        <img src={ImagePDF} alt="download-pef" className="download-pdf" />
        <div className="col-xs-6 margin-left-small">
          <p>This PDF shows a high level project overview, including key project metrics. We hope you'll share it with your colleagues and potential investors.</p>
            <PDFDownloadLink
              document={<PdfDocument data={props.activeProforma}/>}
              fileName={props.activeProforma["project_name"]}
              style={{
              }}
              >
              {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : <Button
                buttonText={<span className="flexrow middle-xs center-xs"><DownloadIcon /> Download Project Snapshot PDF</span>}
                buttonClassName={"button-fill white-gray-border padding-xsmall margin-right-xsmall margin-top-xsmall"}
                disabled={false}
              />}
            </PDFDownloadLink>
        </div>
      </div>

    </div>

  )
}
