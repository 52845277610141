import React, {useState, useEffect, useContext} from 'react'
import { UserContext } from '../UserContext'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from "./buttons/Button.js"
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: '2px solid #fff',
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UserProfile(props) {
  const classes = useStyles();
  let history = useHistory();
  const {token, setToken} = useContext(UserContext)
  const [ showDeleteuserModal, setShowDeleteUserModal ] = useState(false)
  const [ deleteUserConfirmationText, setDeleteUserConfirmationText ] = useState("")
  const [ updatedUserData, setUpdatedUserData ] = useState()
  const [ errorManager, setErrorManager ] = useState({})

  const handleClose = () => {
    setShowDeleteUserModal(false);
  };

  let activeUserFieldNames = [
    {
      "name": "first_name",
      "label": "First Name",
      "type": null
    },
    {
      "name": "last_name",
      "label": "Last Name",
      "type": null
    },
    {
      "name": "email",
      "label": "Email",
      "type": null
    },
    {
      "name": "organization",
      "label": "Organization",
      "type": "profile"
    },
    {
      "name": "title",
      "label": "Title",
      "type": "profile"
    }
  ]

  useEffect(() => {
    if (props.activeUser) {
      setUpdatedUserData(JSON.parse(JSON.stringify(props.activeUser[0])))
    }
  },[props.activeUser])

  const handleChange = (event, field) => {
    // first, handle any errors
    let hasError = false
    if (event.target.value === "") {
      setErrorManager({
        ...errorManager,
        [event.target.name]: "This field cannot be blank",
      });
      hasError = true;
    } else if (event.target.name === "email") {
      let isValid = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(event.target.value)
      if (!isValid) {
        setErrorManager({
          ...errorManager,
          [event.target.name]: "Please enter a valid email address",
        });
        hasError = true;
      } else {
        setErrorManager({
          ...errorManager,
          [event.target.name]: null,
        });
      }
    } else {
      setErrorManager({
        ...errorManager,
        [event.target.name]: null,
      });
    }
    // then, update state
    if (field.type === "profile") {
      const prevUserData = {...updatedUserData};
      prevUserData.profile[event.target.name] = event.target.value;
      setUpdatedUserData(prevUserData);
    } else {
      setUpdatedUserData({
        ...updatedUserData,
        [event.target.name]: event.target.value,
      });
    }
    // then, if no error, patch profile data
    if (!hasError && event.type === "blur") {
      let url = process.env.REACT_APP_API_URL + "users/" + props.activeUser[0].id + "/"
      let headers = new Headers();
      headers.set('Content-type', 'application/json')
      headers.set('Authorization', 'Bearer ' + token);
      fetch(url, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(updatedUserData)
      })
      .then((response) => {
        if (!response.ok) { throw response }
        return response.json()
      })
      .then(data => {
        props.setActiveUser([data])
      }) // state is already updated, so nothing needed here
      .catch( err => {
        if (err.status === 401) {
          history.push("/login")
        } else if (err.status === 404 || err.status === 403 || err.status === 500) {
          history.push("/error404")
        }
      })
    }
  }

  const deleteUser = () => {
    let url = process.env.REACT_APP_API_URL + "users/" + props.activeUser[0].id + "/"
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "DELETE",
      headers: headers
    })
    .then((response) => {
      console.log(response)
      console.log(response.ok)
      if (!response.ok) { throw response }
      if (response.status === 204) {
        
        handleClose()
        props.logout(history)
      }
    })
    .catch( err => {
      if (err.status === 401) {
        history.push("/login")
      } else if (err.status === 404 || err.status === 403 || err.status === 500) {
        history.push("/error404")
      }
    })
  }
  return (
    <div className="flexrow">
      <div className="col-xs-6 margin-top-medium padding-left-small white-vertical-container">
        <h2>Edit Your GroundUp Account Information</h2>
        <div className="col-xs-12">
          <form className={classes.root} noValidate autoComplete="off">
            {updatedUserData && activeUserFieldNames.map((field,index) => {
              return (
                <div className="margin-top-small">
                  <TextField
                    id={field.name}
                    type="text"
                    label={field.label}
                    name={field.name}
                    value={field.type ? updatedUserData.profile[field.name] : updatedUserData[field.name]}
                    onChange={(event) => handleChange(event, field)}
                    onBlur={(event) => handleChange(event, field)}
                    variant="outlined"
                    error={errorManager[field.name] ? true : false}
                    helperText={errorManager[field.name] ? errorManager[field.name] : field.name === "email" ? "This updated email address will become your new login credential." : ""}
                  />
                </div>
              )
            })}
          </form>
          <div className="col-xs-2 margin-top-small padding-left-xxsmall">
            <Button
              buttonClassName="button-normal light-blue padding-xxsmall margin-right-xxsmall"
              buttonText={"Back to Dashboard"}
              onClick={() => {history.push("/dashboard")}}
            />
          </div>
        </div>
      </div>
      <div className="col-xs-6 margin-top-medium padding-left-small white-vertical-container">
        <h3>Danger Zone</h3>
        <div className="warning-box flexrow middle-xs">
          <div className="col-xs-8">
            <h5>Delete User Account</h5>
            <p>This will erase all data associated with your user account, including all your financial models.</p>
          </div>
          <div className="col-xs-4 end-xs">
            <Button
              buttonClassName="button-small red-no-border padding-xxsmall margin-right-xxsmall"
              buttonText={"Delete"}
              onClick={() => setShowDeleteUserModal(true)}
            />
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={showDeleteuserModal}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={showDeleteuserModal}>
                {props.activeUser && <div className={classes.paper + " warning-box flexrow wrap col-xs-4"}>
                  <div className="col-xs-12">
                    <h2 id="transition-modal-title">Are you absolutely sure?</h2>
                    <p id="transition-modal-description">This action cannot be undone. This will permanently delete your user account, its data, and remove all collaborator associations.</p>
                    <p>Please type <strong>{props.activeUser[0].email}</strong> to confirm.</p>
                    <form className={classes.root}>
                      <TextField
                        id="delete-user-text-field"
                        type="text"
                        name="deleteUserConfirmationText"
                        value={deleteUserConfirmationText}
                        onChange={(event) => setDeleteUserConfirmationText(event.target.value)}
                        variant="outlined"
                      />
                    </form>
                    <p>Once complete, you will be logged out of the application and returned to the signup page.</p>
                  </div>
                  <div className="col-xs-12">
                    <Button
                      buttonClassName="button-fill red-no-border padding-xxsmall margin-right-xxsmall"
                      disabled={props.activeUser[0].email === deleteUserConfirmationText ? false : true}
                      buttonText={"I understand the consequences, delete my account."}
                      onClick={(event) => {
                        deleteUser()
                      }}
                    />
                  </div>
                </div>}
              </Fade>
            </Modal>
          </div>
        </div>

      </div>
    </div>
  )
}
