import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import TooltipIcon from '@material-ui/icons/HelpOutline';
import { withStyles } from '@material-ui/core/styles';

const LargeFontTooltip = withStyles({
  tooltip: {
    fontSize: 14,
    color: "white",
  }
})(Tooltip);

export default function Tooltip_InlineGray(props) {

  var ReactDOMServer = require('react-dom/server');
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  var reactElement = htmlToReactParser.parse(props.tooltipText);

  return (
    <LargeFontTooltip
      title={reactElement}
      interactive
      placement="right"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
    >
      <TooltipIcon
        className="svg-light-gray"
      />
    </LargeFontTooltip>
  );
}
