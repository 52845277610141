import React from "react";
import Button from "../buttons/Button.js"
import Tooltip_InlineGray from "../tooltips/Tooltip_InlineGray.js"
import Tooltip_Large_Banner from "../tooltips/Tooltip_Large_Banner.js"
import Tooltip_Gary from "../tooltips/Tooltip_Gary.js"

export default function Custom_Equity_Boolean_Horizontal(props) {

  let yesButtonClass = props.value ? "button-fill padding-xsmall margin-right-xsmall royal-blue" : "button-fill padding-xsmall margin-right-xsmall white-gray-border"
  let noButtonClass  = !props.value ? "button-fill padding-xsmall margin-right-xsmall royal-blue" : "button-fill padding-xsmall margin-right-xsmall white-gray-border"

  return (
    <div className="flexcolumn">
      <div className="col-xs-6">
        <p className={props.disabled ? "navbar-link":null}>{props.questionText}</p>
      </div>
      <div className="flexrow">
        <div className="col-xs-6 flexrow middle-xs">
          <div className="col-xs-6 padding-left-none padding-right-xxsmall">
            <Button
              buttonText={"Yes"}
              buttonClassName={yesButtonClass}
              onClick={() => {
                props.patchProforma(props.proformaId,props.name,true)
              }}
              disabled={false}
            />
          </div>
          <div className="col-xs-6 padding-left-xxsmall padding-right-none">
            <Button
              buttonText={"No"}
              buttonClassName={noButtonClass}
              onClick={() => {
                let requestBody = {}
                requestBody[props.name] = false
                requestBody["sources_equity_nontraditional"] = []
                requestBody["sources_equity_philanthropyamount"] = 0
                requestBody["sources_equity_cappedreturncapitalamount"] = 0
                requestBody["sources_equity_returnofcapitalamount"] = 0
                requestBody["sources_equity_cappedreturncapitalcappedreturn"] = 0
                props.patchProformaBulkBody(props.proformaId,requestBody,props.name)
              }}
              disabled={false}
            />
          </div>
        </div>
        {props.tooltipType === "small" ?
          <div className="col-xs-1">
            <Tooltip_InlineGray
              tooltipText={props.tooltipText}
            />
          </div>
          : props.tooltipType === "gary_tooltip" ?
            <Tooltip_Gary
              tooltipTitle={props.tooltipTitle}
              tooltipText={props.tooltipText}
            />
          :
            null
        }
      </div>
      {props.tooltipType === "large_banner" ?
          <Tooltip_Large_Banner
            tooltipTitle={props.tooltipTitle}
            tooltipText={props.tooltipText}
          />
        :
          null
      }
    </div>
  );
}
