import React from "react";
import OutputTableSection from './OutputsTableSection.js'
import PropagateLoader from "react-spinners/PropagateLoader";

export default function OutputsTable(props) {

  if (props.proformaOutputs && props.proformaOutputs.length !== 0) {
    return (
      <div className="padding-left-none col-xs-10">
        <div className="outputs-table-container">
          {props.proformaOutputs.outputs.map((section,index) => {
            return (
              <OutputTableSection
                section={section}
                formatOutputWithCommas={props.formatOutputWithCommas}
              />
            )
          })}
        </div>
      </div>
    )
  } else {
    return (
      <div className="padding-left-none col-xs-10">
        <div className="outputs-table-container">
          <PropagateLoader
            size={25} color={"#FED766"} loading={true}
          />
        </div>
      </div>
    )
  }
}
