import React, {useState, useEffect, useContext} from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { UserContext } from '../../UserContext'
import { useHistory } from "react-router-dom";
import Button from "../buttons/Button.js"
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/HighlightOff';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: '2px solid #fff',
    padding: theme.spacing(2, 4, 3),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    width: '100%',
  },
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
}));


export default function ProjectShareModal(props) {

  const classes = useStyles();
  let history = useHistory();
  const {token, setToken} = useContext(UserContext)
  // state var to hold collaboration entries for the proforma in question
  const [ proformaShares, setProformaShares ] = useState()
  // state var to display error messaging when adding a new collaborator
  const [ addCollaboratorError, setAddColaboratorError ] = useState()
  // default body structure for API call to create new collaboration
  const [ newCollaboration, setNewCollaboration ] = useState({
    "collaborator_email": "",
    "permissions": "view_only",
    "proforma": props.proforma
  })
  // state var to manage whether or not the save button is active
  const [ isAddCollaboratorDisabled, setIsAddCollaboratorDisabled ] = useState(true)

  // Function Definition:
  //  -- handle change for the email input on the add new collaborator line
  //  -- any change event resets error messaging associated with the email field
  const handleChange = (event) => {
    setAddColaboratorError()
    setNewCollaboration({
      ...newCollaboration,
      [event.target.name]: event.target.value,
    });
  }

  // Function Definition:
  //  -- handle change for the dropdown permissions in the permissions modal table
  //  -- updates app state, and runs patch request
  const handlePermissionChange = (event, editedPermission) => {
    // update state to reflect the new permissions value
    // maps proformaShares, finds matching ids, and updates attribute in question
    setProformaShares(
      proformaShares.map(el => el.id === editedPermission.id ?
        {...el, [event.target.name]: event.target.value}
        : el
      )
    )
    // prepare data for patch request
    let data = {"permissions": event.target.value}
    // patch proforma
    let url = process.env.REACT_APP_API_URL + "collaborations/" + editedPermission.id + "/"
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data)
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()
    })
    .then(data => {}) // state is already updated, so nothing needed here
    .catch( err => {
      if (err.status === 401) {
        history.push("/login")
      } else if (err.status === 404 || err.status === 403 || err.status === 500) {
        history.push("/error404")
      }
    })
  }

  // Function Definition:
  //  -- adds a new collaboration to local state
  //  -- posts the collaboration to the backend
  //  -- prevents user from posting a collaboration with an email that is already affiliated with a collaboration entry
  //  -- handles error messaging if user does not exist in db
  //  -- prevents user from sharing with themself
  const addCollaboration = (newCollaboration) => {
    // if there are no collaboration entries that have the email of the newly added collaborator, then POST
    if (proformaShares.filter((permission) => {return permission.collaborator.username.toLowerCase() === newCollaboration.collaborator_email.toLowerCase() }).length !== 0) {
      // update error message if the email address is already in the list
      setAddColaboratorError("This proforma has already been shared with this user account.")
    } else if (newCollaboration.collaborator_email.toLowerCase() === props.activeUser[0].username) {
      // prevent user from sharing with themself
      setAddColaboratorError("You are the owner of this proforma! You can access it from the 'My Projects' tab.")
    } else {
      let url = process.env.REACT_APP_API_URL + "collaborations/"
      let headers = new Headers();
      headers.set('Content-type', 'application/json')
      headers.set('Authorization', 'Bearer ' + token);
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(newCollaboration)
      })
      .then((response) => {
        if (!response.ok) { throw response }
        return response.json()  //we only get here if there is no error
      })
      .then(data => {
        setProformaShares(proformaShares => [...proformaShares, data]); // add newly created object to app state
        setAddColaboratorError() // reset any error messages
        setNewCollaboration({
          "collaborator_email": "",
          "permissions": "view_only",
          "proforma": props.proforma
        }) // re-establish blank default value
      })
      .catch( err => {
        if (err.status === 401) {
          history.push("/login")
        } else if (err.status === 404) {
          if (err.statusText === "Not Found") {
            // update error message text if not found
            setAddColaboratorError("No accounts are associated with this email. Please try again.")
          }
        } else if (err.status === 500 || err.status === 403) {
          history.push("/error404")
        }
      })
    }
  }

  // Function Definition:
   // -- deletes a new collaboration to local state
   // -- deletes the collaboration to the backend
  const deleteCollaboration = (object) => {
    // remove from local state
    setProformaShares(proformaShares.filter(permission => permission.id !== object.id))
    // hit API with delete request
    let url = process.env.REACT_APP_API_URL + "collaborations/" + object.id + "/"
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "DELETE",
      headers: headers
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()
    })
    .then(data => {}) // nothing here, already removed from local state
    .catch( err => {
      if (err.status === 401) {
        history.push("/login")
      } else if (err.status === 404 || err.status === 403 || err.status === 500) {
        history.push("/error404")
      }
    })

  }

  // Function Definition:
  //  -- Gets all collaboration entries owned by the user
  //  -- Filters that list to those relevant to the current proforma, and sets that set to state
  useEffect(() => {
    // only hit API when shareOpen is true
    if (props.shareOpen) {
      let url = process.env.REACT_APP_API_URL + "collaborations"
      let headers = new Headers();
      headers.set('Content-type', 'application/json')
      headers.set('Authorization', 'Bearer ' + token);
      fetch(url, {
        method: "GET",
        headers: headers,
      })
      .then((response) => {
        if (!response.ok) { throw response }
        return response.json()
      })
      .then(data => {
        // filter the response to only the proforma that match the currently active proforma
        setProformaShares(data.filter((proforma) => {return proforma.proforma === props.proforma}))
      })
      .catch( err => {
        if (err.status === 401) {
          history.push("/login")
        } else if (err.status === 404 || err.status === 500 || err.status === 403) {
          history.push("/error404")
        }
      })
    }
  },[props.shareOpen])

  // Function Definition:
  //  -- updates the active status of the button on any change of collaborator info
  //  -- all fields need to be complete and email needs to pass regex for the save button to be active
  useEffect(() => {
    if (newCollaboration.collaborator_email !== "" &&
        (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(newCollaboration.collaborator_email) &&
        newCollaboration.permissions) {
      setIsAddCollaboratorDisabled(false)
    } else {
      setIsAddCollaboratorDisabled(true)
    }
  },[newCollaboration])

  // currentShareList is the list of collaborations currently associated with the proforma
  let currentShareList = []
  if (!proformaShares) {
    currentShareList.push(
      <div className="flexrow col-xs-10 center-xs padding-top-medium">
        <PropagateLoader
          size={25} color={"#FED766"} loading={true}
        />
      </div>
    )
  } else if (proformaShares.length === 0) {
    currentShareList.push(
      <div>Add your first collaborator below!</div>
    )
  } else {
    // each collaboration object shows the email, first & last name of user, the permissions, and an option to delete the collaboration
    let collaboratorElement = proformaShares.map((permission,index) => {
      return (
        <div className="flexrow middle-xs">
          <div className="col-xs-8">
            <p className="margin-bottom-none">{permission.collaborator.username}</p>
            {permission.collaborator.first_name && permission.collaborator.last_name && <span className="question-subtext">{permission.collaborator.first_name} {permission.collaborator.last_name}</span>}
          </div>
          <div className="col-xs-3">
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                name="permissions"
                value={permission.permissions}
                onChange={(event) => handlePermissionChange(event,permission)}
              >
                <MenuItem value={"view_only"}>Viewer</MenuItem>
                <MenuItem value={"view_edit"}>Editor</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-xs-1">
            <DeleteIcon
              onClick={() => deleteCollaboration(permission)}
              className="svg-delete"
            />
          </div>
        </div>
      )
    })
    currentShareList.push(
      <div>
        <h4>This proforma is shared with:</h4>
        {collaboratorElement}
      </div>
    )
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.shareOpen}
      onClose={props.handleShareClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.shareOpen}>
        <div className={classes.paper + " light-blue-border share-modal"}>
          <h2 id="transition-modal-title">Share Your Project</h2>
          {currentShareList}
          {proformaShares && <div>
            <p>Type a user's email to share this project with them:</p>
            <div className="flexrow col-xs-12 padding-top-xsmall padding-bottom-xsmall padding-left-none">
              <div className="col-xs-10 div-middle padding-left-none">
                <form className={classes.root} noValidate autoComplete="off">
                  <TextField
                    type="email"
                    label="Email"
                    name="collaborator_email"
                    value={newCollaboration.collaborator_email}
                    onChange={handleChange}
                    variant="outlined"
                    error={addCollaboratorError ? true : false}
                    helperText={addCollaboratorError ? addCollaboratorError : null}
                  />
                </form>
              </div>
              <div className="col-xs-2 div-middle">
                <Button
                  buttonText={"Share"}
                  buttonClassName={"button-small light-blue padding-xxsmall margin-right-xxsmall"}
                  onClick={() => addCollaboration(newCollaboration)}
                  disabled={isAddCollaboratorDisabled}
                />
              </div>
            </div>
          </div>}
            <Button
              buttonClassName="button-small light-blue padding-xxsmall margin-right-xxsmall"
              buttonText={"Close"}
              onClick={(event) => {
                props.handleShareClose()
              }}
            />
        </div>
      </Fade>
    </Modal>
  )
}
