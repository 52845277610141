import React from "react";
import GaryTheGroundhog from '../../static_assets/svg/GaryTheGroundhog.png';
import BarLoader from "react-spinners/BarLoader";

export default function InternalRateOfReturn(props) {

  let tooltipText = "<p>The Total IRR calculation includes all forms of non-debt financing, such as philanthropy, grants and tax credit dollars. This will cause it to differ from the Market Rate IRR or Developer IRR, even if those are the only true equity sources that you have in your model. Look at the Market Rate IRR number to determine potential returns to regular equity investors.</p><p>Projects with returns between 5% and 10% may be most attractive to social impact capital. Projects with returns above 10% may be attractive to private capital.</p><p>Projects with returns below these thresholds are likely not viable without seeking grants or other external funding. IRR expectations depend on your source of capital and will vary greatly between projects and investors.</p>"

  var ReactDOMServer = require('react-dom/server');
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  var reactElement = htmlToReactParser.parse(tooltipText);

  if (props.proformaOutputs && props.proformaOutputs.length !== 0) {
    return (
      <div className="flexrow col-xs-12 padding-left-none">
        <div className="col-xs-5 padding-left-none">
          <div className="irr-stat-wrapper border">
            <div className="col-xs-8">Total IRR</div>
            <div className="col-xs-4">{props.proformaOutputs.irr_summary.calc_irr_projectlevel}</div>
          </div>
          <div className="irr-stat-wrapper">
            <div className="col-xs-8">Market Rate IRR</div>
            <div className="col-xs-4">{props.proformaOutputs.irr_summary.calc_irr_ozfund}</div>
          </div>
          {props.proformaOutputs.irr_summary.calc_irr_developer && <div className="irr-stat-wrapper">
            <div className="col-xs-8">Developer IRR</div>
            <div className="col-xs-4">{props.proformaOutputs.irr_summary.calc_irr_developer}</div>
          </div>}
          {props.proformaOutputs.irr_summary.calc_irr_cappedreturncapital && <div className="irr-stat-wrapper">
            <div className="col-xs-8">Capped Return IRR</div>
            <div className="col-xs-4">{props.proformaOutputs.irr_summary.calc_irr_cappedreturncapital}</div>
          </div>}
          {props.proformaOutputs.irr_summary.calc_irr_projectlevel_error_summary && <div>
            <p className="error-text">{props.proformaOutputs.irr_summary.calc_irr_projectlevel_error_summary}</p>
          </div>}
        </div>
        <div className="col-xs-7">
          <div className="gary-tooltip-box">
            <h2 className="gary-title">Understanding IRR</h2>
            {reactElement}
          </div>
          <div className="flexrow middle-xs margin-top-xxsmall">
            <img src={GaryTheGroundhog} alt="help-tooltip" className="gary-image" />
            <div className="flexcolumn margin-left-xxsmall">
              <span className="question-subtext">Gary the Groundhog</span>
              <span className="size-80 light">GroundUp Expert</span>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="flexrow col-xs-12 padding-left-none">
        <div className="col-xs-5 padding-left-none">
          <div className="irr-stat-wrapper border">
            <div className="col-xs-8">Total IRR</div>
            <div className="col-xs-4"><BarLoader size={12} color={"#C4C4C4"} loading={true}/></div>
          </div>
          <div className="irr-stat-wrapper">
            <div className="col-xs-8">Market Rate IRR</div>
            <div className="col-xs-4"><BarLoader size={12} color={"#C4C4C4"} loading={true}/></div>
          </div>
        </div>
      </div>
    )
  }

}
