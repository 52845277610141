import React from "react";
import Button from '../buttons/Button.js'

export default function SelectMultiple_Buttons_Vertical(props) {

  // logic to handle chained patch requests; if the master field value becomes [], then ensure all dependent fields are patched to 0
  let arrayValues = props.proformaData[props.name]
  let requestBody = {}
  if (arrayValues.length === 0) {
    props.choices.sort(function(a, b) {
      return a.order - b.order;
    }).map((choice,index) => {
      if (props.proformaData[choice.dependency_field_name] != 0) {
        requestBody[choice.dependency_field_name] = 0
      }
    })
    if (Object.keys(requestBody).length !== 0) {props.patchProformaBulkBody(props.proformaId,requestBody,props.name)}
  }

  const onClickSelectMultiple = (fieldName, clickedChoice) => {
    let arrayValues = props.proformaData[fieldName]
    let requestBody = {}
    if (arrayValues.includes(clickedChoice)) {
      let valueIndex = arrayValues.indexOf(clickedChoice)
      arrayValues.splice(valueIndex,1)
      // begin building the request body that will also set the value of the dependent fields to zero
      requestBody[clickedChoice] = 0
      // handle slider updates here too, if in the residential section
      if (clickedChoice === "res_squareftperunit_studio") {requestBody["typemix_residential_studio_percent"] = 0}
      if (clickedChoice === "res_squareftperunit_1br") {requestBody["typemix_residential_1br_percent"] = 0}
      if (clickedChoice === "res_squareftperunit_2br") {requestBody["typemix_residential_2br_percent"] = 0}
      if (clickedChoice === "res_squareftperunit_3br") {requestBody["typemix_residential_3br_percent"] = 0}
    } else {
      arrayValues.push(clickedChoice)
    }
    // add the new value for the array field to request body
    requestBody[fieldName] = arrayValues
    props.patchProformaBulkBody(props.proformaId,requestBody,fieldName)
  }
  let customErrorText = ((props.errorManager[props.name] && props.mustCheckOne) || (props.proformaData[props.name].length === 0 && props.mustCheckOne)) ? <div class="error-text">
      <p>Your project must include an option from the list below.</p>
    </div>
    : null
  return (
    <div className="flexrow wrap middle-xs">
      <div className="col-xs-8">
        <p className="margin-bottom-none">{props.questionText}</p>
        <p className="question-subtext">{props.placeholderText}</p>
      </div>
      {customErrorText && <div className="col-xs-6">
        <p>{customErrorText}</p>
      </div>}
      <div className="col-xs-8 flexcolumn margin-top-xxsmall">
        {
          props.choices.sort(function(a, b) {
            return a.order - b.order;
          }).map((choice,index) => {
            let buttonSelectedClass = props.proformaData[props.name].includes(choice.dependency_field_name) ? "royal-blue" : "gray-gray-border"
            return (
              <Button
                key={index}
                buttonText={choice.text_name}
                buttonClassName={"button-75 padding-xsmall margin-bottom-xxsmall margin-right-xsmall " + buttonSelectedClass}
                onClick={() => onClickSelectMultiple(props.name,choice.dependency_field_name)}
                disabled={props.isDisabled}
              />
            )
          })
        }
      </div>
    </div>
  );
}
