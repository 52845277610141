import React from "react";

export default function CustomFiveColumnTable(props) {

  // File Description:
  //  --  TODO

  let residentialSection;
  let siteWorkSection;
  let commercialSection;
  let officeSection;
  let parkingSection;

  siteWorkSection = <div className="flexcolumn">
    <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
      <div className="col-xs-3">Site Work Cost</div>
      <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["construction_costpersf_sitework"]))} per sqft</div>
      <div className="col-xs-1 center-xs disabled-text">x</div>
      <div className="col-xs-2 end-xs div-bottom">{props.formatOutputWithCommas(Math.round(props.activeProforma["project_landsf"]))} sqft</div>
      <div className="col-xs-1 center-xs disabled-text">=</div>
      <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_uses_siteworkcosts_total"]))}</div>
    </div>
  </div>

  if (props.activeProforma["project_type"].includes("project_type_residential")) {
    residentialSection = <div className="flexcolumn">
      {props.activeProforma["typemix_residential"].includes("typemix_residential_newconstruction") && <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
        <div className="col-xs-3">Residential New Construction Cost</div>
        <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["construction_costpersf_residential_new_constr"]))} per sqft</div>
        <div className="col-xs-1 center-xs disabled-text">x</div>
        <div className="col-xs-2 end-xs div-bottom">{props.formatOutputWithCommas(Math.round(props.activeProforma["typemix_residential_newconstruction"]))} sqft</div>
        <div className="col-xs-1 center-xs disabled-text">=</div>
        <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_uses_construction_residential_totalcost_newconstruction"]))}</div>
      </div>}
      {props.activeProforma["typemix_residential"].includes("typemix_residential_renovation") && <div className="flexrow margin-top-xsmall margin-bottom-xsmall chart-title-row">
        <div className="col-xs-3">Residential Renovation Cost</div>
          <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["construction_costpersf_residential_reno"]))} per sqft</div>
          <div className="col-xs-1 center-xs disabled-text">x</div>
          <div className="col-xs-2 end-xs div-bottom">{props.formatOutputWithCommas(Math.round(props.activeProforma["typemix_residential_renovation"]))} sqft</div>
          <div className="col-xs-1 center-xs disabled-text">=</div>
          <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_uses_construction_residential_totalcost_renovation"]))}</div>
      </div>}
    </div>
  }

  if (props.activeProforma["project_type"].includes("project_type_commercial")) {
    commercialSection = <div className="flexcolumn">
      {props.activeProforma["typemix_commercial"].includes("typemix_commercial_newconstruction") && <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
        <div className="col-xs-3">Commercial New Construction Cost</div>
        <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["construction_costpersf_commercial_new_constr"]))} per sqft</div>
        <div className="col-xs-1 center-xs disabled-text">x</div>
        <div className="col-xs-2 end-xs div-bottom">{props.formatOutputWithCommas(Math.round(props.activeProforma["typemix_commercial_newconstruction"]))} sqft</div>
        <div className="col-xs-1 center-xs disabled-text">=</div>
        <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_uses_construction_commercial_totalcost_newconstruction"]))}</div>
      </div>}
      {props.activeProforma["typemix_commercial"].includes("typemix_commercial_renovation") && <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
        <div className="col-xs-3">Commercial Renovation Cost</div>
          <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["construction_costpersf_commercial_reno"]))} per sqft</div>
          <div className="col-xs-1 center-xs disabled-text">x</div>
          <div className="col-xs-2 end-xs div-bottom">{props.formatOutputWithCommas(Math.round(props.activeProforma["typemix_commercial_renovation"]))} sqft</div>
          <div className="col-xs-1 center-xs disabled-text">=</div>
          <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_uses_construction_commercial_totalcost_renovation"]))}</div>
      </div>}
    </div>
  }

  if (props.activeProforma["project_type"].includes("project_type_office")) {
    officeSection = <div className="flexcolumn">
      {props.activeProforma["typemix_office"].includes("typemix_office_newconstruction") && <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
        <div className="col-xs-3">Office New Construction Cost</div>
        <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["construction_costpersf_office_new_constr"]))} per sqft</div>
        <div className="col-xs-1 center-xs disabled-text">x</div>
        <div className="col-xs-2 end-xs div-bottom">{props.formatOutputWithCommas(Math.round(props.activeProforma["typemix_office_newconstruction"]))} sqft</div>
        <div className="col-xs-1 center-xs disabled-text">=</div>
        <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_uses_construction_office_totalcost_newconstruction"]))}</div>
      </div>}
      {props.activeProforma["typemix_office"].includes("typemix_office_renovation") && <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
        <div className="col-xs-3">Office Renovation Cost</div>
          <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["construction_costpersf_office_reno"]))} per sqft</div>
          <div className="col-xs-1 center-xs disabled-text">x</div>
          <div className="col-xs-2 end-xs div-bottom">{props.formatOutputWithCommas(Math.round(props.activeProforma["typemix_office_renovation"]))} sqft</div>
          <div className="col-xs-1 center-xs disabled-text">=</div>
          <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_uses_construction_office_totalcost_renovation"]))}</div>
      </div>}
    </div>
  }

  parkingSection = <div className="flexcolumn">
    <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
      <div className="col-xs-3">Parking</div>
      <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["parking_costperspace"]))} per space</div>
      <div className="col-xs-1 center-xs disabled-text">x</div>
      <div className="col-xs-2 end-xs div-bottom">{props.formatOutputWithCommas(Math.round(props.activeProforma["calc_parking_totalspaces"]))} spaces</div>
      <div className="col-xs-1 center-xs disabled-text">=</div>
      <div className="col-xs-2 end-xs div-bottom">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_uses_parkingcosts_total"]))}</div>
    </div>
  </div>



  return (
    <div>
      <div className="black-underline">
        <div className="flexcolumn">
          <div className="flexrow margin-top-xsmall margin-bottom-xsmall chart-title-row">
            <div className="col-xs-3"></div>
            <div className="col-xs-2 center-xs div-bottom">Unit Cost</div>
            <div className="col-xs-1 center-xs disabled-text"></div>
            <div className="col-xs-2 center-xs div-bottom">Area</div>
            <div className="col-xs-1 center-xs disabled-text"></div>
            <div className="col-xs-2 center-xs div-bottom">Total Cost</div>
          </div>
        </div>
        {siteWorkSection}
        {residentialSection}
        {commercialSection}
        {officeSection}
        {parkingSection}
      </div>
      <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
        <div className="col-xs-3">Total Construction Cost</div>
        <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_uses_constructioncosts_total"]))}</div>
      </div>
      <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
        <div className="col-xs-3">Total Use of Funds Expenses</div>
        <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_uses_total_nonconstruction"]))}</div>
      </div>
      <div className="total-box margin-top-xsmall margin-bottom-xsmall">
        <div className="flexrow padding-top-xsmall padding-bottom-xsmall">
          <div className="col-xs-3">Total Project Cost</div>
          <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_uses_total"]))}</div>
        </div>
      </div>

    </div>
  )
}
