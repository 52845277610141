import React, {useState} from "react";
import ResourcesFAQ from '../data/resourcesFAQ.json'
import ResourcesFAQCard from './ResourcesFAQCard.js'
import AppFooter from './navbar/AppFooter.js'


export default function Resources(props) {

  return (
    <div>
      <div className="padding-small margin-bottom-medium">
        <h1>GroundUp Resources</h1>
        <p className="gray">Take a look at the resources below that can help you navigate GroundUp.</p>
        {ResourcesFAQ.map((section,index) => {
          return (
            <div className="margin-top-small">
              <h2>{section.title}</h2>
              {section.question_group.map((questionGroup,index) => {
                return (
                  <div className="padding-left-xsmall">
                    {questionGroup.questions.map((question,index) => {
                      return(
                        <ResourcesFAQCard
                          question={question}
                        />
                      )

                    })}
                  </div>
                )
              })}
            </div>

          )
        })}
      </div>
      <AppFooter />
    </div>

  );
}
