import React from "react";
import {ReactComponent as ProjectCommercialOfficeResidential} from '../../static_assets/svg/ProjectCommercialOfficeResidential.svg';
import SectionSummary_Taxes from './SectionSummary_Taxes.js'
import SectionSummary_Other from './SectionSummary_Other.js'
import SectionSummary_ProjectFinances from './SectionSummary_ProjectFinances.js'

export default function SectionOverview(props) {

  let summaryOutputs = []
  if (props.section.id === 5) {
    summaryOutputs.push(
      <SectionSummary_Taxes
        section={props.section}
        activeProforma={props.activeProforma}
        formatOutputPercent={props.formatOutputPercent}
        formatOutputWithCommas={props.formatOutputWithCommas}
      />
    )
  } else if (props.section.id === 6) {
    summaryOutputs.push(
      <SectionSummary_ProjectFinances
        section={props.section}
        activeProforma={props.activeProforma}
        formatOutputPercent={props.formatOutputPercent}
        formatOutputWithCommas={props.formatOutputWithCommas}
      />
    )
  } else {
    summaryOutputs.push(
      <SectionSummary_Other
        section={props.section}
        activeProforma={props.activeProforma}
        formatOutputPercent={props.formatOutputPercent}
        formatOutputWithCommas={props.formatOutputWithCommas}
      />
    )
  }

  return (
    <div className="padding-top-small padding-left-xsmall">
      <div className="flexrow center-xs">
        <div className="col-xs-4">
          {<ProjectCommercialOfficeResidential />}
        </div>
      </div>
      <div className="flexrow center-xs">
        <div className="col-xs-4">
          <h1>{props.section.title}</h1>
        </div>
      </div>
      {summaryOutputs}
    </div>
  )
}
