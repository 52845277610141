import React from "react";
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export default function TextArea_Horizontal_NoAdornment(props) {

  const classes = useStyles();

  return (
    <div className="flexrow middle-xs">
      <div className="col-xs-2">
        <p>{props.questionText}</p>
      </div>
      <div className="col-xs-4">
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            key={props.key}
            id={props.name}
            type={props.type}
            label={props.questionText}
            placeholder={props.placeholderText}
            name={props.name}
            value={props.value ? props.value : ""}
            onChange={event => props.handleChange(event, props.type)}
            onBlur={event => props.handleChange(event, props.type)}
            variant="outlined"
            multiline
            rows={4}
          />
        </form>
      </div>
    </div>
  );
}
