import React, {useState} from 'react'
import { useHistory } from "react-router-dom";
import Button from "../buttons/Button.js"
import HomePageRight from "../HomePageRight.js"
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export default function EnterUsername(props) {
  const classes = useStyles();
  let history = useHistory();

  const [ username, setUsername ] = useState("")
  const [ usernameError, setUsernameError ] = useState("")
  const [ isLoading, setIsLoading ] = useState(false)
  const [ showConfirmation, setShowConfirmation ] = useState(false)

  function handleSubmit(e){
    e.preventDefault()
    setIsLoading(true)
    // prepare request, add headers, etc.
    let url = process.env.REACT_APP_API_URL + "groundup/password_reset/"
    let data = {"email": username}
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    // make request
    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data)
    })
    .then((response) => {
      if (!response.ok) { throw response }
      if (response.ok) {
        setIsLoading(false)
        setShowConfirmation(true)
      }
    })
    .catch( err => {
      setIsLoading(false)
      if (err.status === 401) {
        history.push("/login")
      // handle 400 error with error message
      } else if (err.status === 400) {
        setUsernameError("There is no active user associated with this e-mail address or the password can not be changed.")
      } else if (err.status === 403 || err.status === 404 || err.status === 500) {
        history.push("/error404")
      }
    })
  }

  return (
    <div className="flexrow center-xs">
      {!showConfirmation && <div className="col-xs-5 white-vertical-container start-xs margin-top-large">
        <div className="col-xs-12 center-xs">
          <h2>Forgot your password?</h2>
        </div>
        <div className="col-xs-12 start-xs">
          <p>Enter your email below, and you will receive an email momentarily with instructions for how to reset it.</p>
        </div>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="username"
            type="text"
            label="Email"
            name="username"
            value={username}
            onChange={(event) => {
              setUsername(event.target.value)
              setUsernameError()
            }}
            variant="outlined"
            error={usernameError ? true : false}
            helperText={usernameError ? usernameError : ""}
          />
        </form>
        <div className="center-xs margin-top-small">
          <Button
            buttonText={isLoading ? <ClipLoader size={15} color={"#ffffff"} loading={isLoading}/> : "Reset Password"}
            buttonClassName={"button-normal light-blue padding-xsmall margin-right-xsmall"}
            onClick={handleSubmit}
            disabled={isLoading}
          />
        </div>
      </div>}
      {showConfirmation && <div className="col-xs-5 white-vertical-container start-xs margin-top-large">
        <h2>You'll receive an email momentarily.</h2>
        <p>Check your email for a link that will direct you to reset your password.</p>
      </div>}
    </div>
      )


}
