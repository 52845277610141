import React from "react";

// import logos
import {ReactComponent as TGPLogo} from '../../static_assets/svg/TGPLogo.svg';
import TOELogo from '../../static_assets/svg/TOE_White.png';

export default function AppFooter(props) {

  return (
    <div className="footer">
      <div className="flexrow col-xs-12 between-xs margin-right-none margin-left-none padding-left-none middle-xs">
        <div className=" flexrow padding-left-xsmall padding-right-xsmall middle-xs">
          <TGPLogo className="footer-tgp-logo"/>
          <img src={TOELogo} alt="toe-logo" className="toe-image-logo" />
        </div>
        <div className='col-xs-4'>
          <p className="footer-text">This application was built with generous support from the Mastercard Center for Inclusive Growth.</p>
          <p>The Governance Project &#169;	2020</p>
        </div>
      </div>
    </div>
  );
}
