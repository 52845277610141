import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function UserNameDropdown(props) {

  const [anchorEl, setAnchorEl] = useState(null);
  let history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (url) => {
    setAnchorEl(null);
    url ? history.push(url) : props.logout(history)
  };

  return (
    <div className="flexrow middle-xs padding-left-small">
      <div className="flexcolumn padding-right-xxsmall">
        <div className="navbar-user-organization">{props.activeUser.profile.organization}</div>
        <div className="navbar-user-name">{props.activeUser.first_name} {props.activeUser.last_name}</div>
      </div>
      <ExpandMoreIcon
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="svg-light-gray"
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="navbar-user-dropdown"
      >
        <MenuItem onClick={() => handleClose("/dashboard")}>My Dashboard</MenuItem>
        <MenuItem onClick={() => handleClose("/editprofile")}>Edit Profile</MenuItem>
        <MenuItem onClick={() => handleClose()}>Logout</MenuItem>
      </Menu>
    </div>

  );
}
