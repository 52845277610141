import React from "react";
import {ReactComponent as ProjectCommercialOfficeResidential} from '../../static_assets/svg/ProjectCommercialOfficeResidential.svg';

export default function SectionSummary_Taxes(props) {

  let inputFields = props.section.summary_page_section[0].input.sort(function(a, b) {
      return a.id - b.id;
    }).map((inputField, index) => {return inputField})
  let calculationFields = props.section.summary_page_section[0].calculation.sort(function(a, b) {
      return a.id - b.id;
    }).map((calcField, index) => {return calcField})
  let fieldsToRender = [inputFields[0], calculationFields[0], inputFields[1], calculationFields[1]]

  return (
    <div className="flexrow center-xs">
      <div className="col-xs-6 start-xs">
        <div className="col-xs-12">
          <h3>Summary</h3>
        </div>
        {fieldsToRender.map((field,index) => {

          let formattedNumber;
          if (field.post_unit === "%") {
            formattedNumber = props.activeProforma[field.name] ? props.formatOutputPercent(props.activeProforma[field.name]) : ""
          } else {
            formattedNumber = props.activeProforma[field.name] ? props.formatOutputWithCommas(props.activeProforma[field.name]) : ""
          }

          return (
            <div key={index} className="flexrow margin-top-xsmall">
              <div className="col-xs-8"><strong>{field.question_shorthand ? field.question_shorthand : field.display_name}</strong></div>
              <div className="col-xs-4">{field.pre_unit}{formattedNumber} {field.post_unit}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
