import React from "react";
import {ReactComponent as ProjectCommercialOfficeResidential} from '../../static_assets/svg/ProjectCommercialOfficeResidential.svg';

export default function SectionSummary_ProjectFinances(props) {

  let sourcesFieldsArray = []
  let usesFieldsArray = []
  props.section.summary_page_section[0].calculation.map((field,index) => {
    field.name.includes("calc_uses") ? usesFieldsArray.push(field) : sourcesFieldsArray.push(field)
    return field
  })

  let totalRow = []
  let sourcesElement = sourcesFieldsArray.sort(function(a, b) {
      return a.id - b.id;
    }).map((field,index) => {
      if (!field.name.includes("calc_sources_total")) {
        return (
          <div className="flexrow margin-top-xxsmall">
            <div className="col-xs-8"><strong>{field.display_name}</strong></div>
            <div className="col-xs-4">${props.activeProforma[field.name] ? props.formatOutputWithCommas(props.activeProforma[field.name]):""}</div>
          </div>
        )
      } else {
        totalRow.push(
          <div className="flexrow margin-top-xxsmall">
            <div className="col-xs-8"><strong>{field.display_name}</strong></div>
            <div className="col-xs-4">${props.activeProforma[field.name] ? props.formatOutputWithCommas(props.activeProforma[field.name]):""}</div>
          </div>
        )
        return null
      }
    })

  let usesElement = usesFieldsArray.sort(function(a, b) {
      return a.id - b.id;
    }).map((field,index) => {
      if (field.name !== ("calc_uses_total")) {
        return (
          <div className="flexrow margin-top-xxsmall">
            <div className="col-xs-8"><strong>{field.display_name}</strong></div>
            <div className="col-xs-4">${props.activeProforma[field.name] ? props.formatOutputWithCommas(props.activeProforma[field.name]):""}</div>
          </div>
        )
      } else {
        totalRow.splice(0, 0,
          <div className="flexrow margin-top-xxsmall">
            <div className="col-xs-8"><strong>{field.display_name}</strong></div>
            <div className="col-xs-4">${props.activeProforma[field.name] ? props.formatOutputWithCommas(props.activeProforma[field.name]):""}</div>
          </div>
        )
        return null
      }
    })

  let totalRowElement = totalRow.map((field,index) => {
    return (
      <div className="col-xs-6 padding-left-none">
        {field}
      </div>
    )
  })

  let denominator = props.activeProforma["calc_uses_total"] ? Number(props.activeProforma["calc_uses_total"]) : 0
  let totalNumerator = 0
  let totalPercent = 0
  let colorArray = ["",'#12AAEB',"#475C85","#F2994A","#4FBF7E","#FED766","#4F4F4F","#9B51E0"]
  let chartBars = []
  let chartLegends = []
  sourcesFieldsArray.sort(function(a, b) {
    return b.id - a.id;
  }).map((field,index) => {
    if (!field.name.includes("calc_sources_total")) {
      let numeratorLookupField = field.name
      let numerator = props.activeProforma[numeratorLookupField] ? Number(props.activeProforma[numeratorLookupField]) : ""
      totalNumerator = Number(totalNumerator) + Number(numerator)
      let percentage = (numerator / denominator)
      totalPercent = totalPercent + Number(percentage)
      let textPercentage = parseInt(percentage*100).toFixed(0).toString() + "%"
      if (Number(numerator) !== 0) {
        chartBars.push(<div className="progress-bar-element" style={{width: textPercentage, backgroundColor: colorArray[index]}}></div>)
        chartLegends.push(
          <div className="col-xs-1 flexcolumn chart-label" style={{borderTop: '2px solid ' + colorArray[index]}}>
            <span>{field.display_name}</span>
            <span>{props.formatOutputWithCommas(numerator)}</span>
          </div>
        )
      }
    }
    return field
  })

  let totalPercentText = !isNaN(totalPercent) ? Math.round(totalPercent*100).toFixed(0).toString() + "%" : "0%"

  return (
    <div>
      <div className={props.alignLeft ? "flexrow start-xs" : "flexrow center-xs"}>
        <div className="col-xs-10 start-xs margin-bottom-small">
          {!props.hideIntroText && <h3>Summary</h3>}
          <div>
            <div className="flexrow between-xs chart-title-row middle-xs">
              <h4>Project Finances</h4>
              <div>
                <span>{props.formatOutputWithCommas(totalNumerator)}</span>
                <span> / </span>
                <span>{props.formatOutputWithCommas(denominator)}</span>
                <span> ({totalPercentText})</span>
              </div>
            </div>
            <div className="progress-bar-container flexrow">
              {chartBars}
            </div>
            <div className="margin-top-xxsmall flexrow">
              {chartLegends}
            </div>
          </div>
        </div>
      </div>
      <div className="flexrow center-xs black-underline padding-bottom-xsmall">
        <div className="col-xs-10 flexrow start-xs">
          <div className="col-xs-6 padding-left-none">
            {usesElement}
          </div>
          <div className="col-xs-6 padding-left-none">
            {sourcesElement}
          </div>
        </div>
      </div>
      <div className="flexrow margin-top-xsmall center-xs">
        <div className="col-xs-10 total-box flexrow start-xs padding-top-xxsmall padding-bottom-xxsmall middle-xs">
          {totalRowElement}
        </div>
      </div>
    </div>
  )
}
