import React, {useState} from "react";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export default function ProjectFinances(props) {

  const [ isExpanded, setIsExpanded ] = useState(false)

  let rowLabels = []
  let rowData = []

  // add years
  rowLabels.push(
    <div className="flexrow">
      <div className="white ">Year</div>
    </div>
  )

  rowData.push(
    <div className="padding-top-none padding-bottom-none data-row">
      <div className="col-xs-2 end-xs data-container">Year 0</div>
      <div className="col-xs-2 end-xs data-container">Year 1</div>
      <div className="col-xs-2 end-xs data-container">Year 2</div>
      <div className="col-xs-2 end-xs data-container">Year 3</div>
      <div className="col-xs-2 end-xs data-container">Year 4</div>
      <div className="col-xs-2 end-xs data-container">Year 5</div>
      <div className="col-xs-2 end-xs data-container">Year 6</div>
      <div className="col-xs-2 end-xs data-container">Year 7</div>
      <div className="col-xs-2 end-xs data-container">Year 8</div>
      <div className="col-xs-2 end-xs data-container">Year 9</div>
      <div className="col-xs-2 end-xs data-container">Year 10</div>
    </div>
  )

  props.section.rows.map((row,index) => {
    rowLabels.push(
      <div className={"flexrow data-row-label " + row.rowStyle}>
        <div>{row.rowLabel}</div>
      </div>
    )

    let valuesArray = Object.values(row.rowValues).length !== 0 ? Object.values(row.rowValues) : ["-","-","-","-","-","-","-","-","-","-","-",]
    // don't push year 11 into the array
    rowData.push(
      <div className="padding-top-none padding-bottom-none data-row">
        {valuesArray.map((value,index) => {
          if (index !== valuesArray.length - 1) {
            // round anything with absolute value over 1000 to zero decimals
            let roundedValue;
            if (value !== "-") {
              roundedValue = Math.abs(value) > 1000 ? value.toFixed(0) : value.toFixed(2)
            } else {
              roundedValue = value
            }

            return (
              <div className={"col-xs-2 flexrow between-xs data-container border " + row.rowStyle}>
                <span>$</span>
                <span>{props.formatOutputWithCommas(roundedValue)}</span>
              </div>
            )
          }
        })}
      </div>
    )

  })

  return (
    <div>
      <div className="flexrow col-xs-12 middle-xs">
        {isExpanded ?
          <KeyboardArrowDownIcon onClick={() => setIsExpanded(!isExpanded)} />
        : <KeyboardArrowRightIcon onClick={() => setIsExpanded(!isExpanded)} />
        }
        <p className="output-section-title margin-left-xsmall">{props.section.title}</p>
      </div>
      {isExpanded && <div>
        <div className="wrapper flexrow">
          <div className="col-xs-4 first-column padding-left-none padding-right-none">
            {rowLabels}
          </div>
          <div className="second-column">
            {rowData}
          </div>
        </div>
      </div>}
    </div>
  )
}
