import React, {useState} from "react";
import GaryTheGroundhog from '../../static_assets/svg/GaryTheGroundhog.png';
import Tooltip_Large_Banner from "../tooltips/Tooltip_Large_Banner.js"
import SelectMultiple_Images_Horizontal_Icon from "./SelectMultiple_Images_Horizontal_Icons.js"
import {ReactComponent as ProjectCommercial} from '../../static_assets/svg/ProjectCommercial.svg';
import {ReactComponent as ProjectOffice} from '../../static_assets/svg/ProjectOffice.svg';
import {ReactComponent as ProjectResidential} from '../../static_assets/svg/ProjectResidential.svg';

export default function SelectMultiple_Images_Horizontal(props) {

  var ReactDOMServer = require('react-dom/server');
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  var reactElement = htmlToReactParser.parse(props.tooltipText);

  let residential_input_list = [
    {"typemix_residential": []},
    {"typemix_residential_newconstruction": null},
    {"typemix_residential_renovation": null},
    {"typemix_residential_no_reno": null},
    {"typemix_residential_efficiency": null},
    {"parking_requirement": false},
    {"parking_spacesperunit": null},
    {"typemix_residential_units": []},
    {"res_squareftperunit_studio": null},
    {"res_squareftperunit_1br": null},
    {"res_squareftperunit_2br": null},
    {"res_squareftperunit_3br": null},
    {"typemix_residential_studio_percent": null},
    {"typemix_residential_1br_percent": null},
    {"typemix_residential_2br_percent": null},
    {"typemix_residential_3br_percent": null},
    {"res_ami": null},
    {"res_affordable": false},
    {"res_affordableunits_percenttotal": null},
    {"res_rentpercentincome": null},
    {"res_affordableincome_percentami": null},
    {"res_marketrent_percentami": null},
    {"res_otherrevenue_utilityreimbursement_percentofrent": null},
    {"res_otherrevenue_miscellaneousincome_percentofrent": null},
    {"res_otherrevenue_nonrevenueunits_percentofrent": null},
    {"res_otherrevenue_uncollectiblerent_percentofrent": null},
    {"res_operatingexpenses_repairsmaintenance_annual": null},
    {"res_operatingexpenses_marketing_annual": null},
    {"res_operatingexpenses_payroll_annual": null},
    {"res_operatingexpenses_propertyinsurance_costperunit": null},
    {"res_operatingexpenses_generaladmin_annual": null},
    {"res_operatingexpenses_utilities_costperunit": null},
    {"res_operatingexpenses_mgmtfees_percentofrent": null},
    {"res_operatingexpenses_replacementreserve_percentofrent": null},
    {"res_growth_occupancyrate_year1": null},
    {"res_growth_occupancyrate_year2": null},
    {"res_growth_occupancyrate_year3": null},
    {"res_growth_occupancyrate_year4": null},
    {"res_growth_occupancyrate_year5": null},
    {"res_growth_occupancyrate_year6": null},
    {"res_growth_occupancyrate_year7": null},
    {"res_growth_occupancyrate_year8": null},
    {"res_growth_occupancyrate_year9": null},
    {"res_growth_occupancyrate_year10": null},
    {"res_growth_occupancyrate_year11": null},
    {"res_growth_rentgrowth_year1": null},
    {"res_growth_rentgrowth_year2": null},
    {"res_growth_rentgrowth_year3": null},
    {"res_growth_rentgrowth_year4": null},
    {"res_growth_rentgrowth_year5": null},
    {"res_growth_rentgrowth_year6": null},
    {"res_growth_rentgrowth_year7": null},
    {"res_growth_rentgrowth_year8": null},
    {"res_growth_rentgrowth_year9": null},
    {"res_growth_rentgrowth_year10": null},
    {"res_growth_rentgrowth_year11": null},
    {"res_growth_expensegrowth_year1": null},
    {"res_growth_expensegrowth_year2": null},
    {"res_growth_expensegrowth_year3": null},
    {"res_growth_expensegrowth_year4": null},
    {"res_growth_expensegrowth_year5": null},
    {"res_growth_expensegrowth_year6": null},
    {"res_growth_expensegrowth_year7": null},
    {"res_growth_expensegrowth_year8": null},
    {"res_growth_expensegrowth_year9": null},
    {"res_growth_expensegrowth_year10": null},
    {"res_growth_expensegrowth_year11": null},
    {"res_growth_otherrevenuegrowth_year1": null},
    {"res_growth_otherrevenuegrowth_year2": null},
    {"res_growth_otherrevenuegrowth_year3": null},
    {"res_growth_otherrevenuegrowth_year4": null},
    {"res_growth_otherrevenuegrowth_year5": null},
    {"res_growth_otherrevenuegrowth_year6": null},
    {"res_growth_otherrevenuegrowth_year7": null},
    {"res_growth_otherrevenuegrowth_year8": null},
    {"res_growth_otherrevenuegrowth_year9": null},
    {"res_growth_otherrevenuegrowth_year10": null},
    {"res_growth_otherrevenuegrowth_year11": null},
  ]
  let commercial_input_list = [
    {"typemix_commercial": []},
    {"typemix_commercial_newconstruction": null},
    {"typemix_commercial_renovation": null},
    {"typemix_commercial_existingsf": null},
    {"typemix_commercial_efficiency": null},
    {"parking_commercial": false},
    {"parking_commercialsfperspace": null},
    {"comm_rentpersquarefoot": null},
    {"comm_operatingexpenses_repairmaintenance_annual": null},
    {"comm_operatingexpenses_marketing_annual": null},
    {"comm_operatingexpenses_generaladmin_annual": null},
    {"comm_operatingexpenses_janitorial_annual": null},
    {"comm_operatingexpenses_utilities_monthlytotal": null},
    {"comm_operatingexpenses_propertyinsurance_monthlytotal": null},
    {"comm_operatingexpenses_managementfees_percentofrent": null},
    {"comm_operatingexpenses_tenantimprovements_annual": null},
    {"comm_growth_occupancyrate_year1": null},
    {"comm_growth_occupancyrate_year2": null},
    {"comm_growth_occupancyrate_year3": null},
    {"comm_growth_occupancyrate_year4": null},
    {"comm_growth_occupancyrate_year5": null},
    {"comm_growth_occupancyrate_year6": null},
    {"comm_growth_occupancyrate_year7": null},
    {"comm_growth_occupancyrate_year8": null},
    {"comm_growth_occupancyrate_year9": null},
    {"comm_growth_occupancyrate_year10": null},
    {"comm_growth_occupancyrate_year11": null},
    {"comm_growth_rentgrowth_year1": null},
    {"comm_growth_rentgrowth_year2": null},
    {"comm_growth_rentgrowth_year3": null},
    {"comm_growth_rentgrowth_year4": null},
    {"comm_growth_rentgrowth_year5": null},
    {"comm_growth_rentgrowth_year6": null},
    {"comm_growth_rentgrowth_year7": null},
    {"comm_growth_rentgrowth_year8": null},
    {"comm_growth_rentgrowth_year9": null},
    {"comm_growth_rentgrowth_year10": null},
    {"comm_growth_rentgrowth_year11": null},
    {"comm_growth_expensegrowth_year1": null},
    {"comm_growth_expensegrowth_year2": null},
    {"comm_growth_expensegrowth_year3": null},
    {"comm_growth_expensegrowth_year4": null},
    {"comm_growth_expensegrowth_year5": null},
    {"comm_growth_expensegrowth_year6": null},
    {"comm_growth_expensegrowth_year7": null},
    {"comm_growth_expensegrowth_year8": null},
    {"comm_growth_expensegrowth_year9": null},
    {"comm_growth_expensegrowth_year10": null},
    {"comm_growth_expensegrowth_year11": null}
  ]
  let office_input_list = [
    {"typemix_office": []},
    {"typemix_office_newconstruction": null},
    {"typemix_office_renovation": null},
    {"typemix_office_existingsf": null},
    {"typemix_office_efficiency": null},
    {"parking_office": false},
    {"parking_officesfperspace": null},
    {"office_rentpersquarefoot": null},
    {"office_operatingexpenses_repairmaintenance_annual": null},
    {"office_operatingexpenses_marketing_annual": null},
    {"office_operatingexpenses_generaladmin_annual": null},
    {"office_operatingexpenses_janitorial_annual": null},
    {"office_operatingexpenses_tenantimprovements_annual": null},
    {"office_operatingexpenses_utilities_monthlytotal": null},
    {"office_operatingexpenses_propertyinsurance_monthlytotal": null},
    {"office_operatingexpenses_managementfees_percentofrent": null},
    {"office_growth_occupancyrate_year1": null},
    {"office_growth_occupancyrate_year2": null},
    {"office_growth_occupancyrate_year3": null},
    {"office_growth_occupancyrate_year4": null},
    {"office_growth_occupancyrate_year5": null},
    {"office_growth_occupancyrate_year6": null},
    {"office_growth_occupancyrate_year7": null},
    {"office_growth_occupancyrate_year8": null},
    {"office_growth_occupancyrate_year9": null},
    {"office_growth_occupancyrate_year10": null},
    {"office_growth_occupancyrate_year11": null},
    {"office_growth_rentgrowth_year1": null},
    {"office_growth_rentgrowth_year2": null},
    {"office_growth_rentgrowth_year3": null},
    {"office_growth_rentgrowth_year4": null},
    {"office_growth_rentgrowth_year5": null},
    {"office_growth_rentgrowth_year6": null},
    {"office_growth_rentgrowth_year7": null},
    {"office_growth_rentgrowth_year8": null},
    {"office_growth_rentgrowth_year9": null},
    {"office_growth_rentgrowth_year10": null},
    {"office_growth_rentgrowth_year11": null},
    {"office_growth_expensegrowth_year1": null},
    {"office_growth_expensegrowth_year2": null},
    {"office_growth_expensegrowth_year3": null},
    {"office_growth_expensegrowth_year4": null},
    {"office_growth_expensegrowth_year5": null},
    {"office_growth_expensegrowth_year6": null},
    {"office_growth_expensegrowth_year7": null},
    {"office_growth_expensegrowth_year8": null},
    {"office_growth_expensegrowth_year9": null},
    {"office_growth_expensegrowth_year10": null},
    {"office_growth_expensegrowth_year11": null},
  ]

  const onClickSelectMultiple = (fieldName, clickedChoice) => {
    let arrayValues = props.proformaData[fieldName]
    let requestBody = {}
    if (arrayValues.includes(clickedChoice)) {
      let valueIndex = arrayValues.indexOf(clickedChoice)
      arrayValues.splice(valueIndex,1)
      // build the body of the request by iterating through the fields associated with the use, and adding them to the request with their default value
      if (clickedChoice === "project_type_residential") {
        residential_input_list.map((field,index) => {
          let key = Object.keys(field)[0]
          let value = Object.values(field)[0]
          requestBody[key] = value
          return field
        })
      } else if (clickedChoice === "project_type_commercial") {
        commercial_input_list.map((field,index) => {
          let key = Object.keys(field)[0]
          let value = Object.values(field)[0]
          requestBody[key] = value
          return field
        })
      } else if (clickedChoice === "project_type_office") {
        office_input_list.map((field,index) => {
          let key = Object.keys(field)[0]
          let value = Object.values(field)[0]
          requestBody[key] = value
          return field
        })
      }
    } else {
      arrayValues.push(clickedChoice)
    }
    props.setProformaData({
      ...props.proformaData,
      [props.name]: arrayValues
    });
    requestBody[props.name] = arrayValues
    props.patchProformaBulkBody(props.proformaId,requestBody,fieldName)
  }

  let noneSelectedError = props.proformaData[props.name].length === 0 ? true : false

  return (
    <div className="flexrow wrap middle-xs center-xs">
      <div className="col-xs-12 center-xs">
        <h1 className="margin-bottom-xxsmall">{props.questionText}</h1>
        <p className="question-subtext">{props.placeholderText}</p>
      </div>
      <div className="col-xs-10 flexrow margin-top-xxsmall center-xs between-xs">
        {
          props.choices.sort(function(a, b) {
            return a.order - b.order;
          }).map((choice,index) => {
            return (
              <SelectMultiple_Images_Horizontal_Icon
                choice={choice}
                onClickSelectMultiple={onClickSelectMultiple}
                name={props.name}
                proformaData={props.proformaData}
                patchLoader={props.patchLoader}
              />
            )
          })
        }
      </div>
      {noneSelectedError && <div className="col-xs-10 flexrow margin-top-xxsmall center-xs">
        <h4 className="red">Please make a selection by clicking on the project icons that are relevant for your project.</h4>
      </div>}
      <div className="flexrow wrap col-xs-12 margin-top-small start-xs">
        <div className="col-xs-12 large-banner-container">
          <div>
            <h2 className="gary-title">{props.tooltipTitle}</h2>
            {reactElement}
          </div>
        </div>
        <div className="flexrow middle-xs margin-top-xsmall">
          <img src={GaryTheGroundhog} alt="help-tooltip" className="gary-image" />
          <div className="flexcolumn margin-left-xxsmall">
            <span className="question-subtext">Gary the Groundhog</span>
            <span className="size-80 light">GroundUp Expert</span>
          </div>
        </div>
      </div>
    </div>
  );
}
