import React, {useState, useEffect, useContext} from "react";
import { UserContext } from '../../UserContext'
import { useHistory } from "react-router-dom";
import Button from '../buttons/Button.js'

// import summary page components
import InternalRateOfReturn from '../summaryPage/InternalRateOfReturn.js'
import ProjectFinances from '../summaryPage/ProjectFinances.js'
import PDFOverview from '../summaryPage/PDFOverview.js'
import UnderstandingYourResults from '../summaryPage/UnderstandingYourResults.js'
import KeySummaryOutputs from '../summaryPage/KeySummaryOutputs.js'
import OutputsTable from '../summaryPage/OutputsTable.js'

export default function ProjectSummary(props) {

  const {token, setToken} = useContext(UserContext)
  let history = useHistory();
  const [ proformaOutputs, setProformaOutputs ] = useState()

  // prevents a user from accessing the summary page before the proforma is complete
  if (Number(props.overallStatus.percentComplete) !== 1) {
    history.push("/proforma/" + props.activeProforma.id + "/review")
  }

  // fetch summary data on page load
  useEffect(() => {
    // fetch and set state in response
    let url = process.env.REACT_APP_API_URL + "proforma/summary/" + props.activeProforma.id
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "GET",
      headers: headers,
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()  //we only get here if there is no error
    })
    .then(data => setProformaOutputs(data))
    .catch( err => {
      if (err.status === 401) {
        history.push("/login")
      } else if (err.status === 404 || err.status === 500 || err.status === 403) {
        history.push("/error404")
      }
    })
  },[])

    return (
      <div className="col-xs-12 padding-left-small margin-top-small margin-bottom-large">
        <h1 className="margin-bottom-xxsmall">Model Results</h1>
        <h4 className="margin-top-none">10 year projections</h4>
        <div className="margin-top-small">
          <h2>Internal Rate of Return</h2>
          <InternalRateOfReturn
            activeProforma={props.activeProforma}
            proformaOutputs={proformaOutputs}
          />
        </div>
        <div className="margin-top-small">
          <h2>Project Finances</h2>
          <ProjectFinances
            activeProforma={props.activeProforma}
            formatOutputWithCommas={props.formatOutputWithCommas}
          />
        </div>
        <div className="margin-top-small">
          <h2>PDF Overview</h2>
          <PDFOverview
            activeProforma={props.activeProforma}
          />
        </div>
        <div className="margin-top-small">
          <h2>Understanding Your Results</h2>
          <UnderstandingYourResults />
        </div>
        <div className="margin-top-small">
          <h2>Key Summary Outputs</h2>
          <p className="margin-top-xxsmall margin-bottom-xxsmall">Click the header rows below to expand and show more information.</p>
          <KeySummaryOutputs
            activeProforma={props.activeProforma}
            activeProformaStructure={props.activeProformaStructure}
            formatOutputWithCommas={props.formatOutputWithCommas}
            formatOutputPercent={props.formatOutputPercent}
          />
        </div>
        <div className="margin-top-small">
          <h2>Detailed 10 Year Projections</h2>
          <OutputsTable
            proformaOutputs={proformaOutputs}
            formatOutputWithCommas={props.formatOutputWithCommas}
          />
        </div>
      </div>
    );
}
