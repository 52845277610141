import React, {useState, useContext} from "react";
import { UserContext } from '../../UserContext'
import Number_Horizontal_EndAdornment from './Number_Horizontal_EndAdornment.js'
import Button from '../buttons/Button.js'
import Tooltip_InlineGray from "../tooltips/Tooltip_InlineGray.js"
import Tooltip_Gary from "../tooltips/Tooltip_Gary.js"

export default function InputGroup_Growth_Table(props) {

  // File Description:
  //  TODO

  const {token, setToken} = useContext(UserContext)
  const [ showDetailedInputs, setShowDetailedInputs ] = useState(false)
  const [ isCustomSelected, setIsCustomSelected ] = useState(false)

  const handleButtonClick = (text, value) => {

    if (text !== "Custom") {
      let copyProformaData = {...props.proformaData}
      let requestBody = {}
      props.inputFields.map((input,index) => {
        copyProformaData[input.name] = value[index]
        requestBody[input.name] = value[index]
      })
      let url = process.env.REACT_APP_API_URL + "proformas/" + props.proformaId +"/";
      let headers = new Headers();
      let jsonData = JSON.stringify(requestBody)
      console.log("Request JSON: " + jsonData)
      headers.set('Content-type', 'application/json')
      headers.set('Authorization', 'Bearer ' + token);
      fetch(url, {
        method: "PATCH",
        headers: headers,
        body: jsonData
      })
      .then((response) => {
        if (!response.ok) { throw response }
        return response.json()  //we only get here if there is no error
      })
      .then((data) => {
        console.log(data)
        props.setProformaData(data);
        setIsCustomSelected(false)
        setShowDetailedInputs(true)
      })
    } else {
      setIsCustomSelected(true)
      setShowDetailedInputs(true)
    }

  }

  // create a flag that flips to true if any of the predefined value arrays for the growth table are met
  let isAnySelectedClass = false
  let isAnyInputNull = false
  let buttonElements = props.growthTableSettings.sort(function(a, b) {
    return a.order - b.order;
  }).map((growthOption,index) => {
    // get array of current values for all inputs in the InputGroup
    let inputGroupFieldValues = props.inputFields.map((input,index) => {
        if (props.proformaData[input.name] === null) {
          isAnyInputNull = true
        }
        return props.proformaData[input.name] !== null ? Number(props.proformaData[input.name]) : null
    })
    // get array of values from the growth setting
    let growthTableSettingsValues = growthOption.value
    // create boolean for managing whether to select the button
    let isCurrentSelectedClass;
    // if option is custom, and either custom is selected or (no other conditions have been met && they are not null) -> TRUE
    if (growthOption.text === "Custom" && (isCustomSelected || (!isAnySelectedClass && !isAnyInputNull))) {
      isCurrentSelectedClass = true
    // if isCustomSelected and the current option is not Custom -> FALSE
    } else if (isCustomSelected && growthOption.text !== "Custom") {
      isCurrentSelectedClass = false
    // if !isCustomSelected && current option is Custom -> FALSE
    } else if (!isCustomSelected && growthOption.text === "Custom") {
      isCurrentSelectedClass = false
    // if !isCustomSelected && current option is Custom -> compare values in inputFields to those in growth setting, and use comparision to determine whether to select
    } else if (!isCustomSelected && growthOption.text !== "Custom") {
      isCurrentSelectedClass = JSON.stringify(inputGroupFieldValues)==JSON.stringify(growthTableSettingsValues);
      // if they are equal, flip the isAnySelectedClass to true to the custom button is not highlighted
      if (isCurrentSelectedClass) {isAnySelectedClass = true}
    }

    let buttonSelectedClass = isCurrentSelectedClass ? "royal-blue" : "white-gray-border"
    return (
      <Button
        key={index}
        buttonText={growthOption.text}
        buttonClassName={"button-fill padding-xsmall margin-bottom-xxsmall margin-right-xsmall " + buttonSelectedClass}
        onClick={() => handleButtonClick(growthOption.text,growthOption.value)}
      />
    )
  })

  let detailedInputs = props.inputFields.sort(function(a, b) {
    return a.id - b.id;
  }).map((input,index) => {
    return (
      <Number_Horizontal_EndAdornment
        key={index}
        type={input.input_type}
        questionText={input.question_copy}
        placeholderText={input.placeholder_text}
        name={input.name}
        value={props.proformaData[input.name] === null ? "" : props.proformaData[input.name]}
        handleChange={props.handleChange}
        endAdornment={input.post_unit}
        tooltipType={input.tooltip_type}
        tooltipText={input.tooltip}
        tooltipTitle={input.tooltip_title}
        formatInput={props.formatInput}
        errorManager={props.errorManager}
      />
    )
  })

  return (
    <div>
      <div className="flexrow">
        <div className="col-xs-6 flexcolumn">
          {buttonElements}
        </div>
        {props.tooltipType === "small" ?
          <Tooltip_InlineGray
            tooltipText={props.tooltipText}
          />
        : props.tooltipType === "gary_tooltip" ?
          <Tooltip_Gary
            tooltipTitle={props.tooltipTitle}
            tooltipText={props.tooltipText}
          />
        :
          null
        }
      </div>
      {showDetailedInputs && detailedInputs}
    </div>
  )
}
