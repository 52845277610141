import React from "react";

export default function CustomFiveColumnTable(props) {

  // File Description:
  //  --  TODO

  return (
    <div>
      <div className="black-underline">
        <div className="flexcolumn">
          <div className="flexrow margin-top-xsmall margin-bottom-xsmall chart-title-row">
            <div className="col-xs-3"></div>
            <div className="col-xs-2 center-xs div-bottom">Total Units</div>
            <div className="col-xs-2 center-xs div-bottom">Affordable Monthly Rent</div>
            <div className="col-xs-2 center-xs div-bottom">Market Monthly Rent</div>
            <div className="col-xs-2 center-xs div-bottom">Average Montly Rent</div>
          </div>
        </div>
        <div>
          <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
            <div className="col-xs-3">Studio</div>
            <div className="col-xs-2 center-xs">{props.formatOutputWithCommas(Math.round(props.activeProforma["calc_total_residential_units_studio"]))}</div>
            <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_studio_affordablerent"]))}</div>
            <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_studio_marketrent"]))}</div>
            <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_studio_average"]))}</div>
          </div>
        </div>
        <div>
          <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
            <div className="col-xs-3">1 Bedroom</div>
            <div className="col-xs-2 center-xs">{props.formatOutputWithCommas(Math.round(props.activeProforma["calc_total_residential_units_1br"]))}</div>
              <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_1br_affordablerent"]))}</div>
              <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_1br_marketrent"]))}</div>
              <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_1br_average"]))}</div>
          </div>
        </div>
        <div>
          <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
            <div className="col-xs-3">2 Bedroom</div>
            <div className="col-xs-2 center-xs">{props.formatOutputWithCommas(Math.round(props.activeProforma["calc_total_residential_units_2br"]))}</div>
            <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_2br_affordablerent"]))}</div>
            <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_2br_marketrent"]))}</div>
            <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_2br_average"]))}</div>
          </div>
        </div>
        <div>
          <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
            <div className="col-xs-3">3 Bedroom</div>
            <div className="col-xs-2 center-xs">{props.formatOutputWithCommas(Math.round(props.activeProforma["calc_total_residential_units_3br"]))}</div>
            <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_3br_affordablerent"]))}</div>
            <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_3br_marketrent"]))}</div>
            <div className="col-xs-2 end-xs">${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_3br_average"]))}</div>
          </div>
        </div>
      </div>
      <div className="total-box margin-top-xsmall">
        <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
          <div className="col-xs-3">Average Montly Rent</div>
          <div className="col-xs-2"></div>
          <div className="col-xs-2 flexrow between-xs"><span>Affordable</span><span>${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_averageaffordable"]))}</span></div>
          <div className="col-xs-2 flexrow between-xs"><span>Market</span><span>${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_averagemarket"]))}</span></div>
          <div className="col-xs-2 flexrow between-xs"><span>Overall</span><span>${props.formatOutputWithCommas(Math.round(props.activeProforma["calc_residential_monthlyrent_averageoverall"]))}</span></div>
        </div>
      </div>
    </div>
  )
}
