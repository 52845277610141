import React, { useState, useContext } from "react";
import { UserContext } from '../../UserContext'

// import input components
import Boolean_Horizontal from '../inputs/Boolean_Horizontal.js'
import Date_Horizontal from '../inputs/Date_Horizontal.js'
import Text_Horizontal_NoAdornment from '../inputs/Text_Horizontal_NoAdornment.js'
import TextArea_Horizontal_NoAdornment from '../inputs/TextArea_Horizontal_NoAdornment.js'
import Number_Horizontal_StartAdornment from '../inputs/Number_Horizontal_StartAdornment.js'
import Number_Horizontal_EndAdornment from '../inputs/Number_Horizontal_EndAdornment.js'
import Number_Horizontal_StartEndAdornment from '../inputs/Number_Horizontal_StartEndAdornment.js'
import SelectMultiple_Buttons_Vertical from '../inputs/SelectMultiple_Buttons_Vertical.js'
import SelectMultiple_Buttons_Vertical_Icons from '../inputs/SelectMultiple_Buttons_Vertical_Icons.js'
import SelectMultiple_Images_Horizontal from '../inputs/SelectMultiple_Images_Horizontal.js'
import SelectSingle_Buttons_Vertical_WithCustom from '../inputs/SelectSingle_Buttons_Vertical_WithCustom.js'
import InputGroup_Chart from '../inputs/InputGroup_Chart.js'
import InputGroup_Dependent_NumberFields_Unconstrained from '../inputs/InputGroup_Dependent_NumberFields_Unconstrained.js'
import InputGroup_Dependent_NumberFields_BooleanDependency from '../inputs/InputGroup_Dependent_NumberFields_BooleanDependency.js'
import InputGroup_Boolean_Dependent_Select_Multiple from '../inputs/InputGroup_Boolean_Dependent_Select_Multiple.js'
import InputGroup_Dependent_SliderFields_Constrained from '../inputs/InputGroup_Dependent_SliderFields_Constrained.js'
import InputGroup_EquityTable from '../inputs/InputGroup_EquityTable.js'
import InputGroup_Growth_Table from '../inputs/InputGroup_Growth_Table.js'
import InputGroup_Number_Multiply_Across from '../inputs/InputGroup_Number_Multiply_Across.js'
import SingleColumn_Sum_Multiply from '../outputs/SingleColumn_Sum_Multiply.js'
import Division_Table from '../outputs/Division_Table.js'
import CustomFiveColumnTable from '../outputs/Custom_Five_Column_Table.js'
import Custom_ConstructionCosts from '../outputs/Custom_ConstructionCosts.js'
import Custom_SingleColumnTable from '../outputs/Custom_SingleColumnTable.js'
import GrayBox from '../outputs/GrayBox.js'
import Address from '../inputs/Address.js'
import Tooltip_Large_Banner from "../tooltips/Tooltip_Large_Banner.js"
import Tooltip_Gary from "../tooltips/Tooltip_Gary.js"


export default function InputGroups(props) {
  const {token, setToken} = useContext(UserContext)
  const [ patchLoader, setPatchLoader ] = useState(false)

  const patchProforma = (proformaId, name, value) => {
    let url = process.env.REACT_APP_API_URL + "proformas/" + proformaId +"/";
    let headers = new Headers();
    let jsonData = JSON.stringify({[name]:value})
    console.log("Request JSON: " + jsonData)
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "PATCH",
      headers: headers,
      body: jsonData
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()  //we only get here if there is no error
    })
    .then((data) => {
      console.log("Response data: " + data[name])
      props.setActiveProforma(data);
      props.setErrorManager({
        ...props.errorManager,
        [name]: null,
      });
    })
    .catch( err => {
      if (err.status === 500){
        console.log(err)
        props.setErrorManager({
          ...props.errorManager,
          [name]: "Something went wrong. Please try again!",
        });
      } else{
        err.text().then( errorMessage => {
          let errorObject = JSON.parse(errorMessage)
          let errorText = errorObject[name][0]
          console.log(errorText)
          props.setErrorManager({
            ...props.errorManager,
            [name]: errorText,
          });
        })
      }
    })
  }

  const patchProformaBulkBody = (proformaId, requestBody, parentFieldName) => {
    let url = process.env.REACT_APP_API_URL + "proformas/" + proformaId +"/";
    let headers = new Headers();
    let jsonData = JSON.stringify(requestBody)
    console.log("Request JSON: " + jsonData)
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    setPatchLoader(true)
    fetch(url, {
      method: "PATCH",
      headers: headers,
      body: jsonData
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()  //we only get here if there is no error
    })
    .then((data) => {
      console.log("Response data: " + data[parentFieldName])
      props.setActiveProforma(data);
      props.setErrorManager({
        ...props.errorManager,
        [parentFieldName]: null,
      });
      setPatchLoader(false)
    })
    .catch( err => {
      console.log(err)
      err.text().then( errorMessage => {
        let errorObject = JSON.parse(errorMessage)
        let errorText = errorObject[parentFieldName][0]
        console.log(errorText)
        props.setErrorManager({
          ...props.errorManager,
          [parentFieldName]: errorText,
        });
      })
      setPatchLoader(false)
    })
  }


  // Function Definition:
  //  - Runs handleChange for all inputs except address and date
  //  - Receives the event, the type of input being changed, and the trailing unit on the input
  //  - If the input is a number, it deforats it
  //  - If the input is a number and the deformatted value has a trailing ".", don't patch and throw an invalid error
  //  - Otherwise, run the patch request and update state with the new value
  const handleChange = (event, inputType, inputPostUnit) => {
    // deformat the received value if it is a number
    let value;
    if (inputType === "number") {
      // if the value is not blank, deformat it
      if (event.target.value !== "") {
        value = deformatInput(event.target.value, inputType, inputPostUnit)
      // otherwise set it to null
      } else if (event.target.value === "") {
        value = null
      }
    } else {
      value = event.target.value
    }
    // if the changed input is a number and has a trailing ".", throw an invalid error
    if (inputType === "number" && value && value.toString().slice(-1) === ".") {
      props.setErrorManager({
        ...props.errorManager,
        [event.target.name]: "Please enter a valid decimal input.",
      });
    // otherwise, run the patch
    } else if (event.type === "blur" && (value == null || value == 0 || (/[1-9]/).test(value) || inputType === "text" || inputType === "text_area")) {
      patchProforma(props.proformaId, event.target.name, value)
    }
    // update state with the new value
    props.setActiveProforma({
      ...props.activeProforma,
      [event.target.name]: value,
    });
  }

  const handleChangeNoEvent = (name, value) => {
    if (value != "Invalid Date"){
      patchProforma(props.proformaId, name, value)
      props.setActiveProforma({
        ...props.activeProforma,
        [name]: value,
      });
    }
  }

  // Function Definition:
  //  - This function formats in two ways:
  //      - For percentage inputs:
  //          - It converts them by a factor of 100 to display whole-number inputs
  //          - It adjust the decimal location and formatting accordingly
  //      - For non-percentage inputs:
  //          - It comma formats them
  //          - And manages decimal placement
  function formatInput(inputType, inputValue, inputPostUnit) {
    // create the variable to return as the formatted value
    let formattedValue;

    // determine if the input is a percentage-based input
    let isPercent = inputPostUnit.includes("%") ? true : false

    // determine if the input has a trailing decimal or trailing zero
    let hasTrailingDecimal = inputValue.toString().slice(-1) === "." ? true : false
    let hasTrailingZero = inputValue.toString().slice(-1) === "0" ? true : false
    // determine if the input has a decimal
    let hasDecimal = inputValue.toString().includes(".") ? true : false

    // STEP 1: handle number inputs that have a % as the input type
    if (inputType === "number" && isPercent) {
      // allow trailing decimals to pass
      if (hasTrailingDecimal || ((/\.0{1,3}\b/)).test(inputValue)) {
        formattedValue = inputValue
      // if the number has decimal points and passes a number test
      } else if (hasDecimal && Number(inputValue)) {
        // trim trailing zeros received from backend
        let trimmedInput = parseFloat(inputValue)
        // determine how many decimals to display
        //    - if the trimmedInput has less than 2 numbers after the decimal, show no decimals
        //        - (e.g. 0.5 => 1 decimal => will be displayed cleanly as 50, with no decimal point showing)
        //    - if more than 2 numbers after the decimal, show decimal count - 2
        //        - (e.g. 0.541 => 3 decimal - 2 = show 1 decimal => will be displayed cleanly as 54.1, with one decimal point showing)
        let displayedDecimalCount;
        // if there is no decimal in the parsed number, show no decimals
        if (!trimmedInput.toString().split(".")[1]) {
          displayedDecimalCount = 0
        // otherwise set the decimal count
        } else {
          displayedDecimalCount = trimmedInput.toString().split(".")[1].length - 2 < 0 ? 0 : trimmedInput.toString().split(".")[1].length - 2
        }
        // set the formatted value to return
        //    - if the trimmedInput passes a valid number test, return trimmedInput * 100 with correct decimal count
        //    - otherwise return the trimmed input
        formattedValue = (Number(trimmedInput) * 100).toFixed(displayedDecimalCount)
      // if the input value does not have any decimals or fails the above valid number test
      } else {
        // return the number * 100 if it is a number, otherwise return the received value
        formattedValue = (!isNaN(Number(inputValue)) && inputValue !== "") ? (Number(inputValue) * 100) : inputValue
      }
    // STEP 2: handle other number inputs that have a decimal
    } else if (inputType === "number" && hasDecimal) {
      // allow trailing decimals to pass
      if (hasTrailingDecimal || hasTrailingZero || ((/\.0{1,3}\b/)).test(inputValue)) {
        formattedValue = inputValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
      // if the number has decimal points and passes a number test
      } else if (hasDecimal && Number(inputValue)) {
        // trim trailing zeros received from backend
        let trimmedInput = parseFloat(inputValue)
        // determine how many decimals to display; see logic above for an explanation
        //    - unlike percent fields, there is no "n - 2" logic here, since these values are not converted by 100
        let displayedDecimalCount;
        // if there is no decimal in the parsed number, show no decimals
        if (!trimmedInput.toString().split(".")[1]) {
          displayedDecimalCount = 0
        // otherwise set the decimal count
        } else {
          displayedDecimalCount = trimmedInput.toString().split(".")[1].length < 0 ? 0 : trimmedInput.toString().split(".")[1].length
        }
        // display the number with that many decimal points passed to the toFixed function as a variable
        formattedValue = Number(trimmedInput).toFixed(displayedDecimalCount)
        // comma-format the displayed value
        formattedValue = formattedValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
      // if the number is a whole number w/ no decimal points
      } else {
        // return blank if blank, otherwise return the number * 100
        formattedValue = !isNaN(Number(inputValue)) ? (Number(inputValue)) : inputValue
      }
    // STEP 2: handle other number inputs that have a decimal
    } else if (inputType === "number" && !isPercent && !hasDecimal) {
      // add thousand-based comma-separation
      formattedValue = inputValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    } else {
      formattedValue = inputValue
    }
    return formattedValue
  }

  const deformatInput = (value, inputType, inputPostUnit) => {

    // create the variable to return as the formatted value
    // console.log("value received in deformatter: " + value)
    let deformattedValue;

    // first, handle number inputs that are displayed as %
    if (inputType === "number" && inputPostUnit.includes("%")) {
      // if the number has decimal points
      if (value % 1 != 0 && value.includes(".")) {
        // find the number of decimal points
        let toFixedVariable = value.toString().split(".")[1].length + 2 < 0 ? 0 : value.toString().split(".")[1].length + 2
        // return the  number to its percentage format and round it to the appropriate number of fixed decimals
        deformattedValue = !isNaN(Number(value)) ? (Number(value)/100).toFixed(toFixedVariable) : value
      } else {
        // if the value has a trailing "." pass it through; the user is in the process of adding or removing a decimal point
        if (value.toString().slice(-1) === ".") {
          deformattedValue = value
        // return blank if blank, otherwise return the number / 100
        } else {
          // if the number can be cast as a number, do it, otherwise return the value and trigger and error from the API
          deformattedValue = Number(value) ? (Number(value)/100) : value
        }
      }
    // next, handle non-percentage numbers
    } else if (inputType === "number" && !inputPostUnit.includes("%")) {
      // remove everything except numbers and decimal point in the value stored in state
      deformattedValue = value.replace(/[^0-9.]/g, '')
    }
    return deformattedValue
  }

  let inputs = []
  props.subsection.input_groups.sort(function(a, b) {
    return a.order - b.order;
  }).map((inputGroup,index) => {
    inputGroup.title && inputs.push(
      <div key={"inputGroup_label"+index} className="col-xs-6">
        <h4 className="margin-top-small margin-bottom-xxsmall">{inputGroup.title}</h4>
        {inputGroup.subtitle && <p className="question-subtext">{inputGroup.subtitle}</p>}
      </div>
    )
    if (inputGroup.type === "dependent_number_fields") {
      inputs.push(
        <InputGroup_Dependent_NumberFields_Unconstrained
          key={"inputGroup_"+index}
          inputFields={inputGroup.inputs}
          proformaData={props.activeProforma}
          setProformaData={props.setActiveProforma}
          dependentFieldName={inputGroup.dependency_field_name}
          handleChange={handleChange}
          tooltipType={inputGroup.tooltip_type}
          tooltipText={inputGroup.tooltip}
          tooltipTitle={inputGroup.tooltip_title}
          formatInput={formatInput}
          errorManager={props.errorManager}
          patchProforma={patchProforma}
          proformaId={props.proformaId}
        />
      )
      if (inputGroup.tooltip_type === "large_banner") {
        inputs.push(
        <Tooltip_Large_Banner
          tooltipTitle={inputGroup.tooltip_title}
          tooltipText={inputGroup.tooltip}
        />
      )
      }
      if (inputGroup.tooltip_type === "gary_tooltip") {
        inputs.push(
        <Tooltip_Gary
          tooltipTitle={inputGroup.tooltip_title}
          tooltipText={inputGroup.tooltip}
        />
      )
      }
    } else if (inputGroup.type === "boolean_dependent_number_fields") {
      inputs.push(
        <InputGroup_Dependent_NumberFields_BooleanDependency
          key={"inputGroup_"+index}
          inputFields={inputGroup.inputs}
          proformaData={props.activeProforma}
          setProformaData={props.setActiveProforma}
          dependentFieldName={inputGroup.dependency_field_name}
          handleChange={handleChange}
          tooltipType={inputGroup.tooltip_type}
          tooltipText={inputGroup.tooltip}
          tooltipTitle={inputGroup.tooltip_title}
          formatInput={formatInput}
          errorManager={props.errorManager}
          patchProforma={patchProforma}
          proformaId={props.proformaId}
        />
      )
    } else if (inputGroup.type === "dependent_sliders_constrained") {
      inputs.push(
        <InputGroup_Dependent_SliderFields_Constrained
          key={"inputGroup_"+index}
          inputFields={inputGroup.inputs}
          proformaData={props.activeProforma}
          setProformaData={props.setActiveProforma}
          dependentFieldName={inputGroup.dependency_field_name}
          handleChange={handleChange}
          formatInput={formatInput}
          deformatInput={deformatInput}
          patchProforma={patchProforma}
          proformaId={props.proformaId}
          errorManager={props.errorManager}
        />
      )
    } else if (inputGroup.type === "number_multiply_across") {
      inputs.push(
        <InputGroup_Number_Multiply_Across
          key={"inputGroup_"+index}
          inputFields={inputGroup.inputs}
          multiplicationSettings={inputGroup.multiplication_settings}
          proformaData={props.activeProforma}
          setProformaData={props.setActiveProforma}
          dependentFieldName={inputGroup.dependency_field_name}
          handleChange={handleChange}
          tooltipType={inputGroup.tooltip_type}
          tooltipText={inputGroup.tooltip}
          tooltipTitle={inputGroup.tooltip_title}
          formatOutputWithCommas={props.formatOutputWithCommas}
          formatInput={formatInput}
          errorManager={props.errorManager}
        />
      )
    } else if (inputGroup.type === "boolean_dependent_select_multiple") {
      inputs.push(
        <InputGroup_Boolean_Dependent_Select_Multiple
          key={"inputGroup_"+index}
          inputFields={inputGroup.inputs}
          multiplicationSettings={inputGroup.multiplication_settings}
          proformaData={props.activeProforma}
          setProformaData={props.setActiveProforma}
          dependentFieldName={inputGroup.dependency_field_name}
          proformaId={props.proformaId}
          patchProformaBulkBody={patchProformaBulkBody}
          handleChange={handleChange}
          errorManager={props.errorManager}
        />
      )
    } else if (inputGroup.type === "growth_table") {
      inputs.push(
        <InputGroup_Growth_Table
          key={"inputGroup_"+index}
          inputFields={inputGroup.inputs}
          multiplicationSettings={inputGroup.multiplication_settings}
          proformaData={props.activeProforma}
          setProformaData={props.setActiveProforma}
          growthTableSettings={inputGroup.growth_table_settings}
          handleChange={handleChange}
          tooltipType={inputGroup.tooltip_type}
          tooltipText={inputGroup.tooltip}
          tooltipTitle={inputGroup.tooltip_title}
          formatInput={formatInput}
          proformaId={props.proformaId}
          errorManager={props.errorManager}
        />
      )
    } else if (inputGroup.type === "chart") {
      inputs.push(
        <InputGroup_Chart
          key={"inputGroup_"+index}
          inputFields={inputGroup.inputs}
          chartSettings={inputGroup.chart_settings}
          proformaData={props.activeProforma}
          setProformaData={props.setActiveProforma}
          handleChange={handleChange}
          tooltipType={inputGroup.tooltip_type}
          tooltipText={inputGroup.tooltip}
          tooltipTitle={inputGroup.tooltip_title}
          formatOutputWithCommas={props.formatOutputWithCommas}
          formatInput={formatInput}
          errorManager={props.errorManager}
        />
      )
    } else if (inputGroup.type === "custom_equity_table") {
      inputs.push(
        <InputGroup_EquityTable
          key={"inputGroup_"+index}
          inputFields={inputGroup.inputs}
          chartSettings={inputGroup.chart_settings}
          proformaData={props.activeProforma}
          setProformaData={props.setActiveProforma}
          handleChange={handleChange}
          tooltipType={inputGroup.tooltip_type}
          tooltipText={inputGroup.tooltip}
          tooltipTitle={inputGroup.tooltip_title}
          formatOutputWithCommas={props.formatOutputWithCommas}
          patchProforma={patchProforma}
          patchProformaBulkBody={patchProformaBulkBody}
          proformaId={props.proformaId}
        />
      )
    } else {
      if (inputGroup.tooltip_type === "large_banner") {
        inputs.push(
        <Tooltip_Large_Banner
          tooltipTitle={inputGroup.tooltip_title}
          tooltipText={inputGroup.tooltip}
        />
        )
      }
      inputGroup.inputs.sort(function(a, b) {
        return a.id - b.id;
      }).map((input,index) => {
        if (input.input_type === "text") {
          inputs.push(
            <Text_Horizontal_NoAdornment
              key={"input_"+index+input.name}
              type={input.input_type}
              questionText={input.question_copy}
              placeholderText={input.placeholder_text}
              name={input.name}
              value={props.activeProforma[input.name]}
              handleChange={handleChange}
              errorManager={props.errorManager}
            />
          )
        } else if (input.input_type === "date") {
          inputs.push(
            <Date_Horizontal
              key={"input_"+index+input.name}
              type={input.input_type}
              questionText={input.question_copy}
              placeholderText={input.placeholder_text}
              name={input.name}
              value={props.activeProforma[input.name]}
              handleChange={handleChangeNoEvent}
              tooltipType={input.tooltip_type}
              tooltipText={input.tooltip}
            />
          )
        } else if (input.input_type === "text_area") {
          inputs.push(
            <TextArea_Horizontal_NoAdornment
              key={"input_"+index+input.name}
              type={input.input_type}
              questionText={input.question_copy}
              placeholderText={input.placeholder_text}
              name={input.name}
              value={props.activeProforma[input.name]}
              handleChange={handleChange}
            />
          )
        } else if (input.input_type === "address") {
          inputs.push(
            <Address
              key={"input_"+index+input.name}
              questionText={input.question_copy}
              placeholderText={input.placeholder_text}
              name={input.name}
              value={props.activeProforma[input.name]}
              handleAddressChange={handleChangeNoEvent}
            />
          )
        } else if (input.input_type === "number") {
          if (input.pre_unit && input.post_unit) {
            inputs.push(
              <Number_Horizontal_StartEndAdornment
                key={"input_"+index+input.name}
                type={input.input_type}
                questionText={input.question_copy}
                placeholderText={input.placeholder_text}
                name={input.name}
                value={props.activeProforma[input.name] !== null ? props.activeProforma[input.name] : ""}
                handleChange={handleChange}
                startAdornment={input.pre_unit}
                endAdornment={input.post_unit}
                tooltipType={input.tooltip_type}
                tooltipText={input.tooltip}
                tooltipTitle={input.tooltip_title}
                formatInput={formatInput}
                errorManager={props.errorManager}
              />
            )
          } else if (input.pre_unit && !input.post_unit) {
            inputs.push(
              <Number_Horizontal_StartAdornment
                key={"input_"+index+input.name}
                type={input.input_type}
                questionText={input.question_copy}
                placeholderText={input.placeholder_text}
                name={input.name}
                value={props.activeProforma[input.name] !== null ? props.activeProforma[input.name] : ""}
                handleChange={handleChange}
                startAdornment={input.pre_unit}
                tooltipType={input.tooltip_type}
                tooltipText={input.tooltip}
                tooltipTitle={input.tooltip_title}
                formatInput={formatInput}
                errorManager={props.errorManager}
              />
            )
          } else if (!input.pre_unit && input.post_unit) {
            inputs.push(
              <Number_Horizontal_EndAdornment
                key={"input_"+index+input.name}
                type={input.input_type}
                questionText={input.question_copy}
                placeholderText={input.placeholder_text}
                name={input.name}
                value={props.activeProforma[input.name] === null ? "" : props.activeProforma[input.name]}
                handleChange={handleChange}
                endAdornment={input.post_unit}
                tooltipType={input.tooltip_type}
                tooltipText={input.tooltip}
                tooltipTitle={input.tooltip_title}
                formatInput={formatInput}
                errorManager={props.errorManager}
                disabled={(input.dependency_field_name && (!props.activeProforma[input.dependency_field_name] || props.activeProforma[input.dependency_field_name] == 0)) ? true : false}
              />
            )
          }
        } else if (input.input_type === "checkbox") {
          inputs.push(
            <SelectMultiple_Buttons_Vertical
              key={"input_"+index+input.name}
              type={input.input_type}
              questionText={input.question_copy}
              placeholderText={input.placeholder_text}
              name={input.name}
              value={props.activeProforma[input.name]}
              choices={input.choices}
              proformaData={props.activeProforma}
              setProformaData={props.setActiveProforma}
              tooltipType={input.tooltip_type}
              tooltipText={input.tooltip}
              tooltipTitle={input.tooltip_title}
              isDisabled={false}
              errorManager={props.errorManager}
              patchProformaBulkBody={patchProformaBulkBody}
              proformaId={props.proformaId}
            />
          )
        } else if (input.input_type === "boolean") {
          inputs.push(
            <Boolean_Horizontal
              key={"input_"+index+input.name}
              type={input.input_type}
              questionText={input.question_copy}
              placeholderText={input.placeholder_text}
              name={input.name}
              activeProforma={props.activeProforma}
              setActiveProforma={props.setActiveProforma}
              value={props.activeProforma[input.name]}
              tooltipType={input.tooltip_type}
              tooltipText={input.tooltip}
              tooltipTitle={input.tooltip_title}
              dependency_field_name={input.dependency_field_name}
              patchProformaBulkBody={patchProformaBulkBody}
              proformaId={props.proformaId}
            />
          )
        } else if (input.input_type === "checkbox_icon") {
          inputs.push(
            <SelectMultiple_Buttons_Vertical_Icons
              key={"input_"+index+input.name}
              type={input.input_type}
              questionText={input.question_copy}
              placeholderText={input.placeholder_text}
              name={input.name}
              value={props.activeProforma[input.name]}
              choices={input.choices}
              proformaData={props.activeProforma}
              setProformaData={props.setActiveProforma}
              tooltipType={input.tooltip_type}
              tooltipText={input.tooltip}
              tooltipTitle={input.tooltip_title}
              errorManager={props.errorManager}
              patchProformaBulkBody={patchProformaBulkBody}
              proformaId={props.proformaId}
            />
          )
        } else if (input.input_type === "select_multiple_images_horizontal") {
          inputs.push(
            <SelectMultiple_Images_Horizontal
              key={"input_"+index+input.name}
              type={"checkbox"}
              questionText={input.question_copy}
              placeholderText={input.placeholder_text}
              name={input.name}
              value={props.activeProforma[input.name]}
              choices={input.choices}
              proformaData={props.activeProforma}
              setProformaData={props.setActiveProforma}
              tooltipText={input.tooltip}
              tooltipType={input.tooltip_type}
              tooltipTitle={input.tooltip_title}
              patchProformaBulkBody={patchProformaBulkBody}
              patchLoader={patchLoader}
              proformaId={props.proformaId}
            />
          )
        } else if (input.input_type === "radio") {
          inputs.push(
            <SelectSingle_Buttons_Vertical_WithCustom
              key={"input_"+index+input.name}
              type={"radio"}
              questionText={input.question_copy}
              placeholderText={input.placeholder_text}
              name={input.name}
              value={props.activeProforma[input.name] ? props.activeProforma[input.name] : ""}
              choices={input.choices}
              proformaData={props.activeProforma}
              setProformaData={props.setActiveProforma}
              isDisabled={false}
              handleChange={handleChange}
              startAdornment={input.pre_unit}
              endAdornment={input.post_unit}
              tooltipText={inputGroup.tooltip}
              tooltipTitle={inputGroup.tooltip_title}
              tooltipType={inputGroup.tooltip_type}
              formatInput={formatInput}
              errorManager={props.errorManager}
              patchProforma={patchProforma}
              proformaId={props.proformaId}
            />
          )
        }
        return input
      })
    }
    if (inputGroup.tooltip_type === "large_banner_end") {
      inputs.push(
        <Tooltip_Large_Banner
          tooltipTitle={inputGroup.tooltip_title}
          tooltipText={inputGroup.tooltip}
        />
      )
    }
    return inputGroup
  })

  let outputs = []
  props.subsection.outputs.map((output,index) => {
    outputs.push(
      <div className="col-xs-6">
        <h2 className="margin-top-small margin-bottom-xxsmall">{output.title}</h2>
      </div>
    )
    if (output.type === "single_column_sum_multiply") {
      outputs.push(
        <SingleColumn_Sum_Multiply
          activeProforma={props.activeProforma}
          output={output}
          formatOutputWithCommas={props.formatOutputWithCommas}
          formatOutputPercent={props.formatOutputPercent}
        />
      )
    } else if (output.type === "custom_divisiontable") {
      outputs.push(
        <Division_Table
          activeProforma={props.activeProforma}
          output={output}
          formatOutputWithCommas={props.formatOutputWithCommas}
        />
      )
    } else if (output.type === "custom_fivecolumntable") {
      outputs.push(
        <CustomFiveColumnTable
          activeProforma={props.activeProforma}
          output={output}
          formatOutputWithCommas={props.formatOutputWithCommas}
        />
      )
    } else if (output.type === "custom_multiplicationtable") {
      outputs.push(
        <Custom_ConstructionCosts
          activeProforma={props.activeProforma}
          output={output}
          formatOutputWithCommas={props.formatOutputWithCommas}
        />
      )
    } else if (output.type === "gray_box") {
      outputs.push(
        <GrayBox
          activeProforma={props.activeProforma}
          output={output}
          formatOutputWithCommas={props.formatOutputWithCommas}
          formatOutputPercent={props.formatOutputPercent}
        />
      )
    } else if (output.type === "custom_singlecolumn") {
      outputs.push(
        <Custom_SingleColumnTable
          activeProforma={props.activeProforma}
          output={output}
          formatOutputWithCommas={props.formatOutputWithCommas}
          formatOutputPercent={props.formatOutputPercent}
        />
      )
    }
  })

  return (
    <div className="margin-top-small">
      <div className="col-xs-12">
        <h3>{props.subsection.title}</h3>
        <p className="question-subtext">{props.subsection.subtitle}</p>
      </div>
      {inputs}
      {outputs}
    </div>
  )
}
