import React, {useState} from "react";

export default function ChartElement(props) {

  // File Description:
  //  TODO

  let colorArray = ['#12AAEB',"#475C85","#F2994A","#4FBF7E","#FED766","#4F4F4F","#9B51E0"]
  let denominatorLookupField = props.chartSettings[0].total_lookup_name
  let denominator = props.proformaData[denominatorLookupField] ? props.proformaData[denominatorLookupField] : ""
  let totalNumerator = 0
  let totalPercent = 0

  let chartBars = []
  let chartLegends = []
  props.chartSettings[0].data.sort(function(a, b) {
    return a.order - b.order;
  }).map((segment,index) => {
    let numeratorLookupField = segment.lookup_name
    let numerator = props.proformaData[numeratorLookupField] ? Number(props.proformaData[numeratorLookupField]) : ""
    totalNumerator = Number(totalNumerator) + Number(numerator)
    let percentage = (numerator / denominator)
    totalPercent = totalPercent + Number(percentage)
    let textPercentage = parseInt(percentage*100).toFixed(0).toString() + "%"
    if (Number(numerator) !== 0) {
      chartBars.push(<div className="progress-bar-element" style={{width: textPercentage, backgroundColor: colorArray[index]}}></div>)
      chartLegends.push(
        <div className="col-xs-1 flexcolumn chart-label" style={{borderTop: '2px solid ' + colorArray[index]}}>
          <span>{segment.text_name}</span>
          <span>{props.formatOutputWithCommas(numerator)}</span>
        </div>
      )
    }
  })
  let totalPercentText = !isNaN(totalPercent) ? Math.round(totalPercent*100).toFixed(0).toString() + "%" : "0%"

  return (
    <div>
      <div className="flexrow between-xs chart-title-row">
        <span>{props.chartSettings[0].chart_title}</span>
        <div>
          <span>{props.formatOutputWithCommas(Number(totalNumerator).toFixed(0))}</span>
          <span> / </span>
          <span>{props.formatOutputWithCommas(Number(denominator).toFixed(0))}</span>
          <span> ({totalPercentText})</span>
        </div>
      </div>
      <div className="progress-bar-container flexrow">
        {chartBars}
      </div>
      <div className="margin-top-xxsmall flexrow">
        {chartLegends}
      </div>
    </div>

  )
}
