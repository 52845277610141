import React, {useState, useEffect, useContext} from "react";
import { UserContext } from '../../UserContext'
import { useHistory } from "react-router-dom";
import Button from '../buttons/Button.js'
import BarLoader from "react-spinners/BarLoader";
// import project type and status SVG
import {ReactComponent as StatusComplete} from '../../static_assets/svg/sectionCircleComplete.svg';
import {ReactComponent as StatusInProgress} from '../../static_assets/svg/sectionCircleInProgress.svg';
import {ReactComponent as StatusNotStarted} from '../../static_assets/svg/sectionCircleEmpty.svg';
import {ReactComponent as ProjectCommercialOfficeResidential} from '../../static_assets/svg/ProjectCommercialOfficeResidential.svg';
import {ReactComponent as ProjectCommercialResidential} from '../../static_assets/svg/ProjectCommercialResidential.svg';
import {ReactComponent as ProjectCommercialOffice} from '../../static_assets/svg/ProjectCommercialOffice.svg';
import {ReactComponent as ProjectCommercial} from '../../static_assets/svg/ProjectCommercial.svg';
import {ReactComponent as ProjectOfficeResidential} from '../../static_assets/svg/ProjectOfficeResidential.svg';
import {ReactComponent as ProjectOffice} from '../../static_assets/svg/ProjectOffice.svg';
import {ReactComponent as ProjectResidential} from '../../static_assets/svg/ProjectResidential.svg';

export default function ProjectReview(props) {

  let history = useHistory();
  const {token, setToken} = useContext(UserContext)
  const [ proformaOutputs, setProformaOutputs ] = useState()

  // fetch summary data on page load
  useEffect(() => {
    // fetch and set state in response
    let url = process.env.REACT_APP_API_URL + "proforma/summary/" + props.activeProforma.id
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "GET",
      headers: headers,
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()  //we only get here if there is no error
    })
    .then(data => setProformaOutputs(data))
    .catch( err => {
      if (err.status === 401) {
        history.push("/login")
      } else if (err.status === 404 || err.status === 500 || err.status === 403) {
        history.push("/error404")
      }
    })
  },[])

  // determine included uses
  let includesResidential = props.activeProforma.project_type.includes("project_type_residential")
  let includesCommercial = props.activeProforma.project_type.includes("project_type_commercial")
  let includesOffice =props.activeProforma.project_type.includes("project_type_office")

  // retrieve the eligible section IDs based on included uses
  var decoupledStructure = JSON.parse(JSON.stringify(props.activeProformaStructure));
  let eligibleSections = decoupledStructure
  if (!includesResidential) { eligibleSections = eligibleSections.filter((section) => {return section.id !== 2})}
  if (!includesCommercial) { eligibleSections = eligibleSections.filter((section) => {return section.id !== 3})}
  if (!includesOffice) { eligibleSections = eligibleSections.filter((section) => {return section.id !== 4})}

  // determine project image and shorthand
  let projectImage;
  let projectTypeShorthand;
  if (includesCommercial && includesOffice && includesResidential) { // all three
    projectImage = <ProjectCommercialOfficeResidential className="dashboard-project-svg"/>
    projectTypeShorthand = "Commercial, Office & Residential"
  } else if (includesCommercial && includesOffice && !includesResidential) { // commercial office
    projectImage = <ProjectCommercialOffice className="dashboard-project-svg"/>
    projectTypeShorthand = "Commercial, Office"
  } else if (includesCommercial && !includesOffice && includesResidential) { // commercial residential
    projectImage = <ProjectCommercialResidential className="dashboard-project-svg"/>
    projectTypeShorthand = "Commercial, Residential"
  } else if (includesCommercial && !includesOffice && !includesResidential) { // commercial only
    projectImage = <ProjectCommercial className="dashboard-project-svg"/>
    projectTypeShorthand = "Commercial"
  } else if (!includesCommercial && includesOffice && includesResidential) { // office residential
    projectImage = <ProjectOfficeResidential className="dashboard-project-svg"/>
    projectTypeShorthand = "Office, Residential"
  } else if (!includesCommercial && includesOffice && !includesResidential) { // office only
    projectImage = <ProjectOffice className="dashboard-project-svg"/>
    projectTypeShorthand = "Office"
  } else if (!includesCommercial && !includesOffice && includesResidential) { // residential only
    projectImage = <ProjectResidential className="dashboard-project-svg"/>
    projectTypeShorthand = "Residential"
  } else {
    projectImage = <ProjectCommercialOfficeResidential className="dashboard-project-svg"/>
    projectTypeShorthand = "None Selected"
  }

  const handleButtonClick = (sectionID) => {
    props.setActiveSection(sectionID)
    props.setActiveSubsection(0)
    history.push("/proforma/" + props.activeProforma.id)
  }

  return (
    <div className="col-xs-12 padding-left-small margin-top-small margin-bottom-large">
      <h1 className="margin-bottom-xsmall">
        {props.activeProforma.project_name}
      </h1>
      <div className="flexrow middle-xs">
        <div className="col-xs-6 padding-left-none">
          {projectTypeShorthand && <div className="flexrow margin-top-xsmall">
            <div className="col-xs-4 padding-left-none"><strong>Project Type:</strong></div>
            <div className="col-xs-8">{projectTypeShorthand}</div>
          </div>}
          {props.activeProforma.project_overview && <div className="flexrow margin-top-xsmall">
            <div className="col-xs-4 padding-left-none"><strong>Description:</strong></div>
            <div className="col-xs-8">{props.activeProforma.project_overview}</div>
          </div>}
          {props.activeProforma.project_address && <div className="flexrow margin-top-xsmall">
            <div className="col-xs-4 padding-left-none"><strong>Address:</strong></div>
            <div className="col-xs-8">{props.activeProforma.project_address}</div>
          </div>}
          {props.activeProforma.project_landsf && <div className="flexrow margin-top-xsmall">
            <div className="col-xs-4 padding-left-none"><strong>Property Size:</strong></div>
            <div className="col-xs-8">{props.formatOutputWithCommas(props.activeProforma.project_landsf)} sqft</div>
          </div>}
          {props.activeProforma.project_acquisition_price && <div className="flexrow margin-top-xsmall">
            <div className="col-xs-4 padding-left-none"><strong>Acquisition Price:</strong></div>
            <div className="col-xs-8">${props.formatOutputWithCommas(props.activeProforma.project_acquisition_price)}</div>
          </div>}
        </div>
        <div className="col-xs-6">
          {projectImage}
        </div>
      </div>
      {Number(props.overallStatus.percentComplete) === 1 && <div className="margin-top-small middle-xs">
        <div className="flexrow between-xs middle-xs">
          <h2>Results:</h2>
          <div className="col-xs-2">
            <Button
              buttonText={"View Summary"}
              buttonClassName={"button-fill green-no-border padding-xsmall margin-right-xsmall"}
              onClick={() => {history.push("/proforma/" + props.activeProforma.id + "/summary")}}
              disabled={false}
            />
          </div>
        </div>
        <div className="flexrow">
          <div className="col-xs-3 center-xs">
            <div className="summary-stat-container column">
              <span className="table-header-bottom">Project IRR</span>
              <span>{proformaOutputs && proformaOutputs.length !== 0 ? proformaOutputs.irr_summary.calc_irr_projectlevel : <BarLoader size={12} color={"#C4C4C4"} loading={true}/>}</span>
            </div>
            {proformaOutputs && proformaOutputs.length !== 0 && proformaOutputs.irr_summary.calc_irr_projectlevel_error_overview &&
              <div>
                <p className="error-text start-xs">{proformaOutputs.irr_summary.calc_irr_projectlevel_error_overview}</p>
              </div>
            }
          </div>
          <div className="col-xs-3 center-xs">
            <div className="summary-stat-container column">
              <span className="table-header-bottom">Total Project Cost</span>
              <span>${props.formatOutputWithCommas(props.activeProforma.calc_uses_total)}</span>
            </div>
          </div>
          <div className="col-xs-6 center-xs">
            <div className="summary-stat-container">
              <div className="col-xs-12">
                <span className="table-header-bottom">Total Financing</span>
              </div>
              <div className="flexrow">
                <div className="col-xs-4 flexcolumn div-bottom">
                  <span className="table-center"><strong>Debt</strong></span>
                  <span className="table-center">${props.formatOutputWithCommas(props.activeProforma.calc_sources_permanentfinancingamount)}</span>
                </div>
                <div className="col-xs-4 flexcolumn div-bottom">
                  <span className="table-center"><strong>Equity</strong></span>
                  <span className="table-center">${props.formatOutputWithCommas(props.activeProforma.calc_sources_equity_amount)}</span>
                </div>
                <div className="col-xs-4 flexcolumn div-bottom">
                  <span className="table-center"><strong>Philanthropy & Grants</strong></span>
                  <span className="table-center">${props.formatOutputWithCommas(props.activeProforma.sources_equity_philanthropyamount)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      <div className="middle-xs margin-top-small">
        <h2>Progress:</h2>
        <div className="col-xs-8 padding-left-none">
          {eligibleSections.map((section,index) => {
            return (<div className="flexrow padding-top-xxsmall padding-bottom-xxsmall middle-xs black-underline-light">
              <div className="col-xs-4 padding-left-none"><strong>{index + 1}: {section.title}</strong></div>
              <div className="col-xs-4">
                {
                  section.status === "complete" ? <span className="flexrow middle-xs"><StatusComplete className="margin-right-xxsmall" /> Complete</span> :
                  section.status === "inProgress" ? <span className="flexrow middle-xs"><StatusInProgress className="margin-right-xxsmall" /> In progress</span> :
                  <span className="flexrow middle-xs"><StatusNotStarted className="margin-right-xxsmall" /> Not Started</span>
                }
              </div>
              <div className="col-xs-4 center-xs">
                {
                  section.status === "complete" ?
                    <span>
                      <Button
                        buttonText={"Edit"}
                        buttonClassName={"button-small white-gray-border padding-xxsmall margin-right-xxsmall"}
                        onClick={() => handleButtonClick(section.id)}
                        disabled={false}
                      />
                    </span>
                  : section.status === "inProgress" ?
                    <span>
                      <Button
                        buttonText={"Continue"}
                        buttonClassName={"button-small light-blue padding-xxsmall margin-right-xxsmall"}
                        onClick={() => handleButtonClick(section.id)}
                        disabled={false}
                      />
                    </span>
                  : <span>
                      <Button
                        buttonText={"Start"}
                        buttonClassName={"button-small green-no-border padding-xxsmall margin-right-xxsmall"}
                        onClick={() => handleButtonClick(section.id)}
                        disabled={false}
                      />
                    </span>
                }
              </div>
            </div>)
          })}
        </div>

      </div>
    </div>
  );
}
