import React from "react";
import resourceLinks from '../../data/understandingYourResults.json'
import LaunchIcon from '@material-ui/icons/Launch';

export default function ProjectFinances(props) {

  return (
    <div className="padding-left-none col-xs-10">
      <div className="flexrow col-xs-12 padding-left-none wrap resource-wrapper">
        {resourceLinks.map((link,index) => {

          return (
            <div className="col-xs-3 flex-stretch margin-top-xsmall">
              <div className="resource-container">
                <div>
                  <p className="resource-title">{link.title}</p>
                  <p className="resource-description">{link.description}</p>
                </div>
                <a target="_blank" href={link.href}>
                  <div className="flexrow middle-xs between-xs">
                    <span>{link.link_type}</span>
                    <LaunchIcon className="svg-light-gray svg-small"/>
                  </div>
                </a>
              </div>
            </div>
          )
        })}
      </div>
    </div>

  )
}
