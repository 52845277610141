import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function SubsectionList(props) {

  // receives list of subsections in active section
  // receives active subsection number
  // maps sections
  //    -- if section order <= activeSubsection then highlight it yellow

  return (
    <div className="padding-left-xsmall flexrow">
      {props.subsections.map((subsection,index) => {
        let subsectionProgress = subsection.status === "complete" ? "green-fill" :
          subsection.status === "inProgress" ? "yellow-fill" :
          "gray-fill"

        let activeSubsection = subsection.order === props.activeSubsection ? " active" : ""

        return (
          <div
            key={index}
            className="flexrow col-xs-2 padding-left-xxsmall padding-right-xxsmall subsection-list-element"
            onClick={() => props.setActiveSubsection(subsection.order)}
          >
            <div className={"col-xs-12 subsection-container " + subsectionProgress}>
              <div className={"subsection-text-container flexrow middle-xs " + activeSubsection}>
                <span>{subsection.order + ". "}</span>
                <span>{subsection.shorthand}</span>
                {subsection.status === "complete" && <CheckCircleIcon fontSize="small" className="svg-complete margin-left-xxsmall"/>}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
