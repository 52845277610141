import React, {useState, useEffect, useContext} from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from '../UserContext'
import AppFooter from './navbar/AppFooter.js'
import Button from './buttons/Button.js';
import ClipLoader from "react-spinners/ClipLoader";

import feedbackQuestionJSON from '../data/FeedbackQuestions.json';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
}));

const BlueRadio = withStyles({
  root: {
    "&$checked": {
      color: "#12AAEB",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const BlueCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#12AAEB",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


export default function Feedback(props) {

  const classes = useStyles();

  const feedbackQuestionList = feedbackQuestionJSON
  const [ feedbackText, setFeedbackText ] = useState("")
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ showThankYouPage, setShowThankYouPage ] = useState(false)
  const {token, setToken} = useContext(UserContext)
  const [ feedbackResponses, setFeedbackResponses ] = useState({
    "question_1":"",
    "question_2":"",
    "question_3":"",
    "question_4":{},
    "question_5":"",
    "question_6":{},
    "question_7":{},
    "question_8":""
  })
  let history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    let url = process.env.REACT_APP_API_URL + "feedback/";
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    let data = JSON.stringify(feedbackResponses)
    fetch(url, {
      method: "POST",
      headers: headers,
      body: data
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()  //we only get here if there is no error
    })
    .then(
      (data) => {
        if (data.error) {
          console.log(data.error)
        } else {
          setIsSubmitting(false)
          setShowThankYouPage(true)
        }
      }
    )
    .catch( err => {
      if (err.status === 401) {
        history.push("/login")
      } else if (err.status === 404 || err.status === 500 || err.status === 403) {
        history.push("/error404")
      }
    })
  }


  const handleChange = (event, id) => {
    if (event.target.type !== "checkbox") {
      setFeedbackResponses({
        ...feedbackResponses,
        [id]: event.target.value,
      });
    } else {
      let checkedResponsesObject = feedbackResponses[id]
      // update the key of that object that equals the name of the event passed in; set it opposite of what it currently is
      checkedResponsesObject[event.target.name] = !checkedResponsesObject[event.target.name]
      // set this updated object to the value for the current question ID held in state
      setFeedbackResponses({
        ...feedbackResponses,
        [id]: checkedResponsesObject,
      });
    }

  };

  if (!showThankYouPage) {
    return (
      <div className="flexcolumn between-xs white-vertical-container">
        <div className="flexrow wrap">
          <div className="col-xs-12 margin-top-small">
            <h1>Feedback</h1>
            <h3 className="gray">Please take a moment to share feedback with The Governance Project and The Opportunity Exchange teams regarding your use of GroundUp.</h3>
          </div>
          <div className="col-xs-8 padding-left-small flexcolumn">
            {feedbackQuestionJSON.map((question,index) => {
              if (question.question_type === "multipleChoice") {
                return (
                  <div>
                    <h3>{question.question_text}</h3>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label={question.question_text}
                        name={question.name}
                        value={feedbackResponses[question.name]}
                        onChange={(event) => handleChange(event,question.name)}
                      >
                      {question.choices.map((choice,index) => {
                        return (
                          <FormControlLabel value={choice.choice_text} control={<BlueRadio />} disabled={isSubmitting} label={choice.choice_text} />
                        )
                      })}
                      </RadioGroup>
                    </FormControl>
                  </div>
                )
              } else if (question.question_type === "text") {
                return (
                  <div>
                    <h3>{question.question_text}</h3>
                    <form className={classes.root} noValidate autoComplete="off">
                      <TextField
                          label="Please type your response"
                          multiline
                          rows={4}
                          name={question.name}
                          value={feedbackResponses[question.name]}
                          onChange={(event) => handleChange(event,question.name)}
                          variant="outlined"
                          disabled={isSubmitting}
                        />
                    </form>
                  </div>
                )
              } else if (question.question_type === "checkbox") {
                return (
                  <div>
                    <h3>{question.question_text}</h3>
                    <FormControl component="fieldset">
                      <FormGroup>
                        {question.choices.map((choice,index) => {
                          return (
                            <FormControlLabel
                              control={<BlueCheckbox type="checkbox" checked={feedbackResponses[question.name][choice.choice_text]} onChange={(event) => handleChange(event,question.name)} disabled={isSubmitting} name={choice.choice_text} />}
                              label={choice.choice_text}
                            />
                          )
                        })}
                      </FormGroup>
                    </FormControl>
                  </div>
                )
              } else {
                return null
              }
            })}
            <Button
              buttonText={isSubmitting ? <ClipLoader size={15} color={"#ffffff"} loading={isSubmitting}/> : "Submit"}
              buttonClassName={"button-normal light-blue padding-xsmall margin-top-xsmall margin-bottom-large"}
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <AppFooter />
      </div>
    )
  } else {
    return (
      <div className="flexcolumn between-xs white-vertical-container">
        <div className="flexrow wrap">
          <div className="col-xs-12 margin-top-small">
            <h1>Thank you!</h1>
            <h3>Your feedback will help us evaulate how best to improve the app in the future.</h3>
            <Button
              buttonText={"Return to Dashboard"}
              buttonClassName={"button-normal light-blue padding-xsmall margin-top-xsmall margin-bottom-large"}
              onClick={() => history.push("/dashboard")}
            />
          </div>
        </div>
        <AppFooter />
      </div>
    )
  }
}
