import React from "react";

// Button Components
import Button from './buttons/Button.js'

export default function ComponentBankButtons(props) {

  const handleClick = () => console.log("clicked")

  return (
    <div className="flexcolumn">
      <div className="flexrow">
        <div className="col-xs-12 flexcolumn">
          <h1>Buttons</h1>
          <h4>Buttons: Small Width, Normal Height</h4>
          <div className="flexrow">
            <Button
              buttonText={"Next"}
              buttonClassName={"button-small light-blue padding-xsmall margin-right-xsmall"}
              onClick={handleClick}
              disabled={false}
            />
            <Button
              buttonText={"Next"}
              buttonClassName={"button-small royal-blue padding-xsmall margin-right-xsmall"}
              onClick={handleClick}
              disabled={false}
            />
            <Button
              buttonText={"Next"}
              buttonClassName={"button-small dark-blue padding-xsmall margin-right-xsmall"}
              onClick={handleClick}
              disabled={false}
            />
            <Button
              buttonText={"< Back"}
              buttonClassName={"button-small white-no-border padding-xsmall margin-right-xsmall"}
              onClick={handleClick}
              disabled={false}
            />
            <Button
              buttonText={"< Back"}
              buttonClassName={"button-small gray-no-border padding-xsmall margin-right-xsmall"}
              onClick={handleClick}
              disabled={false}
            />
          </div>
          <h4>Buttons: Normal Width, Normal Height</h4>
          <div className="flexrow">
            <Button
              buttonText={"Get Started"}
              buttonClassName={"button-normal light-blue padding-xsmall margin-right-xsmall"}
              onClick={handleClick}
              disabled={false}
            />
            <Button
              buttonText={"Get Started"}
              buttonClassName={"button-normal dark-blue padding-xsmall margin-right-xsmall"}
              onClick={handleClick}
              disabled={false}
            />
            <Button
              buttonText={"+ Add Source"}
              buttonClassName={"button-normal white-gray-border padding-xsmall margin-right-xsmall"}
              onClick={handleClick}
              disabled={false}
            />
            <Button
              buttonText={"+ Add Source"}
              buttonClassName={"button-normal gray-gray-border padding-xsmall margin-right-xsmall"}
              onClick={handleClick}
              disabled={false}
            />
          </div>
          <h4>Buttons: Small Width, Small Height</h4>
          <div className="flexrow">
            <Button
              buttonText={"Edit"}
              buttonClassName={"button-small white-gray-border padding-xxsmall margin-right-xxsmall"}
              onClick={handleClick}
              disabled={false}
            />
            <Button
              buttonText={"Edit"}
              buttonClassName={"button-small gray-gray-border padding-xxsmall margin-right-xxsmall"}
              onClick={handleClick}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
