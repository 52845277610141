import React from "react";
import { useHistory } from "react-router-dom";
import BackArrow from '@material-ui/icons/ArrowBackIos';

export default function BackArrowWithText(props) {

  let history = useHistory();

  const onClick = (url) => {
    history.push(url)
  }

  return (
    <div
      className="flexrow middle-xs underline-on-hover padding-left-xsmall padding-right-xsmall"
      onClick={() => onClick(history.push(props.onClickURL))}
    >
      <BackArrow
        className="svg-white"
        fontSize="inherit"
      />
      <div className="white light">{props.text}</div>
    </div>
  );
}
