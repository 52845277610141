import React from "react";
import { withStyles } from '@material-ui/core/styles';
import GaryTheGroundhog from '../../static_assets/svg/GaryTheGroundhog.png';

export default function Tooltip_Large_Banner(props) {

  var ReactDOMServer = require('react-dom/server');
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  var reactElement = htmlToReactParser.parse(props.tooltipText);

  // array to govern the render size of large banners
  // if the tooltip title is in the array, the banner renders with col-xs-6 as the width
  // otherwise, it renders with col-xs-10
  let bannerWidth = "col-xs-10"
  let smallBannerArray = ["Affordable Housing"]
  smallBannerArray.map((title,index) => {
    if (props.tooltipTitle === title) {
      bannerWidth = "col-xs-6"
    }
  })

  return (
    <div className={"flexrow wrap margin-top-small " + bannerWidth}>
      <div className="col-xs-12 large-banner-container">
        <div>
          <h2 className="gary-title">{props.tooltipTitle}</h2>
          {reactElement}
        </div>
      </div>
      <div className="flexrow middle-xs margin-top-xsmall">
        <img src={GaryTheGroundhog} alt="help-tooltip" className="gary-image" />
        <div className="flexcolumn margin-left-xxsmall">
          <span className="question-subtext">Gary the Groundhog</span>
          <span className="size-80 light">GroundUp Expert</span>
        </div>
      </div>
    </div>
  );
}
