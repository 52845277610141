import React from "react";
import GaryTheGroundhog from '../../static_assets/svg/GaryTheGroundhog.png';

export default function Tooltip_Gary(props) {

  var ReactDOMServer = require('react-dom/server');
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  var reactElement = htmlToReactParser.parse(props.tooltipText);

  return (
    <div className="col-xs-6 gary-container">
      <div className="gary-tooltip-box">
        <h2 className="gary-title">{props.tooltipTitle}</h2>
        {reactElement}
      </div>
      <div className="flexrow middle-xs margin-top-xxsmall">
        <img src={GaryTheGroundhog} alt="help-tooltip" className="gary-image" />
        <div className="flexcolumn margin-left-xxsmall">
          <span className="question-subtext">Gary the Groundhog</span>
          <span className="size-80 light">GroundUp Expert</span>
        </div>
      </div>
    </div>
  );
}
