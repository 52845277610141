import React from "react";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip_InlineGray from "../tooltips/Tooltip_InlineGray.js"
import Tooltip_Gary from "../tooltips/Tooltip_Gary.js"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export default function Number_Horizontal_StartAdornment(props) {

  const classes = useStyles();
  return (
    <div className="flexrow middle-xs">
      <div className="col-xs-2">
        <p>{props.questionText}</p>
      </div>
      <div className="col-xs-4">
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            key={props.key}
            id={props.name}
            type={"text"}
            label={props.placeholderText}
            name={props.name}
            value={props.value !== null ? props.formatInput(props.type, props.value, []) : ""}
            onChange={(event) => props.handleChange(event, props.type, [])}
            onBlur={(event) => props.handleChange(event, props.type, [])}
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">{props.startAdornment}</InputAdornment>,
            }}
            error={props.errorManager[props.name] ? true : false}
            helperText={props.errorManager[props.name] ? props.errorManager[props.name] : null}
          />
        </form>
      </div>
      {props.tooltipType === "small" ?
        <Tooltip_InlineGray
          tooltipText={props.tooltipText}
        />
      : props.tooltipType === "gary_tooltip" ?
        <Tooltip_Gary
          tooltipTitle={props.tooltipTitle}
          tooltipText={props.tooltipText}
        />
      :
        null
      }
    </div>
  );
}
