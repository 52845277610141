import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './App.css';
import { UserContext } from "./UserContext"
import Cookies from 'universal-cookie';

// import components
import Navbar from "./components/navbar/Navbar.js";
import Resources from "./components/Resources.js";
import Feedback from "./components/Feedback.js";
import Blog from "./components/Blog.js";
import Support from "./components/Support.js";
import Dashboard from "./components/Dashboard.js";
import ProformaPageStructure from "./components/ProformaPageStructure.js";
import EditProfile from "./components/EditProfile.js";
import Login from "./components/Login.js";
import SignUp from "./components/SignUp.js";
import TermsOfService from "./components/TermsOfService.js";
import EnterUsername from "./components/resetpassword/EnterUsername.js";
import ResetPassword from "./components/resetpassword/ResetPassword.js";
import Error404 from "./components/errors/error404.js";

import ComponentBankButtons from "./components/ComponentBankButtons.js"

// set app-wide font for Material-UI
const THEME = createMuiTheme({
    typography: {
      fontFamily: '"Nunito", sans-serif'
    },
    palette: {
      primary: {
        main: '#12AAEB'
      },
      secondary: {
        main: '#FED766'
      }
    },
    MuiSvgIcon: {
      color: "white"
    }
  });

function App() {
  if (window.location.protocol !== 'https:') {
    window.location = 'https:' + window.location.href.substring(window.location.protocol.length)
    // return // to stop app from mounting
  }

  const cookies = new Cookies();
  const [ activeUser, setActiveUser ] = useState()
  const [ token, setToken] = useState(cookies.get('auth'))
  cookies.set('auth', token, { path: '/', maxAge: 3559});

  useEffect(() => {
    if (token != "undefined") {
      let headers = new Headers();
      headers.set('Authorization', 'Bearer ' + token);
      let url = process.env.REACT_APP_API_URL + 'users';
      fetch(url, {
        method: "GET",
        headers: headers
      })
      .then( (response) => {
        if (response.ok) {
          return response.json();
        } else throw new Error(response.statusText);
      })
      .then((data) => {
        // GET with an expired token return a blank array here
        if (data.length === 0) {
          // if expired, remove active user and remove cookie
          setActiveUser()
          cookies.remove('auth');
        } else {
          setActiveUser(data)
        }

      })
    }
 }
 , [token])

 const logout = (history) => {
   let formdata = new FormData();
   formdata.append("token", token)
   formdata.append("client_id", process.env.REACT_APP_CLIENT_ID);
   let url = process.env.REACT_APP_API_URL + 'o/revoke_token/';
   fetch(url, {
     method: "POST",
     body: formdata
   })
   .then( (response) => {
    if (response.ok) {
      setActiveUser()
      cookies.remove('auth');
      history.push("/login")
     } else throw new Error(response.statusText);
   })
 }

   function PrivateRoute ({component: Component, hasToken, activeUser, setActiveUser, logout, ...rest}) {
    let authed = hasToken !== "undefined" ? true : false
    return (
      <Route
        {...rest}
        render={(props) => authed === true
          ? <Component {...props} activeUser={activeUser} setActiveUser={setActiveUser} logout={logout} />
          : <Redirect to={{pathname: '/login'}} />}
        />
    )
  }

  return (
    <MuiThemeProvider theme={THEME}>
      <Router>
        <UserContext.Provider value ={{token,setToken}}>
        <div>
          <Navbar
            activeUser={activeUser}
            setActiveUser={setActiveUser}
            logout={logout}
          />
          <Switch>
            <Route exact path="/component-bank/buttons" component={ComponentBankButtons} />
            <Route exact path="/resources" component={Resources} />
            <PrivateRoute hasToken={ token } exact path="/feedback" component={Feedback} />
            <PrivateRoute hasToken={ token } exact path="/support" component={Support} />
            <Route exact path="/blog" component={Blog} />
            <PrivateRoute hasToken={ token } exact path="/dashboard" component={Dashboard} activeUser={activeUser}/>
            <PrivateRoute hasToken={ token } exact path="/proforma/:proformaId/review" component={ProformaPageStructure} activeUser={activeUser}/>
            <PrivateRoute hasToken={ token } exact path="/proforma/:proformaId/summary" component={ProformaPageStructure} activeUser={activeUser}/>
            <PrivateRoute hasToken={ token } exact path="/proforma/:proformaId" component={ProformaPageStructure} activeUser={activeUser}/>
            <PrivateRoute hasToken={ token } exact path="/editprofile" component={EditProfile} activeUser={activeUser} setActiveUser={setActiveUser} logout={logout}/>
            <Route exact path="/reset-password" component={EnterUsername} />
            <Route exact path="/groundup/password_reset/" component={ResetPassword} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/terms" component={TermsOfService} />
            <Route exact path="/error404" component={Error404} />
            <Route exact path="" component={SignUp} />
          </Switch>
        </div>
      </UserContext.Provider>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
