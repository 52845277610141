import React, {useState, useEffect} from "react";

export default function InputGroup_Output_Single_Column_Sum_Multiply(props) {

  // File Description:
  // -- TODO

  const [ interimSum, setInterimSum] = useState(0)

  useEffect(() => {
    let mapSum = 0
    props.output.input.map((inputField,index) => {
      let cleanedInput = Number(props.activeProforma[inputField.name]) ? Number(props.activeProforma[inputField.name]) : 0
      mapSum = !inputField.name.includes("efficiency") ? mapSum + cleanedInput : mapSum
      return inputField
    })
    setInterimSum(mapSum)

  },[props.activeProforma])

  let efficiencyField;
  props.output.input.map((inputField,index) => {
      if (inputField.name.includes("efficiency")) {
        efficiencyField = inputField
      }
  })

  return (
    <div>
      <div className="col-xs-6 black-underline">
        {
          props.output.input.sort(function(a, b) {
            return a.id - b.id;
          }).map((inputField,index) => {
            if (!inputField.name.includes("efficiency")) {
              let valueToFormat = props.activeProforma[inputField.name] ? props.activeProforma[inputField.name] : ""
              return (
                <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
                  <div className="col-xs-8 padding-left-none">{inputField.question_shorthand}</div>
                  <div className="col-xs-4 end-xs padding-right-none">{props.formatOutputWithCommas(valueToFormat)} {inputField.post_unit}</div>
                </div>
              )
            } else {
              return null
            }
          })
        }
      </div>
      <div className="col-xs-6 black-underline">
        <div className="flexrow margin-top-xsmall margin-bottom-xxmall">
          <div className="col-xs-8 padding-left-none">Total</div>
          <div className="col-xs-4 end-xs padding-right-none">{props.formatOutputWithCommas(interimSum)} sqft</div>
        </div>
        <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
          <div className="col-xs-8 padding-left-none">{efficiencyField.question_shorthand}</div>
          <div className="col-xs-4 end-xs padding-right-none">{props.formatOutputPercent(props.activeProforma[efficiencyField.name])} {efficiencyField.post_unit}</div>
        </div>
      </div>
      <div className="col-xs-6 total-box margin-top-xxsmall">
        {props.output.calculation.map((calcField,index) => {
          return (
            <div className="flexrow margin-top-xsmall margin-bottom-xsmall">
              <div className="col-xs-9 padding-left-none">{calcField.display_name}</div>
              <div className="col-xs-3 end-xs padding-right-none">{props.formatOutputWithCommas(props.activeProforma[calcField.name])}  {calcField.post_unit}</div>
            </div>
          )
          })}
      </div>
    </div>

  )
}
