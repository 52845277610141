import React from "react";
import Button from "../buttons/Button.js"
import Tooltip_InlineGray from "../tooltips/Tooltip_InlineGray.js"
import Tooltip_Large_Banner from "../tooltips/Tooltip_Large_Banner.js"
import Tooltip_Gary from "../tooltips/Tooltip_Gary.js"

export default function Boolean_Horizontal(props) {

  let yesButtonClass = props.value ? "button-fill padding-xsmall margin-right-xsmall royal-blue" : "button-fill padding-xsmall margin-right-xsmall white-gray-border"
  let noButtonClass  = !props.value ? "button-fill padding-xsmall margin-right-xsmall royal-blue" : "button-fill padding-xsmall margin-right-xsmall white-gray-border"

  return (
    <div className="flexcolumn">
      <div className="col-xs-6">
        <p className={props.disabled ? "navbar-link":null}>{props.questionText}</p>
      </div>
      <div className="flexrow">
        <div className="col-xs-6 flexrow middle-xs">
          <div className="col-xs-6 padding-left-none padding-right-xxsmall">
            <Button
              buttonText={"Yes"}
              buttonClassName={yesButtonClass}
              onClick={() => {
                let requestBody = {}
                requestBody[props.name] = true
                props.patchProformaBulkBody(props.proformaId,requestBody,props.name)
              }}
              disabled={false}
            />
          </div>
          <div className="col-xs-6 padding-left-xxsmall padding-right-none">
            <Button
              buttonText={"No"}
              buttonClassName={noButtonClass}
              onClick={() => {
                let requestBody = {}
                requestBody[props.name] = false
                if (typeof props.activeProforma[props.dependency_field_name] === "object") {
                  requestBody[props.dependency_field_name] = []
                  if (props.dependency_field_name === "taxes_taxcredits_types"){
                    requestBody[props.dependency_field_name] = []
                    requestBody["taxes_taxcreditsonsale"] = 0
                    requestBody["taxes_annualtaxcreditsdollars"] = 0
                    requestBody["taxes_annualtaxcreditsperbuildingsf"] = 0
                    requestBody["taxes_taxcreditsperlandsf"] = 0
                  }
                } else if (props.dependency_field_name.includes(",")) {
                  var fieldArray = props.dependency_field_name.split(',');
                  fieldArray.map((field,index) => {requestBody[field] = 0; return field})
                } else {
                  requestBody[props.dependency_field_name] = 0
                }
                props.patchProformaBulkBody(props.proformaId,requestBody,props.name)
              }}
              disabled={false}
            />
          </div>
        </div>
        {props.tooltipType === "small" ?
          <div className="col-xs-1">
            <Tooltip_InlineGray
              tooltipText={props.tooltipText}
            />
          </div>
          : props.tooltipType === "gary_tooltip" ?
            <Tooltip_Gary
              tooltipTitle={props.tooltipTitle}
              tooltipText={props.tooltipText}
            />
          :
            null
        }
      </div>
      {props.tooltipType === "large_banner" ?
          <Tooltip_Large_Banner
            tooltipTitle={props.tooltipTitle}
            tooltipText={props.tooltipText}
          />
        :
          null
      }
    </div>
  );
}
