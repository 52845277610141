import React from "react";
import Button from '../buttons/Button.js'
import {ReactComponent as StatusComplete} from '../../static_assets/svg/sectionCircleComplete.svg';
import {ReactComponent as StatusInProgress} from '../../static_assets/svg/sectionCircleInProgress.svg';
import {ReactComponent as StatusNotStarted} from '../../static_assets/svg/sectionCircleEmpty.svg';
import { useHistory } from "react-router-dom";

export default function ProformaSections(props) {
  let history = useHistory();

  let includesCommercial = props.projectType.includes("project_type_commercial")
  let includesResidential = props.projectType.includes("project_type_residential")
  let includesOffice =props.projectType.includes("project_type_office")

  let showViewSummary = true;
  props.sections.map((section,index) => {
    if (section.status !== "complete") {showViewSummary = false}
  })
  if (props.summaryURL) {showViewSummary = false}

  return (
    <div className="padding-top-small">
      {
        props.sections.map((section,index) => {
          let statusRadio;
          if (section.status === "complete") {
            statusRadio = <StatusComplete />
          } else if (section.status === "inProgress") {
            statusRadio = <StatusInProgress />
          } else if (section.status === "notStarted") {
            statusRadio = <StatusNotStarted />
          } else {
            statusRadio = <StatusNotStarted />
          }

          let divClass = "proforma-section-label padding-left-xsmall padding-right-xsmall"
          divClass = section.id === props.activeSection ? divClass + " active" : divClass

          if (
            (section.id === 2 && !includesResidential) ||
            (section.id === 3 && !includesCommercial) ||
            (section.id === 4 && !includesOffice)
          ) {
            return null
          } else {
            return (
              <div
                key={index}
                className={divClass}
                onClick={() => {
                  props.setActiveSection(section.id);
                  props.setActiveSubsection(0);
                  history.push("/proforma/" + props.proformaId)
                }}
              >
                <span>{statusRadio}</span>
                <span className="margin-left-xxsmall section-name">{section.title}</span>
              </div>
            )
          }
        })
      }
      {showViewSummary && <div className="center-xs margin-top-xsmall">
        <Button
          buttonText={"View Summary"}
          buttonClassName={"button-normal light-blue padding-xsmall margin-right-xsmall"}
          onClick={() => history.push("/proforma/" + props.proformaId + "/summary")}
          disabled={false}
        />
      </div>}
      {!showViewSummary && <div className="center-xs margin-top-xsmall">
        <Button
          buttonText={"Proforma Overview"}
          buttonClassName={"button-normal light-blue padding-xsmall margin-right-xsmall"}
          onClick={() => history.push("/proforma/" + props.proformaId + "/review")}
          disabled={false}
        />
      </div>}
    </div>
  )
}
