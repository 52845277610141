import React, {useState, useEffect, useContext} from "react";
import PropTypes from 'prop-types';
import Button from "./buttons/Button.js"
import PropagateLoader from "react-spinners/PropagateLoader";
import ProgressBar from "./proformaPage/ProgressBarElement.js";
import AppFooter from "./navbar/AppFooter.js";
import ProformaObject from "./dashboard/ProformaObject.js";
import { UserContext } from '../UserContext'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import project type SVG
import {ReactComponent as ProjectCommercialOfficeResidential} from '../static_assets/svg/ProjectCommercialOfficeResidential.svg';
import {ReactComponent as ProjectCommercialResidential} from '../static_assets/svg/ProjectCommercialResidential.svg';
import {ReactComponent as ProjectCommercialOffice} from '../static_assets/svg/ProjectCommercialOffice.svg';
import {ReactComponent as ProjectCommercial} from '../static_assets/svg/ProjectCommercial.svg';
import {ReactComponent as ProjectOfficeResidential} from '../static_assets/svg/ProjectOfficeResidential.svg';
import {ReactComponent as ProjectOffice} from '../static_assets/svg/ProjectOffice.svg';
import {ReactComponent as ProjectResidential} from '../static_assets/svg/ProjectResidential.svg';
import GaryTheGroundhog from '../static_assets/svg/GaryTheGroundhog.png';

// MUI function to control tabs on dashboard
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// MUI props to control tabs on dashboard
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// MUI function to control tabs on dashboard
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
  },
}));


export default function Dashboard(props) {
  const classes = useStyles();
  const [ value, setValue ] = useState(0); // used for governing tab display
  const [ proformaList, setProformaList ] = useState([])
  const [ proformaStructure, setProformaStructure ] = useState([])
  const {token, setToken} = useContext(UserContext)
  let history = useHistory();

  // handle change function to govern tab display
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // retrieve list of proforma objects
  useEffect(() => {
    // fetch and set state in response
    let url = process.env.REACT_APP_API_URL + "proformas"
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "GET",
      headers: headers,
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()  //we only get here if there is no error
    })
    .then(data => setProformaList(data))
    .catch( err => {
      if (err.status === 401) {
        history.push("/login")
      } else if (err.status === 404 || err.status === 500 || err.status === 403) {
        history.push("/error404")
      }
    })

    // retrieve proforma structure
    let structureUrl = process.env.REACT_APP_API_URL + "sections/"
    fetch(structureUrl, {
      method: "GET",
      headers: headers,
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()  //we only get here if there is no error
    })
    .then(data => setProformaStructure(data))
    .catch( err => {
      if (err.status === 401) {
        history.push("/login")
      } else if (err.status === 404 || err.status === 500 || err.status === 403) {
      }
    })
  },[])

  function deleteProforma(proformaId) {
    let url = process.env.REACT_APP_API_URL + "proformas/" + proformaId;
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "DELETE",
      headers: headers
    })
    .then(res => {
      if (res.status === 204) {
        setProformaList(proformaList.filter(({ id }) => id !== proformaId));
      } else if (res.status === 404 || res.status === 500 || res.status === 403) {
        history.push("/error404")
      }
    })
  }

  function duplicateProforma(proformaId) {
    let url = process.env.REACT_APP_API_URL + "proformaduplicate/" + proformaId;
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "POST",
      headers: headers,
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json()  //we only get here if there is no error
    })
    .then(data => setProformaList(proformaList => [...proformaList, data]))
    .catch( err => {
      if (err.status === 500 || err.status == 403 || err.status == 404) {
        history.push("/error404")
      }
    });
  }

  function createProforma() {
    let url = process.env.REACT_APP_API_URL + "proformas/";
    let headers = new Headers();
    headers.set('Content-type', 'application/json')
    headers.set('Authorization', 'Bearer ' + token);
    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({"project_name": "New Project"})
    })
    .then(res => res.json())
    .then(data => {
      history.push("/proforma/" + data.id);
    })
  }

  let proformaListElement = []
  if (!proformaList || proformaStructure.length === 0 || !proformaStructure) {
    proformaListElement.push(
      <div className="flexrow center-xs padding-top-medium">
        <PropagateLoader
          size={25} color={"#FED766"} loading={true}
        />
      </div>
    )
  } else if (proformaList.length === 0 && proformaList && proformaStructure.length !== 0 && proformaStructure) {
    proformaListElement.push(
      <div className="flexrow center-xs middle-xs padding-top-medium">
        <div className="col-xs-2 flexcolumn start-xs padding-left-medium">
          <img src={GaryTheGroundhog} alt="welcome" className="gary-image" />
          <span className="question-subtext">Gary the Groundhog</span>
          <span className="size-80 light">GroundUp Expert</span>
        </div>
        <div className="col-xs-6 start-xs">
          <h2>Welcome to GroundUp!</h2>
          <p className="margin-bottom-xxsmall">Looks like you haven't created any financial models yet!</p>
          <p className="margin-top-xxsmall">Click the "New Project" button to get started with your first GroundUp project.</p>
        </div>
      </div>
    )
  } else if (proformaList) {
    // create containers to hold the user's proforma, and those shared with the user
    let myProformaListElement = []
    let sharedViewOnlyElement = []
    let sharedViewAndEditElement = []

    // sort proforma by last updated
    proformaList.sort(function(a, b) {
      let aDate = new Date(a.last_updated_on)
      let bDate = new Date(b.last_updated_on)
      return bDate-aDate;
    }).map((proforma,index) => {
      // if created by user, add to myProformaListElement
      if (proforma.created_by === props.activeUser[0].id) {
        myProformaListElement.push(
          <ProformaObject
            index={index}
            deleteProforma={deleteProforma}
            duplicateProforma={duplicateProforma}
            proforma={proforma}
            proformaStructure={proformaStructure}
            activeUser={props.activeUser}
            permissionLevel="owner"
            proformaList={proformaList}
            setProformaList={setProformaList}
          />
        )
      } else {
        // otherwise, look at permissions
        proforma.permissions.map((permission,index) => {
          // if the user is the collaborator, and view only status, add to sharedViewOnlyElement
          if (permission.collaborator.id === props.activeUser[0].id && permission.permissions === "view_only") {
            sharedViewOnlyElement.push(
              <ProformaObject
                index={index}
                deleteProforma={deleteProforma}
                duplicateProforma={duplicateProforma}
                proforma={proforma}
                proformaStructure={proformaStructure}
                activeUser={props.activeUser}
                permissionLevel="view_only"
                proformaList={proformaList}
                setProformaList={setProformaList}
              />
            )
          // if the user is the collaborator, and view_edit status, add to sharedViewAndEditElement
          } else if (permission.collaborator.id === props.activeUser[0].id && permission.permissions === "view_edit") {
            sharedViewAndEditElement.push(
              <ProformaObject
                index={index}
                deleteProforma={deleteProforma}
                duplicateProforma={duplicateProforma}
                proforma={proforma}
                proformaStructure={proformaStructure}
                activeUser={props.activeUser}
                permissionLevel="view_edit"
                proformaList={proformaList}
                setProformaList={setProformaList}
              />
            )
          }
          return permission
        })
      }
      return proforma
    })

    // if user has no proforma in myProformaListElement but the element renders due to the user having proforma shared with them,
    //  then fill the myProformaListElement with the create project prompt
    if (myProformaListElement.length === 0) {
      myProformaListElement.push(
        <div className="flexrow center-xs middle-xs padding-top-medium">
          <div className="col-xs-2 flexcolumn start-xs padding-left-medium">
            <img src={GaryTheGroundhog} alt="welcome" className="gary-image" />
            <span className="question-subtext">Gary the Groundhog</span>
            <span className="size-80 light">GroundUp Expert</span>
          </div>
          <div className="col-xs-6 start-xs">
            <h2>Welcome to GroundUp!</h2>
            <p className="margin-bottom-xxsmall">Looks like you haven't created any financial models yet!</p>
            <p className="margin-top-xxsmall">Click the "New Project" button to get started with your first GroundUp project.</p>
          </div>
        </div>
      )
    }

    // render the proformaListElement with conditional render logic for the share sections, so they only appear if relevant
    proformaListElement.push(
      <div className="flexrow">
        <div className="col-xs-12">
          <div className={classes.root + " margin-top-medium"}>
            <AppBar position="static">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="secondary" textColor="inherit">
                <Tab label="My Projects" {...a11yProps(0)} />
                {(sharedViewOnlyElement.length !== 0 || sharedViewAndEditElement.length !== 0) && <Tab label="Shared With Me" {...a11yProps(1)} />}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              {myProformaListElement}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="col-xs-12">
                {sharedViewAndEditElement.length !== 0 && <div>
                  <h2>Shared with Me: Editor</h2>
                  <div className="col-xs-12">
                    {sharedViewAndEditElement}
                  </div>
                </div>}
                {sharedViewOnlyElement.length !== 0 && <div>
                  <h2>Shared with Me: Viewer</h2>
                  <div className="col-xs-12">
                   {sharedViewOnlyElement}
                 </div>
                </div>}
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    )
  }


  return (
    <div className="flexcolumn between-xs white-vertical-container">
      <div className="padding-small">
        <div className="col-xs-12 between-xs flexrow middle-xs">
          <h1>Projects</h1>
            <Button
              buttonText={"New Project"}
              buttonClassName={"button-normal light-blue padding-xsmall margin-right-xsmall"}
              onClick={() => createProforma()}
            />
        </div>
        {proformaListElement}
      </div>
      <AppFooter />
    </div>

  );
}
